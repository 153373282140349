import React, {Component} from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

class Select extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        let select;
console.log(this.props.listValues)
        if (this.props.mode === "edit" || this.props.mode === "add") {
            select = <Input
                name={this.props.fieldName}
                value={this.props.fieldValue || ''}
                type={this.props.type || 'select'}
                bsSize="sm"
                onChange={e => this.props.handleInputChange(e)}
            >
                <option key=''>-- Select --</option>
                {this.props.listValues.map((item) => (
                    <option key={item.id}>{item.title}</option>
                ))}
            </Input>
        } else {
            select = <Input
                name={this.props.fieldName}
                value={this.props.fieldValue || ''}
                type={this.props.type || 'select'}
                placeholder={this.props.placeholderText} 
                readOnly
                style={this.props.style}
                bsSize="sm"
            >
            {this.props.listValues.map((item) => (
                <option key={item.Id}>{item.Title}</option>
            ))}
            </Input>
        }

        return (
            <FormGroup>
                <Label for={this.props.fieldName} size="sm">{this.props.fieldTitle || _.startCase(this.props.fieldName)}</Label>
                {select}
            </FormGroup>
        )
    }
}

Select.propTypes = {
    mode: PropTypes.string,             //Mode: view, edit, add
    name: PropTypes.string,             //DOM name
    type: PropTypes.string,  //Type: text, date, number, textarea, email, password, url, datetime, color, search,
    value: PropTypes.string,            //Value of the input object
    listValues: PropTypes.array,
    isDisabled: PropTypes.bool,         //Set the input to be disabled
    isReadOnly: PropTypes.bool,         //Override the readOnly property
    style: PropTypes.string,            //Any style overrides for the input object
}

export default Select;