/* eslint-disable */
import React from 'react';


class Footer extends React.Component {
  render() {
    return (
      <footer className="footer text-center">
        © JetFlyt, All Rights Reserved.  Powered by the <a href="https://www.FUZIONAgency.com">FUZION Agency</a>.
      </footer>
    );
  }
}
export default Footer;
