/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class NewsFeed extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <section className="news-feed-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <div className="section-title">
                <h2>News Feeds</h2>
                <p>
                  Our extensive portfolio of into-wing suppliers provides a
                  variety of choices with the assurance that your fuel is of the
                  highest quality and will be there on time every time.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-4 col-sm-12">
              <div className="single-new-feed-items">
                <div className="thumb">
                  <img src="assets/img/news-feed/01.jpg" alt="news feed" />
                </div>
                <div className="content">
                  <div className="meta-tags">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fas fa-calendar-alt" /> 8 June, 2017
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-user" /> John Doe
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-comments" /> 23Comments
                        </a>
                      </li>
                    </ul>
                  </div>
                  <a href="blog-details.html">
                    <h4 className="title">
                      It is a long established fact that a reader will be
                      distracted
                    </h4>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-sm-12">
              <div className="single-new-feed-items">
                <div className="thumb">
                  <img src="assets/img/news-feed/02.jpg" alt="news feed" />
                </div>
                <div className="content">
                  <div className="meta-tags">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fas fa-calendar-alt" /> 8 June, 2017
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-user" /> John Doe
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-comments" /> 23Comments
                        </a>
                      </li>
                    </ul>
                  </div>
                  <a href="blog-details.html">
                    <h4 className="title">
                      It is a long established fact that a reader will be
                      distracted
                    </h4>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 col-sm-12">
              <div className="single-new-feed-items mobile-border-top">
                <div className="content">
                  <a href="blog-details.html">
                    <h4 className="title">
                      It is a long established fact that a reader will be
                      distracted
                    </h4>
                  </a>
                </div>
              </div>

              <div className="hr-separator" />
              <div className="single-new-feed-items">
                <div className="content">
                  <a href="blog-details.html">
                    <h4 className="title">
                      It is a long established fact that a reader will be
                      distracted
                    </h4>
                  </a>
                </div>
              </div>
              <div className="hr-separator" />
              <div className="single-new-feed-items">
                <div className="content">
                  <a href="blog-details.html">
                    <h4 className="title">
                      It is a long established fact that a reader will be
                      distracted
                    </h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default NewsFeed;
