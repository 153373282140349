/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class SupportBar extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <section className="support-bar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <Link to="/">
                <img src={logo} alt="JetFlyt" />
              </Link>
            </div>
            <div className="col-lg-9 col-sm-12">
              <div className="support-bar-content">
                <ul>
                  <li>
                    <div className="single-support-item">
                      <div className="icon">
                        <i className="fas fa-check-square" />
                      </div>
                      <div className="content">
                        <span className="subtitle">Available Support</span>
                        <h5>24/7 Online Support</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="single-support-item">
                      <div className="icon">
                        <i className="fas fa-clock" />
                      </div>
                      <div className="content">
                        <span className="subtitle">Working Time</span>
                        <h5>8:00AM - 12:00PM</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="single-support-item">
                      <div className="icon">
                        <i className="fas fa-envelope" />
                      </div>
                      <div className="content">
                        <span className="subtitle">Get In Touch</span>
                        <a href="mailTo:Info@jetflyt.com">
                          <h5>Info@jetflyt.com</h5>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default SupportBar;
