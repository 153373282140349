/* eslint-disable */
import React from 'react';

import {
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Table
} from 'reactstrap';

import iconSignature from '../../../assets/images/icons/icon-32-signature.png';
import iconMillionair from '../../../assets/images/icons/icon-32-millionair.png';

class FuelMarket extends React.Component {
	render() {
		/*--------------------------------------------------------------------------------*/
		/* Used In Dashboard-2 [Cryptocurrency]                                           */
		/*--------------------------------------------------------------------------------*/
		return <div>
			<Card>
				<CardBody>
					<CardTitle>Fuel Pricing</CardTitle>
					<CardSubtitle>Overview of Current Fuel Prices</CardSubtitle>
					<div className="mt-3">
						<Table className="mb-3" bordered responsive>
							<thead>
								<tr className="bg-light">
									<th>Vendor</th>
									<th>Currency </th>
									<th>Price </th>
									<th>Tender </th>
									<th>Gallons </th>
									<th>Change % (1D)</th>
									<th>Change % (1W)</th>
								</tr>
							</thead>
							<tbody>
								{/*--------------------------------------------------------------------------------*/}
								{/* Row-1                                                                          */}
								{/*--------------------------------------------------------------------------------*/}
								<tr>
									<td>
										<span>
											<img src={iconSignature} alt="Signature Flight Services" className="dark-logo" />
										</span>
									</td>
									<td>
										<h6><a className="font-medium link" href="" > Signature</a></h6>
										<small className="text-muted">USD</small>
									</td>
									<td>
										<p>$4.67</p>
									</td>
									<td>
										<p>$4.68</p>
									</td>
									<td>
										<p>200</p>
									</td>
									<td className="no-wrap"><span className="badge badge-success"><i className="fa fa-chevron-up"></i> 66.26%</span></td>
									<td className="no-wrap"><span className="badge badge-danger"><i className="fa fa-chevron-down"></i> -16.48%</span></td>
								</tr>
								{/*--------------------------------------------------------------------------------*/}
								{/* Row-2                                                                          */}
								{/*--------------------------------------------------------------------------------*/}
								<tr>
									<td>
										<span>
											<img src={iconMillionair} alt="Millionair Aviation" className="dark-logo" />
										</span>
									</td>
									<td>
										<h6><a className="font-medium link" href="" > Millionair</a></h6>
										<small className="text-muted">USD</small>
									</td>
									<td>
										<p>$5.68</p>
									</td>
									<td>
										<p>$4.67</p>
									</td>
									<td>
										<p>500</p>
									</td>
									<td className="no-wrap"><span className="badge badge-success"><i className="fa fa-chevron-up"></i> 26.18%</span></td>
									<td className="no-wrap"><span className="badge badge-danger"><i className="fa fa-chevron-down"></i> -11.47%</span></td>
								</tr>
							</tbody>
						</Table>
					</div>
				</CardBody>
			</Card>
		</div>
	}
}

export default FuelMarket;
