import Customer from "../views/dashboards/customer.jsx";
import FBO from "../views/dashboards/fbo.jsx";
import Sales from "../views/dashboards/sales.jsx";
import WorldMap from "../views/maps/WorldMap";
//--General-------------------------------------------
import Types from "../views/admin/general/Types.jsx";
import ICAOs from "../views/admin/general/ICAOs.jsx";
//--Vendors-------------------------------------------
import FBOs from "../views/vendor/fbo/FBOs.jsx";
//--Admin-------------------------------------------
import Persons from "../views/admin/org/Persons.jsx";
import Roles from "../views/admin/org/Roles.jsx";
import Organizations from "../views/admin/org/Organizations.jsx";
//--Corporate-------------------------------------------
import Tenders from "../views/admin/corp/Tenders.jsx";
import Missions from "../views/admin/corp/Missions.jsx";
import Legs from "../views/admin/corp/Legs.jsx";
import Seats from "../views/admin/corp/Seats.jsx";
import Vehicles from "../views/admin/corp/Vehicles.jsx";
// import Types from '../views/admin/Types.jsx';
// import Services from '../views/admin/Services.jsx';
// import Products from '../views/admin/Products.jsx';
// import Invoices from '../views/admin/Invoices.jsx';
// import Payments from '../views/admin/Payments.jsx';
// import Messages from '../views/admin/Messages.jsx';
// import QuoteRequests from '../views/admin/QuoteRequests.jsx';
// import Quotes from '../views/admin/Quotes.jsx';

// import Vectormap from '../views/maps/vectormap.jsx';
// var auths = [].concat(AuthRoutes);

var ThemeRoutes = [
  {
    navlabel: true,
    name: "Personal",
    icon: "mdi mdi-dots-horizontal"
  },
  {
    path: "/customer/dashboard",
    name: "Customer Dashboard",
    icon: "mdi mdi-adjust",
    component: Customer
  },
  {
    path: "/customer/fbo",
    name: "FBO Dashboard",
    icon: "mdi mdi-adjust",
    component: FBO
  },
  {
    path: "/customer/sales",
    name: "Sales Dashboard",
    icon: "mdi mdi-adjust",
    component: Sales
  },
  {
    collapse: true,
    name: "FBO",
    state: "fbopages",
    icon: "mdi mdi-view-dashboard",
    // extra: "mega-dropdown",
    child: [
      {
        path: "/customer/vendor/fbos",
        name: "FBOs",
        icon: "mdi mdi-adjust",
        component: FBOs
      },
      {
        path: "/customer/vendor/map",
        name: "World Map",
        icon: "mdi mdi-adjust",
        component: WorldMap
      }
    ]
  },
  {
    collapse: true,
    name: "Corporation",
    state: "corppages",
    icon: "mdi mdi-view-dashboard",
    // extra: "mega-dropdown",
    child: [
      {
        path: "/customer/corp/tenders",
        name: "Tenders",
        icon: "mdi mdi-adjust",
        component: Tenders
      },
      {
        path: "/customer/corp/missions",
        name: "Missions",
        icon: "mdi mdi-adjust",
        component: Missions
      },
      {
        path: "/customer/corp/legs",
        name: "Legs",
        icon: "mdi mdi-adjust",
        component: Legs
      },
      {
        path: "/customer/corp/vehicles",
        name: "Vehicles",
        icon: "mdi mdi-adjust",
        component: Vehicles
      },
      {
        path: "/customer/corp/seats",
        name: "Seats",
        icon: "mdi mdi-adjust",
        component: Seats
      }
    ]
  },
  {
    collapse: true,
    name: "Admin",
    state: "adminpages",
    icon: "mdi mdi-view-dashboard",
    // extra: "mega-dropdown",
    child: [
      {
        path: "/customer/admin/organizations",
        name: "Organizations",
        icon: "mdi mdi-adjust",
        component: Organizations
      },
      {
        path: "/customer/admin/persons",
        name: "People",
        icon: "mdi mdi-adjust",
        component: Persons
      },
      {
        path: "/customer/admin/roles",
        name: "Roles",
        icon: "mdi mdi-adjust",
        component: Roles
      },
      {
        path: "/customer/admin/types",
        name: "Types",
        icon: "mdi mdi-adjust",
        component: Types
      },
      {
        path: "/customer/admin/icaos",
        name: "ICAOs",
        icon: "mdi mdi-adjust",
        component: ICAOs
      }
    ]
  },
  {
    path: "/",
    // pathTo: "/dashboards/classic",
    name: "Home",
    component: Customer
  }
];
export default ThemeRoutes;
