/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class BrandLogo extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <div className="brand-logo-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="brand-carousel" id="brand-carousel">
                <div className="single-brand-logo">
                  <img src="assets/img/brands/01.png" alt="brangs logo" />
                </div>
                <div className="single-brand-logo">
                  <img src="assets/img/brands/02.png" alt="brangs logo" />
                </div>
                <div className="single-brand-logo">
                  <img src="assets/img/brands/03.png" alt="brangs logo" />
                </div>
                <div className="single-brand-logo">
                  <img src="assets/img/brands/04.png" alt="brangs logo" />
                </div>
                <div className="single-brand-logo">
                  <img src="assets/img/brands/01.png" alt="brangs logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BrandLogo;
