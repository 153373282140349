/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class Testimonials extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <section className="testimonial-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title-inner">
                <span className="subtitle">Testimonials</span>
                <h2 className="title">Our Happy Clients Says About Us</h2>
              </div>

              <div className="testimonial-wrapper" id="testimonial-carousel">
                <div className="single-testimonial-item">
                  <div className="descripton">
                    <ul className="ratigs">
                      <li>
                        <a href="#">
                          <i className="fas fa-star" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star" />
                        </a>
                      </li>
                    </ul>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here.
                    </p>
                  </div>
                  <div className="clients-details">
                    <div className="thumb">
                      <img
                        src="assets/img/testimonial/01.png"
                        alt="tesimonial image"
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">William Hannah</h4>
                      <span className="post">Founder </span>
                    </div>
                  </div>
                </div>

                <div className="single-testimonial-item">
                  <div className="descripton">
                    <ul className="ratigs">
                      <li>
                        <a href="#">
                          <i className="fas fa-star" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star" />
                        </a>
                      </li>
                    </ul>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here.
                    </p>
                  </div>
                  <div className="clients-details">
                    <div className="thumb">
                      <img
                        src="assets/img/testimonial/01.png"
                        alt="tesimonial image"
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Hannah William</h4>
                      <span className="post">CEO </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="section-title-inner">
                <span className="subtitle">Get Up To Date</span>
                <h2 className="title">Subscribe For Our Newsletter</h2>
              </div>

              <div className="subscribe-form-wrapper">
                <div className="form-element margin-bottom-30">
                  <label>
                    Enter Your Name <span className="c-red"> **</span>
                  </label>
                  <div className="has-icon">
                    <input
                      type="text"
                      placeholder="Enter your name...."
                      className="input-field borderd square"
                    />
                    <div className="the-icon">
                      <i className="far fa-user" />
                    </div>
                  </div>
                </div>
                <div className="form-element">
                  <label>
                    Enter Your Email Address <span className="c-red"> **</span>
                  </label>
                  <div className="has-icon">
                    <input
                      type="email"
                      placeholder="Enter your email address...."
                      className="input-field borderd square"
                    />
                    <div className="the-icon">
                      <i className="far fa-envelope" />
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="submit-btn btn-rounded margin-top-40 btn-center"
                >
                  <i className="far fa-paper-plane" /> subscribe now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Testimonials;
