import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { db } from "../../firebase";
import Tender from "./Tender";
import { Row, Col } from "reactstrap";

class Tenders extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      baseObject: "Tenders",
      icaos: [],
      listTypes: [],
      tenderTypes: []
    };
    this.getData = this.getData.bind(this);
    //        this.removeData = this.removeData.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getIcaos();
    this.getTypes();
  }

  getData() {
    // eslint-disable-next-line
    db.list(this.state.baseObject).then(collection => {
      if (collection) {
        const items = collection.docs.reduce(
          (res, item) => ({ ...res, [item.id]: item.data() }),
          {}
        );
        const results = Object.entries(items).map(e =>
          Object.assign(e[1], { key: e[0] })
        );
        this.setState({ data: results });
      } else {
        this.setState({ data: [] });
      }
    });
  }

  getIcaos = () => {
    // eslint-disable-next-line
    db.list("ICAOs")
      .then(collection => {
        if (collection) {
          const items = collection.docs.reduce(
            (res, item) => ({ ...res, [item.id]: item.data() }),
            {}
          );
          const results = Object.entries(items).map(e =>
            Object.assign(e[1], { key: e[0] })
          );
          this.setState({
            icaos: results
          });
        }
      })
      .catch(error => {
        console.log(`Error retrieving list of ICAOs: `, error);
      });
  };

  getTypes = () => {
    // eslint-disable-next-line
    db.list("Types")
      .then(collection => {
        if (collection) {
          const items = collection.docs.reduce(
            (res, item) => ({ ...res, [item.id]: item.data() }),
            {}
          );
          const results = Object.entries(items).map(e =>
            Object.assign(e[1], { key: e[0] })
          );
          this.setState({
            listTypes: results,
            tenderTypes: results.filter(type => type.group === "Tender Type")
          });
        }
      })
      .catch(error => {
        console.log(`Error retrieving list of Types: `, error);
      });
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        <ReactTable
          data={data}
          columns={[
            {
              Header: () => (
                <Row>
                  <Col xs={{ size: 8, offset: 2 }}>
                    <h2>{this.state.baseObject}</h2>
                  </Col>
                  <Col xs="2" className="text-right">
                    <Tender
                      baseObject={this.state.baseObject}
                      handleRefresh={this.getData}
                      buttonText="Add New"
                      mode="add"
                      icaos={this.state.icaos}
                      tenderTypes={this.state.tenderTypes}
                    />
                  </Col>
                </Row>
              ),
              columns: [
                {
                  Header: "",
                  width: 90,
                  id: "edit",
                  filterable: false,
                  sortable: false,
                  Cell: ({ original }) => (
                    <Col className="text-right">
                      <Tender
                        key={original.id}
                        baseObject={this.state.baseObject}
                        item={original}
                        handleRefresh={this.getData}
                        buttonText="View"
                        mode="view"
                        icaos={this.state.icaos}
                        tenderTypes={this.state.tenderTypes}
                      />
                    </Col>
                  )
                },
                {
                  Header: "Type",
                  accessor: "type"
                },
                {
                  Header: "Requested",
                  accessor: "requestedDate"
                },
                {
                  Header: "Gallons",
                  accessor: "gallons"
                },
                {
                  Header: "ICAO",
                  accessor: "icao"
                },
                {
                  Header: "Total Cost",
                  accessor: "totalCost"
                }
              ]
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

export default Tenders;
