/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class MeetTheTeam extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <section className="team-area">
        <div className="team-area-top">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 text-center">
                <div className="section-title">
                  <h2 className="c-white">Our Team</h2>
                  <p>
                    Our extensive portfolio of into-wing suppliers provides a
                    variety of choices with the assurance that your fuel is of
                    the highest quality and will be there on time every time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="team-area-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-3 col-sm-6">
                <div className="single-team-member">
                  <div className="thumb">
                    <img src="assets/img/team/01.jpg" alt="team members" />
                  </div>
                  <div className="content">
                    <h4 className="title">William Hannah</h4>
                    <span className="post">Founder</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 col-sm-6">
                <div className="single-team-member">
                  <div className="thumb">
                    <img src="assets/img/team/02.jpg" alt="team members" />
                  </div>
                  <div className="content">
                    <h4 className="title">Halim Thomson</h4>
                    <span className="post">CEO</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 col-sm-6">
                <div className="single-team-member">
                  <div className="thumb">
                    <img src="assets/img/team/03.jpg" alt="team members" />
                  </div>
                  <div className="content">
                    <h4 className="title">Arista Promi</h4>
                    <span className="post">Manager</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 col-sm-6">
                <div className="single-team-member">
                  <div className="thumb">
                    <img src="assets/img/team/04.jpg" alt="team members" />
                  </div>
                  <div className="content">
                    <h4 className="title">Linkon Hannah</h4>
                    <span className="post">Pilot</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default MeetTheTeam;
