/* eslint-disable */
import React from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  FuelOrder,
  FuelExchange,
  FuelMarket,
  FuelTrade,
  FuelTrade2,
  Weather
} from "components/dashboard-components";

class Sales extends React.Component {
  render() {
    return (
      <div>
        {/* <Row>
          <Col sm="12" lg="6">
            <FuelOrder />
          </Col>
          <Col sm="12" lg="6">
            <FuelExchange />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <FuelMarket />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={6}>
            <FuelTrade />
          </Col>
          <Col sm={12} lg={6}>
            <FuelTrade2 />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Weather />
          </Col>
        </Row> */}
      </div>
    );
  }
}

export default Sales;
