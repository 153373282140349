import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyAxeVa1osMx1bu8NHfg1_rojRj7DJ3quNI",
  authDomain: "jetflyt-001.firebaseapp.com",
  databaseURL: "https://jetflyt-001.firebaseio.com",
  projectId: "jetflyt-001",
  storageBucket: "jetflyt-001.appspot.com",
  messagingSenderId: "692585408125"
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
const db = firebase.firestore();
db.settings({
  timestampsInSnapshots: true
});

const auth = firebase.auth();

export { db, auth, firebase };
