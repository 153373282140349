/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import pilot from "../../assets/theme/jetflyt/img/home/Pilot-520x420.png";
import { Link } from "react-router-dom";
class About extends React.Component {
  render() {
    return (
      <section className="about-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left-content-area">
                <img
                  src={pilot}
                  alt="Tender solutions for private jet pilots"
                />
              </div>
            </div>
            <div className="col-lg-6 remove-col-padding">
              <div className="right-content-area">
                <span className="section-subtitle">About JetFlyt</span>
                <h3>Expert Tender Management</h3>
                <p>
                  Managing your fuel purchases is what we do all day. Joining
                  our group of corporate aviation professionals means you get
                  the best expertise in managing your fuel buys.
                </p>
                <p>
                  Because our team is composed of career professionals you can
                  trust that we know the ins and outs of commercial fuel
                  purchasing. Our vast network of connections from this long
                  history can help you as you rely on our fuel management
                  services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
