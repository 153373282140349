import React from "react";
import { Map, Marker, Popup, TileLayer, WMSTileLayer } from "react-leaflet";
import { Card, CardBody } from "reactstrap";
import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import { airports } from "../../data/airport-data";

const iconAirport = new Leaflet.Icon({
  iconUrl: require("../../assets/images/maps/iconAirport.png"),
  shadowUrl: require("../../assets/images/maps/shadow.png"),
  iconSize: [35, 35],
  shadowSize: [35, 16],
  iconAnchor: [19, 36],
  shadowAnchor: [10, 15],
  popupAnchor: [-3, -76]
});

class WorldMap extends React.Component {
  constructor() {
    super();
    this.state = {
      latitude: 29.645,
      longitude: -95.279,
      zoom: 13,
      bluemarble: false
    };
  }

  componentDidMount() {
    this.loadAirports();
  }

  loadAirports() {}

  handleMapClick = e => {
    this.setState({
      bluemarble: !this.state.bluemarble
    });
  };

  render() {
    const position = [this.state.latitude, this.state.longitude];

    // const location={
    //   lat: 29.645,
    //   lng: -95.279,
    // }

    const markers = airports.map((a, index) => {
      const thisLocation = { lat: a.LatitudeDecimal, lng: a.LongitudeDecimal };
      return (
        <Marker key={index} position={thisLocation} icon={iconAirport}>
          <Popup>
            {a.ICAO} - {a.Airport}
            <br />
            Millionaire - $4.53
            <br />
            Signature - $5.67
          </Popup>
        </Marker>
      );
    });

    const map = (
      <Map
        center={position}
        zoom={5}
        style={{ width: "100%", height: "600px" }}
        onClick={this.handleMapClick}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {this.state.bluemarble ? (
          <WMSTileLayer
            url="https://demo.boundlessgeo.com/geoserver/ows"
            layers="nasa:bluemarble"
          />
        ) : (
          <WMSTileLayer
            url="http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi"
            layers="nexrad-n0r-900913"
            format="image/png"
            transparent={true}
            attribution="Weather data © 2012 IEM Nexrad"
          />
        )}
        {markers}
      </Map>
    );
    return (
      <div>
        <Card>
          <CardBody>
            <h5 className="mb-4">World Map</h5>
            {map}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default WorldMap;
