import React from "react";
import ReactDOM from "react-dom";
//import { createBrowserHistory } from 'history';
//import indexRoutes from "./routes/index.jsx";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import HomeLayout from "./layouts/HomeLayout";
import FullLayout from "./layouts/FullLayout";
//const hist = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} key={key} component={prop.component} />;
      })} */}

      <Route exact path="/" component={HomeLayout} />
      <Route path="/customer" component={FullLayout} />
      {/* <Route path="/:user" component={User}/> */}
      {/* <Route component={NoMatch} /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
