import React, {Component} from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

class TextBox extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {

        let textBox;

        if (this.props.mode === "edit") {
            textBox = <Input
                name={this.props.fieldName}
                value={this.props.fieldValue || ''}
                type={this.props.type || 'text'}
                maxLength={this.props.maxLength}
                placeholder={this.props.placeholderText} 
                bsSize="sm"
                onChange={e => this.props.handleInputChange(e)}
            />
        } else if (this.props.mode === "add") {
            textBox = <Input
            name={this.props.fieldName}
            value={this.props.fieldValue || ''}
            type={this.props.type || 'text'}
            maxLength={this.props.maxLength}
            placeholder={this.props.placeholderText} 
            bsSize="sm"
            onChange={e => this.props.handleInputChange(e)}
        />
        } else {
            textBox = <Input
                name={this.props.fieldName}
                value={this.props.fieldValue || ''}
                type={this.props.type}
                placeholder={this.props.placeholderText} 
                readOnly
                style={this.props.style}
                bsSize="sm"
            />
        }

        return (
            <FormGroup>
                <Label for={this.props.fieldName} size="sm">{this.props.fieldTitle || _.startCase(this.props.fieldName)}</Label>
                {textBox}
            </FormGroup>
        )
    }
}

TextBox.propTypes = {
    mode: PropTypes.string,             //Mode: view, edit, add
    name: PropTypes.string,             //DOM name
    type: PropTypes.string,  //Type: text, date, number, textarea, email, password, url, datetime, color, search,
    value: PropTypes.string,            //Value of the input object
    placeholder: PropTypes.string,      //Default text when no value exists
    isDisabled: PropTypes.bool,         //Set the input to be disabled
    isReadOnly: PropTypes.bool,         //Override the readOnly property
    style: PropTypes.string,            //Any style overrides for the input object
    maxLength: PropTypes.string,        //Maximum characters for input box
}

export default TextBox;