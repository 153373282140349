/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

class NavBar extends React.Component {
  state = { isOpen: false };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <nav className="navbar navbar-area navbar-expand-lg navbar-light ">
        <div className="container">
          <div className="collapse navbar-collapse" id="flybuzzNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link pl-0">
                  Home<span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  href="#"
                >
                  Solutions
                </a>
                <div className="dropdown-menu">
                  <a href="#" className="dropdown-item">
                    Tender Management
                  </a>
                  <a href="#" className="dropdown-item">
                    Fuel Price Comparison
                  </a>
                  <a href="#" className="dropdown-item">
                    Trip Planning
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Pricing
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#">
                  Updates
                </a>
                <div className="dropdown-menu">
                  <a href="#" className="dropdown-item">
                    Latest News
                  </a>
                  <a href="#" className="dropdown-item">
                    NOTAMS
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div className="navbar-btn-wrapper">
            <Link to="/customer" className="boxed-btn btn-rounded">
              Submit a Tender
            </Link>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#flybuzzNav"
            aria-controls="flybuzzNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </nav>
    );
  }
}
export default NavBar;
