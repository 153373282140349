import React, { Component } from "react";
//import moment from 'moment'
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  FormText
} from "reactstrap";
import TextBox from "../../components/form-components/TextBox";
import Select from "../../components/form-components/Select";
import { db } from "../../firebase";

const INITIAL_STATE = {
  key: null,
  id: "",
  type: "",
  gallons: 0,
  icao: "",
  baseRate: 0,
  differentialRate: 0,
  intoPlaneRate: 0,
  federalTax: 0,
  federalOilSpillTax: 0,
  statePermitFee: 0,
  statePetroleumTax: 0,
  stateGrossReceiptTax: 0,
  airportFlowFee: 0,
  otherTaxRate: 0,
  otherAirportFee: 0,
  totalCost: 0,
  requestedDate: "",
  requestedBy: "",
  receivedDate: "",
  receivedBy: "",
  respondedDate: "",
  respondedBy: "",
  acceptedDate: "",
  acceptedBy: "",
  //---------------------
  modal: false,
  error: null,
  mode: "add"
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

class Tender extends Component {
  constructor(props) {
    super(props);
    if (this.props.item) {
      this.state = {
        ...this.props.item,
        mode: this.props.mode
      };
    } else {
      this.state = {
        ...INITIAL_STATE,
        mode: this.props.mode
      };
    }
  }

  toggle = e => {
    this.setState({
      ...this.props.item,
      modal: !this.state.modal,
      mode: this.props.mode
    });
    this.props.handleRefresh();
  };

  handleEditButton = e => {
    e.preventDefault();
    this.setState({
      mode: "edit"
    });
  };

  handleCancelButton = e => {
    e.preventDefault();
    this.setState({
      ...INITIAL_STATE,
      mode: "view"
    });
    this.toggle();
  };

  handleDeleteButton = e => {
    e.preventDefault();
    console.log(this.props.baseObject, this.state.key);
    db.remove(this.props.baseObject, this.state.key)
      .then(this.setState({ mode: "view" }))
      .then(this.props.handleRefresh())
      .then(this.toggle())
      .catch(error => {
        alert(error);
      });
  };

  handleInputChange = e => {
    this.setState(byPropKey(e.target.name, e.target.value));
  };

  handleSaveChanges = e => {
    e.preventDefault();
    const item = {
      key: this.state.key,
      id: this.state.id || "",
      type: this.state.type || "",
      gallons: this.state.gallons || 0,
      icao: this.state.icao || "",
      baseRate: this.state.baseRate || 0,
      differentialRate: this.state.differentialRate || 0,
      intoPlaneRate: this.state.intoPlaneRate || 0,
      federalTax: this.state.federalTax || 0,
      federalOilSpillTax: this.state.federalOilSpillTax || 0,
      statePermitFee: this.state.statePermitFee || 0,
      statePetroleumTax: this.state.statePetroleumTax || 0,
      stateGrossReceiptTax: this.state.stateGrossReceiptTax || 0,
      airportFlowFee: this.state.airportFlowFee || 0,
      otherTaxRate: this.state.otherTaxRate || 0,
      otherAirportFee: this.state.otherAirportFee || 0,
      totalCost: this.state.totalCost || 0,
      requestedDate: this.state.requestedDate || "",
      requestedBy: this.state.requestedBy || "",
      receivedDate: this.state.receivedDate || "",
      receivedBy: this.state.receivedBy || "",
      respondedDate: this.state.respondedDate || "",
      respondedBy: this.state.respondedBy || "",
      acceptedDate: this.state.acceptedDate || "",
      acceptedBy: this.state.acceptedBy || ""
    };
    db.save(this.props.baseObject, item)
      .then(
        this.setState({
          ...INITIAL_STATE,
          mode: "view"
        })
      )
      .then(this.props.handleRefresh())
      .then(this.toggle())
      .catch(error => {
        alert(error);
      });
  };

  render() {
    const {
      //            key,
      id,
      type,
      gallons,
      icao,
      baseRate,
      differentialRate,
      intoPlaneRate,
      federalTax,
      federalOilSpillTax,
      statePermitFee,
      statePetroleumTax,
      stateGrossReceiptTax,
      airportFlowFee,
      otherTaxRate,
      otherAirportFee,
      totalCost,
      requestedDate,
      requestedBy,
      receivedDate,
      receivedBy,
      respondedDate,
      respondedBy,
      acceptedDate,
      acceptedBy,
      //-----------------
      error
    } = this.state;

    const isInvalid = id === "" || type === "" || gallons === "" || icao === "";
    const closeButton = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );

    return (
      <div>
        <Button onClick={this.toggle} size="sm" className="btn" color="primary">
          {this.props.buttonText}
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          close={closeButton}
          className={this.props.className}
          backdrop="static"
          size="lg"
        >
          <Form onSubmit={this.onSubmit}>
            <ModalHeader
              toggle={this.toggle}
              className="modal-custom text-right"
            >
              <div className="float-left">{this.props.baseObject}: </div>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm="6">
                  <TextBox
                    fieldName="id"
                    fieldValue={id}
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <Select
                    fieldName="type"
                    fieldValue={type}
                    listValues={this.props.tenderTypes}
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="totalCost"
                    fieldValue={totalCost}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                </Col>
                <Col sm="6">
                  <TextBox
                    fieldName="gallons"
                    fieldValue={gallons}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <Select
                    fieldName="icao"
                    fieldValue={icao}
                    listValues={this.props.icaos}
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm="3">
                  <TextBox
                    fieldName="baseRate"
                    fieldValue={baseRate}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="differentialRate"
                    fieldValue={differentialRate}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="intoPlaneRate"
                    fieldValue={intoPlaneRate}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                </Col>
                <Col sm="3">
                  <TextBox
                    fieldName="airportFlowFee"
                    fieldValue={airportFlowFee}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="otherAirportFee"
                    fieldValue={otherAirportFee}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                </Col>
                <Col sm="3">
                  <TextBox
                    fieldName="federalTax"
                    fieldValue={federalTax}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="federalOilSpillTax"
                    fieldValue={federalOilSpillTax}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="otherTaxRate"
                    fieldValue={otherTaxRate}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                </Col>
                <Col sm="3">
                  <TextBox
                    fieldName="statePermitFee"
                    fieldValue={statePermitFee}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="statePetroleumTax"
                    fieldValue={statePetroleumTax}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="stateGrossReceiptTax"
                    fieldValue={stateGrossReceiptTax}
                    type="number"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm="3">
                  <TextBox
                    fieldName="requestedDate"
                    fieldValue={requestedDate}
                    type="date"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="receivedDate"
                    fieldValue={receivedDate}
                    type="date"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                </Col>
                <Col sm="3">
                  <TextBox
                    fieldName="requestedBy"
                    fieldValue={requestedBy}
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="receivedBy"
                    fieldValue={receivedBy}
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                </Col>
                <Col sm="3">
                  <TextBox
                    fieldName="respondedDate"
                    fieldValue={respondedDate}
                    type="date"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="acceptedDate"
                    fieldValue={acceptedDate}
                    type="date"
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                </Col>
                <Col sm="3">
                  <TextBox
                    fieldName="respondedBy"
                    fieldValue={respondedBy}
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                  <TextBox
                    fieldName="acceptedBy"
                    fieldValue={acceptedBy}
                    mode={this.state.mode}
                    handleInputChange={this.handleInputChange}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <FormGroup>
                <FormText>{error && <p>{error.message}</p>}</FormText>
              </FormGroup>
              {(this.state.mode === "edit" || this.state.mode === "add") && (
                <Button
                  disabled={isInvalid}
                  type="submit"
                  color="primary"
                  onClick={this.handleSaveChanges}
                >
                  Save Changes
                </Button>
              )}{" "}
              {this.state.mode !== "edit" && this.state.mode !== "add" && (
                <Button
                  type="submit"
                  color="secondary"
                  onClick={this.handleEditButton}
                >
                  Edit
                </Button>
              )}{" "}
              {(this.state.mode === "edit" || this.state.mode === "add") && (
                <Button color="secondary" onClick={this.handleCancelButton}>
                  Cancel
                </Button>
              )}
              {this.state.mode !== "edit" && this.state.mode !== "add" && (
                <Button
                  type="submit"
                  color="secondary"
                  onClick={this.handleDeleteButton}
                >
                  Delete
                </Button>
              )}{" "}
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Tender;
