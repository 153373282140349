import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ICAO from './ICAO';
import { Row, Col, } from 'reactstrap';
// import { airports } from '../../../data/airport-data';

class ICAOs extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            baseObject: "ICAOs",
        };
        this.getData = this.getData.bind(this);
//        this.removeData = this.removeData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        // this.setState({ data: airports })
        // eslint-disable-next-line
        // db.list(this.state.baseObject)
        // .then((collection) => {
        //     if(collection){
        //         const items = collection.docs.reduce((res, item) => ({...res, [item.id]: item.data()}), {});
        //         const results = Object.entries(items).map(e => Object.assign(e[1], { key: e[0] }));
        //         this.setState({data: results})
        //     }
        //     else
        //     {
        //         this.setState({data: []});
        //     }
        // });
    }

    render() {
        const { data } = this.state;
        return (
            <div>
                <ReactTable
                    data={ data }
                    columns={[
                        {
                            Header: () => (
                                <Row>
                                    <Col xs={{ size: 8, offset: 2}}><h2>{this.state.baseObject}</h2></Col>
                                    <Col xs="2" className="text-right">
                                    <ICAO
                                        baseObject={this.state.baseObject}
                                        handleRefresh={this.getData} 
                                        buttonText='Add New'
                                        mode='add'
                                    />
                                    </Col>
                                </Row>
                            ),
                            columns: [
                                {
                                    Header: "",
                                    width: 90,
                                    id: "edit",
                                    filterable: false,
                                    sortable: false,
                                    Cell: ({original}) => (
                                        <Col className="text-right">
                                            <ICAO
                                                baseObject={this.state.baseObject}
                                                item={original} 
                                                handleRefresh={this.getData}  
                                                buttonText='View'
                                                mode="view"
                                                />
                                        </Col>
                                    )
                                },
                                {
                                    Header: "ICAO",
                                    accessor: "ICAO",
                                },
                                {
                                    Header: "IATA",
                                    accessor: "IATA",
                                },
                                {
                                    Header: "Title",
                                    accessor: "Airport",
                                },
                                {
                                    Header: "Country",
                                    accessor: "Country",
                                },
                                {
                                    Header: "Altitude",
                                    accessor: "Altitude",
                                },
                                {
                                    Header: "Coordinates",
                                    accessor: "LatitudeDecimal",
                                    Cell: props => (
                                        <span>{`${props.original.LongitudeDecimal} - ${props.original.LatitudeDecimal}`}</span>
                                    )
                                },
                            ]
                        }

                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                />
            </div>
        );
    }
}

export default ICAOs;
