/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class BackToTop extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <div className="back-to-top">
        <i className="fas fa-rocket" />
      </div>
    );
  }
}
export default BackToTop;
