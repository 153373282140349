/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class PricingPlan extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <section className="pricing-plan-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <div className="section-title">
                <h2>Pricing Plans</h2>
                <p>
                  Our extensive portfolio of into-wing suppliers provides a
                  variety of choices with the assurance that your fuel is of the
                  highest quality and will be there on time every time.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-10 remove-col-padding">
              <div className="single-wide-plans">
                <div className="row">
                  <div className=" col-md-3 col-lg-3">
                    <div className="header">
                      <span className="price">Free Trail</span>
                      <h4 className="title">Starter</h4>
                    </div>
                  </div>
                  <div className=" col-md-3 col-lg-3">
                    <div className="rules">
                      <ul>
                        <li>
                          <i className="fas fa-check" /> 1 Hour Fly
                        </li>
                        <li>
                          <i className="fas fa-check" /> 1 Private Jet
                        </li>
                        <li>
                          <i className="fas fa-check" /> 10 Cabin Staff
                        </li>
                        <li>
                          <i className="fas fa-check" /> 1 Large Seat
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className=" col-md-3 col-lg-3">
                    <div className="rules">
                      <ul>
                        <li>
                          <i className="fas fa-check" /> 5 Sky Jump
                        </li>
                        <li>
                          <i className="fas fa-check" /> 24/7 Support
                        </li>
                        <li>
                          <i className="fas fa-check" /> Free Food
                        </li>
                        <li>
                          <i className="fas fa-check" /> Free Shipping
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className=" col-md-3 col-lg-3 remove-col-padding">
                    <div className="btn-wrapper">
                      <div className="btn-wrapper-inner">
                        <a
                          href="pricing.html"
                          className="boxed-btn btn-rounded"
                        >
                          Purshase now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="single-wide-plans">
                <div className="row">
                  <div className="col-md-3 col-lg-3">
                    <div className="header">
                      <span className="price">$100/mo</span>
                      <h4 className="title">Advanced</h4>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="rules">
                      <ul>
                        <li>
                          <i className="fas fa-check" /> 1 Hour Fly
                        </li>
                        <li>
                          <i className="fas fa-check" /> 1 Private Jet
                        </li>
                        <li>
                          <i className="fas fa-check" /> 10 Cabin Staff
                        </li>
                        <li>
                          <i className="fas fa-check" /> 1 Large Seat
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="rules">
                      <ul>
                        <li>
                          <i className="fas fa-check" /> 5 Sky Jump
                        </li>
                        <li>
                          <i className="fas fa-check" /> 24/7 Support
                        </li>
                        <li>
                          <i className="fas fa-check" /> Free Food
                        </li>
                        <li>
                          <i className="fas fa-check" /> Free Shipping
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 remove-col-padding">
                    <div className="btn-wrapper">
                      <div className="btn-wrapper-inner">
                        <a
                          href="pricing.html"
                          className="boxed-btn btn-rounded"
                        >
                          Purshase now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="single-wide-plans">
                <div className="row">
                  <div className="col-md-3 col-lg-3">
                    <div className="header">
                      <span className="price">$200/mo</span>
                      <h4 className="title">Full Team</h4>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="rules">
                      <ul>
                        <li>
                          <i className="fas fa-check" /> 1 Hour Fly
                        </li>
                        <li>
                          <i className="fas fa-check" /> 1 Private Jet
                        </li>
                        <li>
                          <i className="fas fa-check" /> 10 Cabin Staff
                        </li>
                        <li>
                          <i className="fas fa-check" /> 1 Large Seat
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="rules">
                      <ul>
                        <li>
                          <i className="fas fa-check" /> 5 Sky Jump
                        </li>
                        <li>
                          <i className="fas fa-check" /> 24/7 Support
                        </li>
                        <li>
                          <i className="fas fa-check" /> Free Food
                        </li>
                        <li>
                          <i className="fas fa-check" /> Free Shipping
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 remove-col-padding">
                    <div className="btn-wrapper">
                      <div className="btn-wrapper-inner">
                        <a
                          href="pricing.html"
                          className="boxed-btn btn-rounded"
                        >
                          Purshase now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="single-wide-plans">
                <div className="row">
                  <div className="col-md-3 col-lg-3">
                    <div className="header">
                      <span className="price">$500/mo</span>
                      <h4 className="title">Premium</h4>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="rules">
                      <ul>
                        <li>
                          <i className="fas fa-check" /> 1 Hour Fly
                        </li>
                        <li>
                          <i className="fas fa-check" /> 1 Private Jet
                        </li>
                        <li>
                          <i className="fas fa-check" /> 10 Cabin Staff
                        </li>
                        <li>
                          <i className="fas fa-check" /> 1 Large Seat
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="rules">
                      <ul>
                        <li>
                          <i className="fas fa-check" /> 5 Sky Jump
                        </li>
                        <li>
                          <i className="fas fa-check" /> 24/7 Support
                        </li>
                        <li>
                          <i className="fas fa-check" /> Free Food
                        </li>
                        <li>
                          <i className="fas fa-check" /> Free Shipping
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 remove-col-padding">
                    <div className="btn-wrapper">
                      <div className="btn-wrapper-inner">
                        <a
                          href="pricing.html"
                          className="boxed-btn btn-rounded"
                        >
                          Purshase now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default PricingPlan;
