/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class Portfolio extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <section className="portfolio-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <div className="section-title">
                <h2>Our Portfolio</h2>
                <p>
                  Our extensive portfolio of into-wing suppliers provides a
                  variety of choices with the assurance that your fuel is of the
                  highest quality and will be there on time every time.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-menu-wrapper text-center">
                <ul>
                  <li className="active" data-filter="*">
                    All
                  </li>
                  <li data-filter=".heli">Helocopter</li>
                  <li data-filter=".flight">Flight</li>
                  <li data-filter=".aviation">Aviation</li>
                  <li data-filter=".pilot">Pilot</li>
                </ul>
              </div>

              <div
                className="portfolio-content-wrapper"
                id="portfolio-content-wrapper"
              >
                <div className="single-portfolio-wrapper grid-item heli flight">
                  <img
                    src="assets/img/portfolio/01.jpg"
                    alt="portfolio images"
                  />
                </div>
                <div className="single-portfolio-wrapper grid-item flight">
                  <img
                    src="assets/img/portfolio/02.jpg"
                    alt="portfolio images"
                  />
                </div>
                <div className="single-portfolio-wrapper grid-item aviation">
                  <img
                    src="assets/img/portfolio/03.jpg"
                    alt="portfolio images"
                  />
                </div>
                <div className="single-portfolio-wrapper grid-item pilot">
                  <img
                    src="assets/img/portfolio/04.jpg"
                    alt="portfolio images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Portfolio;
