import { db } from './firebase';

// User API

// export const doCreateUser = (id, username, email) =>
// db.ref(`users/${id}`).set({
//   username,
//   email,
// });

// export const onceGetUsers = () =>
// db.ref('users').once('value');

export const list = (baseObject) =>
db.collection(baseObject).get()
  .then((snapshot) => {
    if(snapshot.empty) {
      console.log('No ', baseObject, 's found');
      return;
    }
    // snapshot.forEach((creator) => {
    //   console.log('Creator: ', creator.data());
    // })
    return snapshot;
  })
  .catch(err => {
      console.log('Error getting creators', err);
  })

export const save = (baseObject, item) => {
  if(item.key === null) {
    console.log("ID=", item.key, ", baseObject=", baseObject, ", args=", ...[item])
    return db.collection(baseObject).add({
      ...item
    })
    .then(ref => {
      console.log(baseObject, " #", ref.id, " was created")
    })
    .catch(error => {
      console.log('Failed to create ', baseObject, " with error: ", error);
    });
  }
  else {
    return db.collection(baseObject).doc(item.key).set({
      ...item
    })
    .then(
      console.log(baseObject, " #", item.key, " was updated")
    )
    .catch(error => {
      console.log('Failed to update ', baseObject, " with error: ", error);
    });
  }
}

export const remove = (baseObject, key) => {
  return db.collection(baseObject).doc(key).delete()
  .then(ref => {
    console.log(baseObject, " #", key, " was removed")
  })
  .catch(error => {
    console.log('Failed to remove ', baseObject, " with error: ", error);
  });
}
