import user1 from '../../assets/images/users/1.jpg';
import user2 from '../../assets/images/users/2.jpg';
// import user3 from '../../assets/images/users/3.jpg';
// import user4 from '../../assets/images/users/4.jpg';

import img1 from '../../assets/images/big/img1.jpg';
import img2 from '../../assets/images/big/img2.jpg';
import img3 from '../../assets/images/big/img3.jpg';

const messages = [
    {
        "id": 1,
        "image": user1,
        "status": "online",
        "title": "Uplift Today",
        "desc": "You have a flight today at KTEB",
        "time": "09:10 CST"
    },
    {
        "id": 2,
        "image": user2,
        "status": "busy",
        "title": "Tender Offer",
        "desc": "Signature offers $4.63 for KHOU",
        "time": "09:02 CST"
    },

];

const notifications = [
    {
        "id": 1,
        "iconclass": "fa fa-link",
        "iconbg": "primary",
        "title": "KTEB Uplift Approval",
        "desc": "Approval not yet submitted for KTEB uplift",
        "time": "09:30 MST"
    },
    {
        "id": 2,
        "iconclass": "ti-calendar",
        "iconbg": "success",
        "title": "Successful Uplift",
        "desc": "KJAX uplift completed without incident",
        "time": "21:10 MST"
    },
    {
        "id": 3,
        "iconclass": "ti-settings",
        "iconbg": "info",
        "title": "Profile Information Needed",
        "desc": "Passport information lacks Visa information",
        "time": "09:08 MST"
    },
    {
        "id": 4,
        "iconclass": "ti-user",
        "iconbg": "primary",
        "title": "New Tender Received",
        "desc": "Tender offer has been delivered to Signature @ KHOU",
        "time": "9:02 MST"
    }
]

/*--------------------------------------------------------------------------------*/
/* For Mega Menu Carousel                                                         */
/*--------------------------------------------------------------------------------*/
const items = [
    {
        src: img1,
        altText: 'Slide 1',
        caption: 'Slide 1'
    },
    {
        src: img2,
        altText: 'Slide 2',
        caption: 'Slide 2'
    },
    {
        src: img3,
        altText: 'Slide 3',
        caption: 'Slide 3'
    }
];

export { messages, notifications, items };