const airports = [
    {
      "ICAO": "KABI",
      "IATA": "ABI",
      "Airport": "ABILENE RGNL",
      "City": "ABILENE",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 24,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 99,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 54,
      "LongitudeDirection": "W",
      "Altitude": 546,
      "LatitudeDecimal": 32.411,
      "LongitudeDecimal": -99.682
    },
    {
      "ICAO": "KABQ",
      "IATA": "ABQ",
      "Airport": "N/A",
      "City": "ALBUQUERQUE",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KACK",
      "IATA": "ACK",
      "Airport": "NANTUCKET MEM",
      "City": "NANTUCKET",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 15,
      "LatitudeSeconds": 10,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 70,
      "LongitudeMinutes": 3,
      "LongitudeSeconds": 36,
      "LongitudeDirection": "W",
      "Altitude": 15,
      "LatitudeDecimal": 41.253,
      "LongitudeDecimal": -70.06
    },
    {
      "ICAO": "KACT",
      "IATA": "ACT",
      "Airport": "WACO RGNL",
      "City": "WACO",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 36,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 49,
      "LongitudeDirection": "W",
      "Altitude": 158,
      "LatitudeDecimal": 31.611,
      "LongitudeDecimal": -97.23
    },
    {
      "ICAO": "KACY",
      "IATA": "ACY",
      "Airport": "ATLANTIC CITY INTERNATIONAL",
      "City": "ATLANTIC CITY",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 27,
      "LatitudeSeconds": 27,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 74,
      "LongitudeMinutes": 34,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 23,
      "LatitudeDecimal": 39.458,
      "LongitudeDecimal": -74.577
    },
    {
      "ICAO": "KADM",
      "IATA": "ADM",
      "Airport": "ARDMORE MUNI",
      "City": "ARDMORE",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 18,
      "LatitudeSeconds": 11,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 1,
      "LongitudeSeconds": 10,
      "LongitudeDirection": "W",
      "Altitude": 233,
      "LatitudeDecimal": 34.303,
      "LongitudeDecimal": -97.019
    },
    {
      "ICAO": "KADW",
      "IATA": "ADW",
      "Airport": "ANDREWS AFB",
      "City": "CAMP SPRINGS",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 48,
      "LatitudeSeconds": 38,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 52,
      "LongitudeSeconds": 1,
      "LongitudeDirection": "W",
      "Altitude": 86,
      "LatitudeDecimal": 38.811,
      "LongitudeDecimal": -76.867
    },
    {
      "ICAO": "KAEX",
      "IATA": "AEX",
      "Airport": "ALEXANDRIA INTERNATIONAL",
      "City": "ALEXANDRIA",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 19,
      "LatitudeSeconds": 38,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 32,
      "LongitudeSeconds": 54,
      "LongitudeDirection": "W",
      "Altitude": 28,
      "LatitudeDecimal": 31.327,
      "LongitudeDecimal": -92.548
    },
    {
      "ICAO": "KAFB",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SHEPPARD",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KAGS",
      "IATA": "AGS",
      "Airport": "AUGUSTA RGNL AT BUSH FLD",
      "City": "BUSH FIELD",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 22,
      "LatitudeSeconds": 11,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 57,
      "LongitudeSeconds": 52,
      "LongitudeDirection": "W",
      "Altitude": 44,
      "LatitudeDecimal": 33.37,
      "LongitudeDecimal": -81.964
    },
    {
      "ICAO": "KAKR",
      "IATA": "AKR",
      "Airport": "AKRON FULTON INTERNATIONAL",
      "City": "AKRON",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 2,
      "LatitudeSeconds": 15,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 28,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "W",
      "Altitude": 326,
      "LatitudeDecimal": 41.038,
      "LongitudeDecimal": -81.467
    },
    {
      "ICAO": "KALB",
      "IATA": "ALB",
      "Airport": "ALBANY INTERNATIONAL",
      "City": "ALBANY",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 44,
      "LatitudeSeconds": 53,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 73,
      "LongitudeMinutes": 48,
      "LongitudeSeconds": 10,
      "LongitudeDirection": "W",
      "Altitude": 87,
      "LatitudeDecimal": 42.748,
      "LongitudeDecimal": -73.803
    },
    {
      "ICAO": "KALC",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "LIME ACRES",
      "Country": "SOUTH AFRICA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KALI",
      "IATA": "ALI",
      "Airport": "ALICE INTERNATIONAL",
      "City": "ALICE",
      "Country": "USA",
      "LatitudeDegrees": 27,
      "LatitudeMinutes": 44,
      "LatitudeSeconds": 27,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 98,
      "LongitudeMinutes": 1,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 55,
      "LatitudeDecimal": 27.741,
      "LongitudeDecimal": -98.027
    },
    {
      "ICAO": "KAMA",
      "IATA": "AMA",
      "Airport": "AMARILLO INTERNATIONAL",
      "City": "AMARILLO",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 13,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 101,
      "LongitudeMinutes": 42,
      "LongitudeSeconds": 21,
      "LongitudeDirection": "W",
      "Altitude": 1100,
      "LatitudeDecimal": 35.219,
      "LongitudeDecimal": -101.706
    },
    {
      "ICAO": "KANB",
      "IATA": "ANB",
      "Airport": "ANNISTON METROPOLITAN",
      "City": "ANNISTON",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 35,
      "LatitudeSeconds": 17,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 85,
      "LongitudeMinutes": 51,
      "LongitudeSeconds": 29,
      "LongitudeDirection": "W",
      "Altitude": 187,
      "LatitudeDecimal": 33.588,
      "LongitudeDecimal": -85.858
    },
    {
      "ICAO": "KAND",
      "IATA": "AND",
      "Airport": "ANDERSON RGNL",
      "City": "ANDERSEN",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 29,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 42,
      "LongitudeSeconds": 33,
      "LongitudeDirection": "W",
      "Altitude": 239,
      "LatitudeDecimal": 34.495,
      "LongitudeDecimal": -82.709
    },
    {
      "ICAO": "KAOO",
      "IATA": "AOO",
      "Airport": "ALTOONA BLAIR CO",
      "City": "ALTOONA",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 17,
      "LatitudeSeconds": 47,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 78,
      "LongitudeMinutes": 19,
      "LongitudeSeconds": 12,
      "LongitudeDirection": "W",
      "Altitude": 459,
      "LatitudeDecimal": 40.296,
      "LongitudeDecimal": -78.32
    },
    {
      "ICAO": "KAPG",
      "IATA": "APG",
      "Airport": "PHILLIPS AAF",
      "City": "ABERDEEN",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 27,
      "LatitudeSeconds": 58,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 10,
      "LongitudeSeconds": 10,
      "LongitudeDirection": "W",
      "Altitude": 18,
      "LatitudeDecimal": 39.466,
      "LongitudeDecimal": -76.169
    },
    {
      "ICAO": "KARA",
      "IATA": "ARA",
      "Airport": "ACADIANA REGIONAL",
      "City": "LOUISIANA",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 2,
      "LatitudeSeconds": 15,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 91,
      "LongitudeMinutes": 53,
      "LongitudeSeconds": 2,
      "LongitudeDirection": "W",
      "Altitude": 8,
      "LatitudeDecimal": 30.038,
      "LongitudeDecimal": -91.884
    },
    {
      "ICAO": "KART",
      "IATA": "ART",
      "Airport": "WATERTOWN INTERNATIONAL",
      "City": "WATERTOWN",
      "Country": "USA",
      "LatitudeDegrees": 43,
      "LatitudeMinutes": 59,
      "LatitudeSeconds": 30,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 1,
      "LongitudeSeconds": 18,
      "LongitudeDirection": "W",
      "Altitude": 100,
      "LatitudeDecimal": 43.992,
      "LongitudeDecimal": -76.022
    },
    {
      "ICAO": "KATL",
      "IATA": "ATL",
      "Airport": "THE WILLIAM B HARTSFIELD ATLANTA INTERNATIONAL",
      "City": "ATLANTA",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 25,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 84,
      "LongitudeMinutes": 25,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 313,
      "LatitudeDecimal": 33.64,
      "LongitudeDecimal": -84.427
    },
    {
      "ICAO": "KAUG",
      "IATA": "AUG",
      "Airport": "AUGUSTA STATE",
      "City": "AUGUSTA",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 19,
      "LatitudeSeconds": 14,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 69,
      "LongitudeMinutes": 47,
      "LongitudeSeconds": 50,
      "LongitudeDirection": "W",
      "Altitude": 108,
      "LatitudeDecimal": 44.321,
      "LongitudeDecimal": -69.797
    },
    {
      "ICAO": "KAUS",
      "IATA": "AUS",
      "Airport": "AUSTIN BERGSTROM INTERNATIONAL",
      "City": "AUSTIN",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 11,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 11,
      "LongitudeDirection": "W",
      "Altitude": 166,
      "LatitudeDecimal": 30.194,
      "LongitudeDecimal": -97.67
    },
    {
      "ICAO": "KAYE",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "FORT DEVENS",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KBAB",
      "IATA": "BAB",
      "Airport": "BEALE AFB",
      "City": "MARYSVILLE",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 8,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 121,
      "LongitudeMinutes": 26,
      "LongitudeSeconds": 11,
      "LongitudeDirection": "W",
      "Altitude": 35,
      "LatitudeDecimal": 39.136,
      "LongitudeDecimal": -121.436
    },
    {
      "ICAO": "KBAD",
      "IATA": "BAD",
      "Airport": "BARKSDALE AFB",
      "City": "SHREVEPORT",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 30,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 39,
      "LongitudeSeconds": 45,
      "LongitudeDirection": "W",
      "Altitude": 51,
      "LatitudeDecimal": 32.502,
      "LongitudeDecimal": -93.663
    },
    {
      "ICAO": "KBCT",
      "IATA": "BCT",
      "Airport": "BOCA RATON",
      "City": "BOCA RATON",
      "Country": "USA",
      "LatitudeDegrees": 26,
      "LatitudeMinutes": 22,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 6,
      "LongitudeSeconds": 27,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 26.378,
      "LongitudeDecimal": -80.107
    },
    {
      "ICAO": "KBDE",
      "IATA": "BDE",
      "Airport": "BAUDETTE INTERNATIONAL",
      "City": "BAUDETTE",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 332,
      "LatitudeDecimal": 48.728,
      "LongitudeDecimal": -94.612
    },
    {
      "ICAO": "KBDL",
      "IATA": "BDL",
      "Airport": "BRADLEY INTERNATIONAL",
      "City": "WINDSOR LOCKS",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 20,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 72,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 59,
      "LongitudeDirection": "W",
      "Altitude": 53,
      "LatitudeDecimal": 41.939,
      "LongitudeDecimal": -72.683
    },
    {
      "ICAO": "KBDR",
      "IATA": "BDR",
      "Airport": "IGOR I SIKORSKY MEM",
      "City": "STRATFORD",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 9,
      "LatitudeSeconds": 48,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 73,
      "LongitudeMinutes": 7,
      "LongitudeSeconds": 34,
      "LongitudeDirection": "W",
      "Altitude": 3,
      "LatitudeDecimal": 41.163,
      "LongitudeDecimal": -73.126
    },
    {
      "ICAO": "KBED",
      "IATA": "BED",
      "Airport": "LAURENCE G HANSCOM FLD",
      "City": "BEDFORD",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 28,
      "LatitudeSeconds": 11,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 71,
      "LongitudeMinutes": 17,
      "LongitudeSeconds": 20,
      "LongitudeDirection": "W",
      "Altitude": 41,
      "LatitudeDecimal": 42.47,
      "LongitudeDecimal": -71.289
    },
    {
      "ICAO": "KBFI",
      "IATA": "BFI",
      "Airport": "BOEING FLD KING CO INTERNATIONAL",
      "City": "SEATTLE",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 31,
      "LatitudeSeconds": 47,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 18,
      "LongitudeSeconds": 7,
      "LongitudeDirection": "W",
      "Altitude": 6,
      "LatitudeDecimal": 47.53,
      "LongitudeDecimal": -122.302
    },
    {
      "ICAO": "KBFL",
      "IATA": "BFL",
      "Airport": "MEADOWS FLD",
      "City": "BAKERSFIELD",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 26,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 119,
      "LongitudeMinutes": 3,
      "LongitudeSeconds": 24,
      "LongitudeDirection": "W",
      "Altitude": 155,
      "LatitudeDecimal": 35.433,
      "LongitudeDecimal": -119.057
    },
    {
      "ICAO": "KBFM",
      "IATA": "BFM",
      "Airport": "MOBILE DOWNTOWN",
      "City": "MOBILE",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 37,
      "LatitudeSeconds": 35,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 88,
      "LongitudeMinutes": 4,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 8,
      "LatitudeDecimal": 30.626,
      "LongitudeDecimal": -88.068
    },
    {
      "ICAO": "KBGR",
      "IATA": "BGR",
      "Airport": "BANGOR INTERNATIONAL",
      "City": "BANGOR",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 48,
      "LatitudeSeconds": 26,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 68,
      "LongitudeMinutes": 49,
      "LongitudeSeconds": 41,
      "LongitudeDirection": "W",
      "Altitude": 59,
      "LatitudeDecimal": 44.807,
      "LongitudeDecimal": -68.828
    },
    {
      "ICAO": "KBGS",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "BIG SPRINGS",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KBHM",
      "IATA": "BHM",
      "Airport": "BIRMINGHAM INTERNATIONAL",
      "City": "BIRMINGHAM",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 33,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 86,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 12,
      "LongitudeDirection": "W",
      "Altitude": 197,
      "LatitudeDecimal": 33.563,
      "LongitudeDecimal": -86.753
    },
    {
      "ICAO": "KBIF",
      "IATA": "BIF",
      "Airport": "BIGGS AAF",
      "City": "EL PASO",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 50,
      "LatitudeSeconds": 58,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 106,
      "LongitudeMinutes": 22,
      "LongitudeSeconds": 48,
      "LongitudeDirection": "W",
      "Altitude": 1203,
      "LatitudeDecimal": 31.849,
      "LongitudeDecimal": -106.38
    },
    {
      "ICAO": "KBIX",
      "IATA": "BIX",
      "Airport": "KEESLER AFB",
      "City": "BILOXI",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 24,
      "LatitudeSeconds": 39,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 88,
      "LongitudeMinutes": 55,
      "LongitudeSeconds": 25,
      "LongitudeDirection": "W",
      "Altitude": 11,
      "LatitudeDecimal": 30.411,
      "LongitudeDecimal": -88.924
    },
    {
      "ICAO": "KBKF",
      "IATA": "BKF",
      "Airport": "BUCKLEY AFB",
      "City": "BUCKLEY",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 42,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 104,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 5,
      "LongitudeDirection": "W",
      "Altitude": 1726,
      "LatitudeDecimal": 39.702,
      "LongitudeDecimal": -104.751
    },
    {
      "ICAO": "KBLI",
      "IATA": "BLI",
      "Airport": "BELLINGHAM INTERNATIONAL",
      "City": "BELLINGHAM",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 47,
      "LatitudeSeconds": 33,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 32,
      "LongitudeSeconds": 15,
      "LongitudeDirection": "W",
      "Altitude": 52,
      "LatitudeDecimal": 48.792,
      "LongitudeDecimal": -122.537
    },
    {
      "ICAO": "KBLV",
      "IATA": "BLV",
      "Airport": "SCOTT AFB MIDAMERICA",
      "City": "BELLEVILLE",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 32,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 89,
      "LongitudeMinutes": 50,
      "LongitudeSeconds": 6,
      "LongitudeDirection": "W",
      "Altitude": 140,
      "LatitudeDecimal": 38.545,
      "LongitudeDecimal": -89.835
    },
    {
      "ICAO": "KBNA",
      "IATA": "BNA",
      "Airport": "NASHVILLE INTERNATIONAL",
      "City": "NASHVILLE",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 28,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 86,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 41,
      "LongitudeDirection": "W",
      "Altitude": 183,
      "LatitudeDecimal": 36.124,
      "LongitudeDecimal": -86.678
    },
    {
      "ICAO": "KBOF",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "WASHINGTON",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KBOI",
      "IATA": "BOI",
      "Airport": "BOISE AIR TERMINAL",
      "City": "BOISE",
      "Country": "USA",
      "LatitudeDegrees": 43,
      "LatitudeMinutes": 33,
      "LatitudeSeconds": 51,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 116,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 22,
      "LongitudeDirection": "W",
      "Altitude": 876,
      "LatitudeDecimal": 43.564,
      "LongitudeDecimal": -116.223
    },
    {
      "ICAO": "KBOS",
      "IATA": "BOS",
      "Airport": "GENERAL EDWARD LAWRENCE LOGAN INTERNATIONAL",
      "City": "BOSTON",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 51,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 71,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 18,
      "LongitudeDirection": "W",
      "Altitude": 6,
      "LatitudeDecimal": 42.364,
      "LongitudeDecimal": -71.005
    },
    {
      "ICAO": "KBPT",
      "IATA": "BPT",
      "Airport": "SOUTHEAST TEXAS RGNL",
      "City": "BEAUMONT",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 57,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 1,
      "LongitudeSeconds": 14,
      "LongitudeDirection": "W",
      "Altitude": 5,
      "LatitudeDecimal": 29.951,
      "LongitudeDecimal": -94.021
    },
    {
      "ICAO": "KBRO",
      "IATA": "BRO",
      "Airport": "BROWNSVILLE SOUTH PADRE ISLAND INTERNATIONAL",
      "City": "BROWNSVILLE",
      "Country": "USA",
      "LatitudeDegrees": 25,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 24,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 25,
      "LongitudeSeconds": 33,
      "LongitudeDirection": "W",
      "Altitude": 7,
      "LatitudeDecimal": 25.907,
      "LongitudeDecimal": -97.426
    },
    {
      "ICAO": "KBSM",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "AUSTIN",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KBTR",
      "IATA": "BTR",
      "Airport": "BATON ROUGE METRO RYAN FLD",
      "City": "BATON ROUGE",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 31,
      "LatitudeSeconds": 59,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 91,
      "LongitudeMinutes": 8,
      "LongitudeSeconds": 58,
      "LongitudeDirection": "W",
      "Altitude": 22,
      "LatitudeDecimal": 30.533,
      "LongitudeDecimal": -91.149
    },
    {
      "ICAO": "KBTV",
      "IATA": "BTV",
      "Airport": "BURLINGTON INTERNATIONAL",
      "City": "BURLINGTON",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 28,
      "LatitudeSeconds": 18,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 73,
      "LongitudeMinutes": 9,
      "LongitudeSeconds": 11,
      "LongitudeDirection": "W",
      "Altitude": 103,
      "LatitudeDecimal": 44.472,
      "LongitudeDecimal": -73.153
    },
    {
      "ICAO": "KBUF",
      "IATA": "BUF",
      "Airport": "BUFFALO NIAGARA INTERNATIONAL",
      "City": "BUFFALO",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 25,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 78,
      "LongitudeMinutes": 43,
      "LongitudeSeconds": 55,
      "LongitudeDirection": "W",
      "Altitude": 221,
      "LatitudeDecimal": 42.94,
      "LongitudeDecimal": -78.732
    },
    {
      "ICAO": "KBUR",
      "IATA": "BUR",
      "Airport": "BURBANK GLENDALE PASADENA",
      "City": "BURBANK",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 12,
      "LatitudeSeconds": 2,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 118,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 31,
      "LongitudeDirection": "W",
      "Altitude": 238,
      "LatitudeDecimal": 34.201,
      "LongitudeDecimal": -118.359
    },
    {
      "ICAO": "KBWI",
      "IATA": "BWI",
      "Airport": "BALTIMORE WASHINGTON INTERNATIONAL",
      "City": "BALTIMORE",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 10,
      "LatitudeSeconds": 31,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 6,
      "LongitudeDirection": "W",
      "Altitude": 45,
      "LatitudeDecimal": 39.175,
      "LongitudeDecimal": -76.668
    },
    {
      "ICAO": "KBYH",
      "IATA": "BYH",
      "Airport": "ARKANSAS INTERNATIONAL",
      "City": "BLYTHEVILLE",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 57,
      "LatitudeSeconds": 51,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 89,
      "LongitudeMinutes": 56,
      "LongitudeSeconds": 36,
      "LongitudeDirection": "W",
      "Altitude": 77,
      "LatitudeDecimal": 35.964,
      "LongitudeDecimal": -89.943
    },
    {
      "ICAO": "KBYS",
      "IATA": "BYS",
      "Airport": "BICYCLE LAKE AAF",
      "City": "FORT IRWIN",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 16,
      "LatitudeSeconds": 49,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 116,
      "LongitudeMinutes": 37,
      "LongitudeSeconds": 48,
      "LongitudeDirection": "W",
      "Altitude": 717,
      "LatitudeDecimal": 35.28,
      "LongitudeDecimal": -116.63
    },
    {
      "ICAO": "KCAE",
      "IATA": "CAE",
      "Airport": "COLUMBIA METROPOLITAN",
      "City": "COLOMBIA",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 19,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 7,
      "LongitudeSeconds": 10,
      "LongitudeDirection": "W",
      "Altitude": 72,
      "LatitudeDecimal": 33.939,
      "LongitudeDecimal": -81.119
    },
    {
      "ICAO": "KCAR",
      "IATA": "CAR",
      "Airport": "CARIBOU MUNI",
      "City": "CARIBOU",
      "Country": "USA",
      "LatitudeDegrees": 46,
      "LatitudeMinutes": 52,
      "LatitudeSeconds": 17,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 68,
      "LongitudeMinutes": 1,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 191,
      "LatitudeDecimal": 46.871,
      "LongitudeDecimal": -68.018
    },
    {
      "ICAO": "KCBM",
      "IATA": "CBM",
      "Airport": "COLUMBUS AFB",
      "City": "COLOMBUS",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 38,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 88,
      "LongitudeMinutes": 26,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 67,
      "LatitudeDecimal": 33.644,
      "LongitudeDecimal": -88.444
    },
    {
      "ICAO": "KCDC",
      "IATA": "CDC",
      "Airport": "CEDAR CITY RGNL",
      "City": "CEDAR CITY",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 42,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 113,
      "LongitudeMinutes": 5,
      "LongitudeSeconds": 55,
      "LongitudeDirection": "W",
      "Altitude": 1714,
      "LatitudeDecimal": 37.701,
      "LongitudeDecimal": -113.099
    },
    {
      "ICAO": "KCDS",
      "IATA": "CDS",
      "Airport": "CHILDRESS MUNI",
      "City": "CHILDRESS",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 26,
      "LatitudeSeconds": 1,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 100,
      "LongitudeMinutes": 17,
      "LongitudeSeconds": 17,
      "LongitudeDirection": "W",
      "Altitude": 596,
      "LatitudeDecimal": 34.434,
      "LongitudeDecimal": -100.288
    },
    {
      "ICAO": "KCEF",
      "IATA": "CEF",
      "Airport": "WESTOVER ARB METROPOLITAN",
      "City": "CHICOPEE FALLS",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 11,
      "LatitudeSeconds": 53,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 72,
      "LongitudeMinutes": 32,
      "LongitudeSeconds": 3,
      "LongitudeDirection": "W",
      "Altitude": 75,
      "LatitudeDecimal": 42.198,
      "LongitudeDecimal": -72.534
    },
    {
      "ICAO": "KCEW",
      "IATA": "CEW",
      "Airport": "BOB SIKES",
      "City": "CRESTVIEW",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 46,
      "LatitudeSeconds": 43,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 86,
      "LongitudeMinutes": 31,
      "LongitudeSeconds": 19,
      "LongitudeDirection": "W",
      "Altitude": 65,
      "LatitudeDecimal": 30.779,
      "LongitudeDecimal": -86.522
    },
    {
      "ICAO": "KCFD",
      "IATA": "CFD",
      "Airport": "COULTER FLD",
      "City": "BRYAN",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 42,
      "LatitudeSeconds": 56,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 96,
      "LongitudeMinutes": 19,
      "LongitudeSeconds": 52,
      "LongitudeDirection": "W",
      "Altitude": 112,
      "LatitudeDecimal": 30.716,
      "LongitudeDecimal": -96.331
    },
    {
      "ICAO": "KCGJ",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "MCCONNELL",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KCHA",
      "IATA": "CHA",
      "Airport": "LOVELL FLD",
      "City": "CHATTANOOGA",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 2,
      "LatitudeSeconds": 7,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 85,
      "LongitudeMinutes": 12,
      "LongitudeSeconds": 13,
      "LongitudeDirection": "W",
      "Altitude": 208,
      "LatitudeDecimal": 35.035,
      "LongitudeDecimal": -85.204
    },
    {
      "ICAO": "KCHD",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "CHANDLER",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KCHS",
      "IATA": "CHS",
      "Airport": "CHARLESTON AFB INTERNATIONAL",
      "City": "CHARLESTON",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 53,
      "LatitudeSeconds": 55,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 25,
      "LongitudeDirection": "W",
      "Altitude": 14,
      "LatitudeDecimal": 32.899,
      "LongitudeDecimal": -80.04
    },
    {
      "ICAO": "KCIC",
      "IATA": "CIC",
      "Airport": "CHICO MUNI",
      "City": "CHICO",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 47,
      "LatitudeSeconds": 43,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 121,
      "LongitudeMinutes": 51,
      "LongitudeSeconds": 30,
      "LongitudeDirection": "W",
      "Altitude": 73,
      "LatitudeDecimal": 39.795,
      "LongitudeDecimal": -121.858
    },
    {
      "ICAO": "KCLE",
      "IATA": "CLE",
      "Airport": "CLEVELAND HOPKINS INTERNATIONAL",
      "City": "CLEVELAND",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 24,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 50,
      "LongitudeSeconds": 59,
      "LongitudeDirection": "W",
      "Altitude": 242,
      "LatitudeDecimal": 41.412,
      "LongitudeDecimal": -81.85
    },
    {
      "ICAO": "KCLL",
      "IATA": "CLL",
      "Airport": "EASTERWOOD FLD",
      "City": "COLLEGE STATION",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 35,
      "LatitudeSeconds": 18,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 96,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 49,
      "LongitudeDirection": "W",
      "Altitude": 98,
      "LatitudeDecimal": 30.588,
      "LongitudeDecimal": -96.364
    },
    {
      "ICAO": "KCLT",
      "IATA": "CLT",
      "Airport": "CHARLOTTE DOUGLAS INTERNATIONAL",
      "City": "CHARLOTTE",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 12,
      "LatitudeSeconds": 50,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 56,
      "LongitudeSeconds": 35,
      "LongitudeDirection": "W",
      "Altitude": 229,
      "LatitudeDecimal": 35.214,
      "LongitudeDecimal": -80.943
    },
    {
      "ICAO": "KCMH",
      "IATA": "CMH",
      "Airport": "PORT COLUMBUS INTERNATIONAL",
      "City": "COLUMBUS",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 59,
      "LatitudeSeconds": 52,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 53,
      "LongitudeSeconds": 30,
      "LongitudeDirection": "W",
      "Altitude": 249,
      "LatitudeDecimal": 39.998,
      "LongitudeDecimal": -82.892
    },
    {
      "ICAO": "KCNM",
      "IATA": "CNM",
      "Airport": "CAVERN CITY AIR TERMINAL",
      "City": "CARLSBAD",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 20,
      "LatitudeSeconds": 14,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 104,
      "LongitudeMinutes": 15,
      "LongitudeSeconds": 47,
      "LongitudeDirection": "W",
      "Altitude": 1005,
      "LatitudeDecimal": 32.337,
      "LongitudeDecimal": -104.263
    },
    {
      "ICAO": "KCNW",
      "IATA": "CNW",
      "Airport": "TSTC WACO",
      "City": "WACO",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 16,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 4,
      "LongitudeSeconds": 26,
      "LongitudeDirection": "W",
      "Altitude": 144,
      "LatitudeDecimal": 31.638,
      "LongitudeDecimal": -97.074
    },
    {
      "ICAO": "KCOF",
      "IATA": "COF",
      "Airport": "PATRICK AFB",
      "City": "COCO BEACH",
      "Country": "USA",
      "LatitudeDegrees": 28,
      "LatitudeMinutes": 14,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 36,
      "LongitudeDirection": "W",
      "Altitude": 3,
      "LatitudeDecimal": 28.235,
      "LongitudeDecimal": -80.61
    },
    {
      "ICAO": "KCOS",
      "IATA": "COS",
      "Airport": "CITY OF COLORADO SPRINGS MUNI",
      "City": "COLORADO SPRINGS",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 48,
      "LatitudeSeconds": 20,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 104,
      "LongitudeMinutes": 42,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "W",
      "Altitude": 1885,
      "LatitudeDecimal": 38.806,
      "LongitudeDecimal": -104.7
    },
    {
      "ICAO": "KCOT",
      "IATA": "COT",
      "Airport": "COTULLA LA SALLE CO",
      "City": "COTULLA",
      "Country": "USA",
      "LatitudeDegrees": 28,
      "LatitudeMinutes": 27,
      "LatitudeSeconds": 29,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 99,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 12,
      "LongitudeDirection": "W",
      "Altitude": 146,
      "LatitudeDecimal": 28.458,
      "LongitudeDecimal": -99.22
    },
    {
      "ICAO": "KCOU",
      "IATA": "COU",
      "Airport": "COLUMBIA RGNL",
      "City": "COLUMBIA",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 49,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 10,
      "LongitudeDirection": "W",
      "Altitude": 271,
      "LatitudeDecimal": 38.818,
      "LongitudeDecimal": -92.219
    },
    {
      "ICAO": "KCPR",
      "IATA": "CPR",
      "Airport": "NATRONA CO INTERNATIONAL",
      "City": "CASPER",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 28,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 106,
      "LongitudeMinutes": 27,
      "LongitudeSeconds": 51,
      "LongitudeDirection": "W",
      "Altitude": 1631,
      "LatitudeDecimal": 42.908,
      "LongitudeDecimal": -106.464
    },
    {
      "ICAO": "KCRP",
      "IATA": "CRP",
      "Airport": "CORPUS CHRISTI INTERNATIONAL",
      "City": "CORPUS CHRISTI",
      "Country": "USA",
      "LatitudeDegrees": 27,
      "LatitudeMinutes": 46,
      "LatitudeSeconds": 13,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 30,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 14,
      "LatitudeDecimal": 27.77,
      "LongitudeDecimal": -97.501
    },
    {
      "ICAO": "KCTB",
      "IATA": "CTB",
      "Airport": "CUT BANK MUNI",
      "City": "CUTBANK",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 36,
      "LatitudeSeconds": 30,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 112,
      "LongitudeMinutes": 22,
      "LongitudeSeconds": 34,
      "LongitudeDirection": "W",
      "Altitude": 1175,
      "LatitudeDecimal": 48.608,
      "LongitudeDecimal": -112.376
    },
    {
      "ICAO": "KCUS",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "COLUMBUS",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KCVG",
      "IATA": "CVG",
      "Airport": "CINCINNATI NORTHERN KENTUCKY INTERNATIONAL",
      "City": "CINCINNATI",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 2,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 84,
      "LongitudeMinutes": 39,
      "LongitudeSeconds": 43,
      "LongitudeDirection": "W",
      "Altitude": 274,
      "LatitudeDecimal": 39.046,
      "LongitudeDecimal": -84.662
    },
    {
      "ICAO": "KCVS",
      "IATA": "CVS",
      "Airport": "CANNON AFB",
      "City": "CLOVIS",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 22,
      "LatitudeSeconds": 57,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 103,
      "LongitudeMinutes": 19,
      "LongitudeSeconds": 19,
      "LongitudeDirection": "W",
      "Altitude": 1310,
      "LatitudeDecimal": 34.383,
      "LongitudeDecimal": -103.322
    },
    {
      "ICAO": "KCXL",
      "IATA": "CXL",
      "Airport": "CALEXICO INTERNATIONAL",
      "City": "CALEXICO",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 10,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 115,
      "LongitudeMinutes": 30,
      "LongitudeSeconds": 47,
      "LongitudeDirection": "W",
      "Altitude": 2,
      "LatitudeDecimal": 32.669,
      "LongitudeDecimal": -115.513
    },
    {
      "ICAO": "KCXO",
      "IATA": "CXO",
      "Airport": "MONTGOMERY CO",
      "City": "CONROE",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 52,
      "LongitudeDirection": "W",
      "Altitude": 75,
      "LatitudeDecimal": 30.352,
      "LongitudeDecimal": -95.414
    },
    {
      "ICAO": "KCYS",
      "IATA": "CYS",
      "Airport": "CHEYENNE",
      "City": "CHEYENNE",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 9,
      "LatitudeSeconds": 20,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 104,
      "LongitudeMinutes": 48,
      "LongitudeSeconds": 42,
      "LongitudeDirection": "W",
      "Altitude": 1877,
      "LatitudeDecimal": 41.156,
      "LongitudeDecimal": -104.812
    },
    {
      "ICAO": "KDAL",
      "IATA": "DAL",
      "Airport": "DALLAS LOVE FLD",
      "City": "DALLAS",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 50,
      "LatitudeSeconds": 49,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 96,
      "LongitudeMinutes": 51,
      "LongitudeSeconds": 6,
      "LongitudeDirection": "W",
      "Altitude": 149,
      "LatitudeDecimal": 32.847,
      "LongitudeDecimal": -96.852
    },
    {
      "ICAO": "KDAY",
      "IATA": "DAY",
      "Airport": "JAMES M COX DAYTON INTERNATIONAL",
      "City": "DAYTON",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 8,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 84,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 9,
      "LongitudeDirection": "W",
      "Altitude": 308,
      "LatitudeDecimal": 39.902,
      "LongitudeDecimal": -84.219
    },
    {
      "ICAO": "KDCA",
      "IATA": "DCA",
      "Airport": "RONALD REAGAN WASHINGTON NATIONAL",
      "City": "WASHINGTON",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 51,
      "LatitudeSeconds": 7,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 77,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 15,
      "LongitudeDirection": "W",
      "Altitude": 5,
      "LatitudeDecimal": 38.852,
      "LongitudeDecimal": -77.037
    },
    {
      "ICAO": "KDCG",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SAN DIEGO",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KDDA",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "FORT BELVOIR",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KDEN",
      "IATA": "DEN",
      "Airport": "DENVER INTERNATIONAL",
      "City": "DENVER",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 51,
      "LatitudeSeconds": 30,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 104,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 1,
      "LongitudeDirection": "W",
      "Altitude": 1656,
      "LatitudeDecimal": 39.858,
      "LongitudeDecimal": -104.667
    },
    {
      "ICAO": "KDET",
      "IATA": "DET",
      "Airport": "DETROIT CITY",
      "City": "DETROIT",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 24,
      "LatitudeSeconds": 33,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 83,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 35,
      "LongitudeDirection": "W",
      "Altitude": 191,
      "LatitudeDecimal": 42.409,
      "LongitudeDecimal": -83.01
    },
    {
      "ICAO": "KDFW",
      "IATA": "DFW",
      "Airport": "DALLAS FORT WORTH INTERNATIONAL",
      "City": "DALLAS-FORT WORTH",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 53,
      "LatitudeSeconds": 47,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 15,
      "LongitudeDirection": "W",
      "Altitude": 184,
      "LatitudeDecimal": 32.896,
      "LongitudeDecimal": -97.037
    },
    {
      "ICAO": "KDHN",
      "IATA": "DHN",
      "Airport": "DOTHAN RGNL",
      "City": "DOTHAN",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 19,
      "LatitudeSeconds": 16,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 85,
      "LongitudeMinutes": 26,
      "LongitudeSeconds": 58,
      "LongitudeDirection": "W",
      "Altitude": 123,
      "LatitudeDecimal": 31.321,
      "LongitudeDecimal": -85.449
    },
    {
      "ICAO": "KDHT",
      "IATA": "DHT",
      "Airport": "DALHART MUNI",
      "City": "DALHART",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 1,
      "LatitudeSeconds": 21,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 102,
      "LongitudeMinutes": 32,
      "LongitudeSeconds": 50,
      "LongitudeDirection": "W",
      "Altitude": 1217,
      "LatitudeDecimal": 36.023,
      "LongitudeDecimal": -102.547
    },
    {
      "ICAO": "KDLF",
      "IATA": "DLF",
      "Airport": "LAUGHLIN AFB",
      "City": "DEL RIO",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 34,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 100,
      "LongitudeMinutes": 46,
      "LongitudeSeconds": 40,
      "LongitudeDirection": "W",
      "Altitude": 330,
      "LatitudeDecimal": 29.359,
      "LongitudeDecimal": -100.778
    },
    {
      "ICAO": "KDLH",
      "IATA": "DLH",
      "Airport": "DULUTH INTERNATIONAL",
      "City": "DULUTH",
      "Country": "USA",
      "LatitudeDegrees": 46,
      "LatitudeMinutes": 50,
      "LatitudeSeconds": 31,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 11,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 436,
      "LatitudeDecimal": 46.842,
      "LongitudeDecimal": -92.194
    },
    {
      "ICAO": "KDMA",
      "IATA": "DMA",
      "Airport": "DAVIS MONTHAN AFB",
      "City": "TUCSON",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 9,
      "LatitudeSeconds": 59,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 110,
      "LongitudeMinutes": 52,
      "LongitudeSeconds": 59,
      "LongitudeDirection": "W",
      "Altitude": 825,
      "LatitudeDecimal": 32.166,
      "LongitudeDecimal": -110.883
    },
    {
      "ICAO": "KDMF",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "DANNELLY",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KDOV",
      "IATA": "DOV",
      "Airport": "DOVER AFB",
      "City": "DOVER",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 48,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 27,
      "LongitudeSeconds": 59,
      "LongitudeDirection": "W",
      "Altitude": 10,
      "LatitudeDecimal": 39.13,
      "LongitudeDecimal": -75.466
    },
    {
      "ICAO": "KDPA",
      "IATA": "DPA",
      "Airport": "DU PAGE",
      "City": "WEST CHICAGO",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 28,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 88,
      "LongitudeMinutes": 14,
      "LongitudeSeconds": 55,
      "LongitudeDirection": "W",
      "Altitude": 232,
      "LatitudeDecimal": 41.908,
      "LongitudeDecimal": -88.249
    },
    {
      "ICAO": "KDRI",
      "IATA": "DRI",
      "Airport": "BEAUREGARD PARISH",
      "City": "DERIDDER",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 49,
      "LatitudeSeconds": 54,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 20,
      "LongitudeSeconds": 23,
      "LongitudeDirection": "W",
      "Altitude": 63,
      "LatitudeDecimal": 30.832,
      "LongitudeDecimal": -93.34
    },
    {
      "ICAO": "KDRO",
      "IATA": "DRO",
      "Airport": "DURANGO LA PLATA CO",
      "City": "DURANGO",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 9,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 107,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 13,
      "LongitudeDirection": "W",
      "Altitude": 2038,
      "LatitudeDecimal": 37.151,
      "LongitudeDecimal": -107.754
    },
    {
      "ICAO": "KDRT",
      "IATA": "DRT",
      "Airport": "DEL RIO INTERNATIONAL",
      "City": "DEL RIO",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 22,
      "LatitudeSeconds": 22,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 100,
      "LongitudeMinutes": 55,
      "LongitudeSeconds": 33,
      "LongitudeDirection": "W",
      "Altitude": 305,
      "LatitudeDecimal": 29.373,
      "LongitudeDecimal": -100.926
    },
    {
      "ICAO": "KDSM",
      "IATA": "DSM",
      "Airport": "DES MOINES INTERNATIONAL",
      "City": "DES MOINES",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 32,
      "LatitudeSeconds": 2,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 39,
      "LongitudeSeconds": 45,
      "LongitudeDirection": "W",
      "Altitude": 292,
      "LatitudeDecimal": 41.534,
      "LongitudeDecimal": -93.663
    },
    {
      "ICAO": "KDTW",
      "IATA": "DTW",
      "Airport": "DETROIT METRO WAYNE CO",
      "City": "DETROIT",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 12,
      "LatitudeSeconds": 44,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 83,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 12,
      "LongitudeDirection": "W",
      "Altitude": 197,
      "LatitudeDecimal": 42.212,
      "LongitudeDecimal": -83.353
    },
    {
      "ICAO": "KDUG",
      "IATA": "DUG",
      "Airport": "BISBEE DOUGLAS INTERNATIONAL",
      "City": "DOUGLAS",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 28,
      "LatitudeSeconds": 8,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 109,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 13,
      "LongitudeDirection": "W",
      "Altitude": 1267,
      "LatitudeDecimal": 31.469,
      "LongitudeDecimal": -109.604
    },
    {
      "ICAO": "KDYS",
      "IATA": "DYS",
      "Airport": "DYESS AFB",
      "City": "ABILENE",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 25,
      "LatitudeSeconds": 14,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 99,
      "LongitudeMinutes": 51,
      "LongitudeSeconds": 16,
      "LongitudeDirection": "W",
      "Altitude": 546,
      "LatitudeDecimal": 32.421,
      "LongitudeDecimal": -99.854
    },
    {
      "ICAO": "KECG",
      "IATA": "ECG",
      "Airport": "ELIZABETH CITY CGAS RGNL",
      "City": "ELIZABETH CITY",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 15,
      "LatitudeSeconds": 38,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 10,
      "LongitudeSeconds": 28,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 36.261,
      "LongitudeDecimal": -76.174
    },
    {
      "ICAO": "KEDW",
      "IATA": "EDW",
      "Airport": "EDWARDS AFB",
      "City": "EDWARDS AFB",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 19,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 53,
      "LongitudeSeconds": 1,
      "LongitudeDirection": "W",
      "Altitude": 702,
      "LatitudeDecimal": 34.905,
      "LongitudeDecimal": -117.884
    },
    {
      "ICAO": "KEFD",
      "IATA": "EFD",
      "Airport": "ELLINGTON FLD",
      "City": "HOUSTON",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 36,
      "LatitudeSeconds": 26,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 9,
      "LongitudeSeconds": 31,
      "LongitudeDirection": "W",
      "Altitude": 10,
      "LatitudeDecimal": 29.607,
      "LongitudeDecimal": -95.159
    },
    {
      "ICAO": "KEGP",
      "IATA": "EGP",
      "Airport": "EAGLE PASS MUNI",
      "City": "EAGLE PASS",
      "Country": "USA",
      "LatitudeDegrees": 28,
      "LatitudeMinutes": 42,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 100,
      "LongitudeMinutes": 28,
      "LongitudeSeconds": 46,
      "LongitudeDirection": "W",
      "Altitude": 246,
      "LatitudeDecimal": 28.7,
      "LongitudeDecimal": -100.479
    },
    {
      "ICAO": "KEKN",
      "IATA": "EKN",
      "Airport": "ELKINS RANDOLPH CO JENNINGS RANDOLPH",
      "City": "ELKINS",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 53,
      "LatitudeSeconds": 22,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 79,
      "LongitudeMinutes": 51,
      "LongitudeSeconds": 25,
      "LongitudeDirection": "W",
      "Altitude": 606,
      "LatitudeDecimal": 38.889,
      "LongitudeDecimal": -79.857
    },
    {
      "ICAO": "KELD",
      "IATA": "ELD",
      "Airport": "SOUTH ARKANSAS RGNL AT GOODWIN FLD",
      "City": "EL DORADO",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 13,
      "LatitudeSeconds": 15,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 48,
      "LongitudeSeconds": 47,
      "LongitudeDirection": "W",
      "Altitude": 85,
      "LatitudeDecimal": 33.221,
      "LongitudeDecimal": -92.813
    },
    {
      "ICAO": "KELP",
      "IATA": "ELP",
      "Airport": "EL PASO INTERNATIONAL",
      "City": "EL PASO",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 48,
      "LatitudeSeconds": 24,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 106,
      "LongitudeMinutes": 22,
      "LongitudeSeconds": 40,
      "LongitudeDirection": "W",
      "Altitude": 1207,
      "LatitudeDecimal": 31.807,
      "LongitudeDecimal": -106.378
    },
    {
      "ICAO": "KEND",
      "IATA": "END",
      "Airport": "VANCE AFB",
      "City": "ENID",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 20,
      "LatitudeSeconds": 23,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 54,
      "LongitudeSeconds": 58,
      "LongitudeDirection": "W",
      "Altitude": 399,
      "LatitudeDecimal": 36.34,
      "LongitudeDecimal": -97.916
    },
    {
      "ICAO": "KENV",
      "IATA": "ENV",
      "Airport": "WENDOVER",
      "City": "WENDOVER",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 7,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 114,
      "LongitudeMinutes": 1,
      "LongitudeSeconds": 51,
      "LongitudeDirection": "W",
      "Altitude": 1291,
      "LatitudeDecimal": 40.719,
      "LongitudeDecimal": -114.031
    },
    {
      "ICAO": "KESF",
      "IATA": "ESF",
      "Airport": "ESLER RGNL",
      "City": "ALEXANDRIA",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 23,
      "LatitudeSeconds": 41,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 17,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 35,
      "LatitudeDecimal": 31.395,
      "LongitudeDecimal": -92.296
    },
    {
      "ICAO": "KEWN",
      "IATA": "EWN",
      "Airport": "CRAVEN CO RGNL",
      "City": "NEW BERN",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 22,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 77,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 34,
      "LongitudeDirection": "W",
      "Altitude": 6,
      "LatitudeDecimal": 35.073,
      "LongitudeDecimal": -77.043
    },
    {
      "ICAO": "KEWR",
      "IATA": "EWR",
      "Airport": "NEWARK LIBERTY INTERNATIONAL",
      "City": "NEWARK",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 41,
      "LatitudeSeconds": 32,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 74,
      "LongitudeMinutes": 10,
      "LongitudeSeconds": 7,
      "LongitudeDirection": "W",
      "Altitude": 6,
      "LatitudeDecimal": 40.692,
      "LongitudeDecimal": -74.169
    },
    {
      "ICAO": "KEYW",
      "IATA": "EYW",
      "Airport": "KEY WEST INTERNATIONAL",
      "City": "KEY WEST",
      "Country": "USA",
      "LatitudeDegrees": 24,
      "LatitudeMinutes": 33,
      "LatitudeSeconds": 22,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 34,
      "LongitudeDirection": "W",
      "Altitude": 1,
      "LatitudeDecimal": 24.556,
      "LongitudeDecimal": -81.759
    },
    {
      "ICAO": "KFAF",
      "IATA": "FAF",
      "Airport": "FELKER AAF",
      "City": "FORT EUSTIS",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 57,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 31,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 37.133,
      "LongitudeDecimal": -76.609
    },
    {
      "ICAO": "KFAT",
      "IATA": "FAT",
      "Airport": "FRESNO YOSEMITE INTERNATIONAL",
      "City": "FRESNO",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 46,
      "LatitudeSeconds": 34,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 119,
      "LongitudeMinutes": 43,
      "LongitudeSeconds": 5,
      "LongitudeDirection": "W",
      "Altitude": 103,
      "LatitudeDecimal": 36.776,
      "LongitudeDecimal": -119.718
    },
    {
      "ICAO": "KFCS",
      "IATA": "FCS",
      "Airport": "BUTTS AAF",
      "City": "FORT CARSON",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 104,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 23,
      "LongitudeDirection": "W",
      "Altitude": 1780,
      "LatitudeDecimal": 38.678,
      "LongitudeDecimal": -104.756
    },
    {
      "ICAO": "KFFO",
      "IATA": "FFO",
      "Airport": "WRIGHT PATTERSON AFB",
      "City": "DAYTON",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 49,
      "LatitudeSeconds": 34,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 84,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 54,
      "LongitudeDirection": "W",
      "Altitude": 251,
      "LatitudeDecimal": 39.826,
      "LongitudeDecimal": -84.048
    },
    {
      "ICAO": "KFHU",
      "IATA": "FHU",
      "Airport": "SIERRA VISTA MUNI LIBBY AAF",
      "City": "FORT HUACHUCA",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 35,
      "LatitudeSeconds": 18,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 110,
      "LongitudeMinutes": 20,
      "LongitudeSeconds": 39,
      "LongitudeDirection": "W",
      "Altitude": 1439,
      "LatitudeDecimal": 31.588,
      "LongitudeDecimal": -110.344
    },
    {
      "ICAO": "KFLL",
      "IATA": "FLL",
      "Airport": "FORT LAUDERDALE HOLLYWOOD INTERNATIONAL",
      "City": "FORT LAUDERDALE",
      "Country": "USA",
      "LatitudeDegrees": 26,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 21,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 9,
      "LongitudeSeconds": 9,
      "LongitudeDirection": "W",
      "Altitude": 3,
      "LatitudeDecimal": 26.072,
      "LongitudeDecimal": -80.153
    },
    {
      "ICAO": "KFLO",
      "IATA": "FLO",
      "Airport": "FLORENCE RGNL",
      "City": "FLORENCE",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 11,
      "LatitudeSeconds": 7,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 79,
      "LongitudeMinutes": 43,
      "LongitudeSeconds": 26,
      "LongitudeDirection": "W",
      "Altitude": 45,
      "LatitudeDecimal": 34.185,
      "LongitudeDecimal": -79.724
    },
    {
      "ICAO": "KFLV",
      "IATA": "FLV",
      "Airport": "SHERMAN AAF",
      "City": "FORT LEAVENWORTH",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 22,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 54,
      "LongitudeSeconds": 52,
      "LongitudeDirection": "W",
      "Altitude": 236,
      "LatitudeDecimal": 39.368,
      "LongitudeDecimal": -94.914
    },
    {
      "ICAO": "KFME",
      "IATA": "N/A",
      "Airport": "TIPTON",
      "City": "FORT MEADE",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 5,
      "LatitudeSeconds": 7,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 33,
      "LongitudeDirection": "W",
      "Altitude": 46,
      "LatitudeDecimal": 39.085,
      "LongitudeDecimal": -76.759
    },
    {
      "ICAO": "KFMH",
      "IATA": "FMH",
      "Airport": "OTIS ANGB",
      "City": "FALMOUTH",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 39,
      "LatitudeSeconds": 30,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 70,
      "LongitudeMinutes": 31,
      "LongitudeSeconds": 17,
      "LongitudeDirection": "W",
      "Altitude": 40,
      "LatitudeDecimal": 41.658,
      "LongitudeDecimal": -70.521
    },
    {
      "ICAO": "KFMN",
      "IATA": "FMN",
      "Airport": "FOUR CORNERS RGNL",
      "City": "FARMINGTON",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 44,
      "LatitudeSeconds": 28,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 108,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 47,
      "LongitudeDirection": "W",
      "Altitude": 1679,
      "LatitudeDecimal": 36.741,
      "LongitudeDecimal": -108.23
    },
    {
      "ICAO": "KFMY",
      "IATA": "FMY",
      "Airport": "PAGE FLD",
      "City": "FORT MYERS",
      "Country": "USA",
      "LatitudeDegrees": 26,
      "LatitudeMinutes": 35,
      "LatitudeSeconds": 11,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 51,
      "LongitudeSeconds": 47,
      "LongitudeDirection": "W",
      "Altitude": 6,
      "LatitudeDecimal": 26.586,
      "LongitudeDecimal": -81.863
    },
    {
      "ICAO": "KFOD",
      "IATA": "FOD",
      "Airport": "FORT DODGE RGNL",
      "City": "FORT DODGE",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 33,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 11,
      "LongitudeSeconds": 33,
      "LongitudeDirection": "W",
      "Altitude": 353,
      "LatitudeDecimal": 42.551,
      "LongitudeDecimal": -94.193
    },
    {
      "ICAO": "KFOE",
      "IATA": "FOE",
      "Airport": "FORBES FLD",
      "City": "TOPEKA",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 57,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 39,
      "LongitudeSeconds": 49,
      "LongitudeDirection": "W",
      "Altitude": 329,
      "LatitudeDecimal": 38.951,
      "LongitudeDecimal": -95.664
    },
    {
      "ICAO": "KFOK",
      "IATA": "FOK",
      "Airport": "THE FRANCIS S GABRESKI",
      "City": "WEST HAMPTON BEACH",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 50,
      "LatitudeSeconds": 37,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 72,
      "LongitudeMinutes": 37,
      "LongitudeSeconds": 54,
      "LongitudeDirection": "W",
      "Altitude": 21,
      "LatitudeDecimal": 40.844,
      "LongitudeDecimal": -72.632
    },
    {
      "ICAO": "KFRI",
      "IATA": "FRI",
      "Airport": "MARSHALL AAF",
      "City": "FORT RILEY",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 3,
      "LatitudeSeconds": 18,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 96,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 52,
      "LongitudeDirection": "W",
      "Altitude": 325,
      "LatitudeDecimal": 39.055,
      "LongitudeDecimal": -96.764
    },
    {
      "ICAO": "KFSI",
      "IATA": "FSI",
      "Airport": "HENRY POST AAF",
      "City": "FORT SILL",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 59,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 98,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 7,
      "LongitudeDirection": "W",
      "Altitude": 363,
      "LatitudeDecimal": 34.65,
      "LongitudeDecimal": -98.402
    },
    {
      "ICAO": "KFSM",
      "IATA": "FSM",
      "Airport": "FORT SMITH RGNL",
      "City": "FORT SMITH",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 20,
      "LatitudeSeconds": 11,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 22,
      "LongitudeSeconds": 2,
      "LongitudeDirection": "W",
      "Altitude": 143,
      "LatitudeDecimal": 35.336,
      "LongitudeDecimal": -94.367
    },
    {
      "ICAO": "KFTK",
      "IATA": "FTK",
      "Airport": "GODMAN AAF",
      "City": "FORT KNOX",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 25,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 85,
      "LongitudeMinutes": 58,
      "LongitudeSeconds": 19,
      "LongitudeDirection": "W",
      "Altitude": 231,
      "LatitudeDecimal": 37.907,
      "LongitudeDecimal": -85.972
    },
    {
      "ICAO": "KFTW",
      "IATA": "FTW",
      "Airport": "FORT WORTH MEACHAM INTERNATIONAL",
      "City": "FORT WORTH",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 49,
      "LatitudeSeconds": 11,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 217,
      "LatitudeDecimal": 32.82,
      "LongitudeDecimal": -97.362
    },
    {
      "ICAO": "KFWH",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "FORT WORTH",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KFXE",
      "IATA": "FXE",
      "Airport": "FORT LAUDERDALE EXECUTIVE",
      "City": "FORT LAUDERDALE",
      "Country": "USA",
      "LatitudeDegrees": 26,
      "LatitudeMinutes": 11,
      "LatitudeSeconds": 50,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 10,
      "LongitudeSeconds": 14,
      "LongitudeDirection": "W",
      "Altitude": 5,
      "LatitudeDecimal": 26.197,
      "LongitudeDecimal": -80.171
    },
    {
      "ICAO": "KFYV",
      "IATA": "FYV",
      "Airport": "DRAKE FLD",
      "City": "FAYETTEVILLE",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 18,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 10,
      "LongitudeSeconds": 12,
      "LongitudeDirection": "W",
      "Altitude": 382,
      "LatitudeDecimal": 36.005,
      "LongitudeDecimal": -94.17
    },
    {
      "ICAO": "KGAG",
      "IATA": "GAG",
      "Airport": "GAGE",
      "City": "GAGE",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 17,
      "LatitudeSeconds": 43,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 99,
      "LongitudeMinutes": 46,
      "LongitudeSeconds": 35,
      "LongitudeDirection": "W",
      "Altitude": 678,
      "LatitudeDecimal": 36.295,
      "LongitudeDecimal": -99.776
    },
    {
      "ICAO": "KGCK",
      "IATA": "GCK",
      "Airport": "GARDEN CITY RGNL",
      "City": "GARDEN CITY",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 55,
      "LatitudeSeconds": 39,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 100,
      "LongitudeMinutes": 43,
      "LongitudeSeconds": 27,
      "LongitudeDirection": "W",
      "Altitude": 881,
      "LatitudeDecimal": 37.927,
      "LongitudeDecimal": -100.724
    },
    {
      "ICAO": "KGEG",
      "IATA": "GEG",
      "Airport": "SPOKANE INTERNATIONAL",
      "City": "SPOKANE",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 37,
      "LatitudeSeconds": 11,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 32,
      "LongitudeSeconds": 1,
      "LongitudeDirection": "W",
      "Altitude": 723,
      "LatitudeDecimal": 47.62,
      "LongitudeDecimal": -117.534
    },
    {
      "ICAO": "KGFA",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "GREAT FALLS",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KGFK",
      "IATA": "GFK",
      "Airport": "GRAND FORKS INTERNATIONAL",
      "City": "GRAND FORKS",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 57,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 10,
      "LongitudeSeconds": 34,
      "LongitudeDirection": "W",
      "Altitude": 258,
      "LatitudeDecimal": 47.949,
      "LongitudeDecimal": -97.176
    },
    {
      "ICAO": "KGGG",
      "IATA": "GGG",
      "Airport": "EAST TEXAS RGNL",
      "City": "LONGVIEW",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 23,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 42,
      "LongitudeSeconds": 41,
      "LongitudeDirection": "W",
      "Altitude": 112,
      "LatitudeDecimal": 32.385,
      "LongitudeDecimal": -94.711
    },
    {
      "ICAO": "KGLS",
      "IATA": "GLS",
      "Airport": "SCHOLES INTERNATIONAL AT GALVESTON",
      "City": "GALVESTON",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 15,
      "LatitudeSeconds": 55,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 51,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 2,
      "LatitudeDecimal": 29.265,
      "LongitudeDecimal": -94.86
    },
    {
      "ICAO": "KGNT",
      "IATA": "GNT",
      "Airport": "GRANTS MILAN MUNI",
      "City": "GRANTS",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 9,
      "LatitudeSeconds": 55,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 107,
      "LongitudeMinutes": 54,
      "LongitudeSeconds": 2,
      "LongitudeDirection": "W",
      "Altitude": 1988,
      "LatitudeDecimal": 35.165,
      "LongitudeDecimal": -107.901
    },
    {
      "ICAO": "KGNV",
      "IATA": "GNV",
      "Airport": "GAINESVILLE RGNL",
      "City": "GAINESVILLE",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 41,
      "LatitudeSeconds": 24,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 16,
      "LongitudeSeconds": 18,
      "LongitudeDirection": "W",
      "Altitude": 47,
      "LatitudeDecimal": 29.69,
      "LongitudeDecimal": -82.272
    },
    {
      "ICAO": "KGRB",
      "IATA": "GRB",
      "Airport": "AUSTIN STRAUBEL INTERNATIONAL",
      "City": "GREEN BAY",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 29,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 88,
      "LongitudeMinutes": 7,
      "LongitudeSeconds": 46,
      "LongitudeDirection": "W",
      "Altitude": 212,
      "LatitudeDecimal": 44.485,
      "LongitudeDecimal": -88.129
    },
    {
      "ICAO": "KGRF",
      "IATA": "GRF",
      "Airport": "GRAY AAF",
      "City": "FORT LEWIS",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 45,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 34,
      "LongitudeSeconds": 50,
      "LongitudeDirection": "W",
      "Altitude": 93,
      "LatitudeDecimal": 47.079,
      "LongitudeDecimal": -122.581
    },
    {
      "ICAO": "KGRK",
      "IATA": "GRK",
      "Airport": "ROBERT GRAY AAF",
      "City": "KILLEEN",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 2,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 49,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 311,
      "LatitudeDecimal": 31.067,
      "LongitudeDecimal": -97.829
    },
    {
      "ICAO": "KGRR",
      "IATA": "GRR",
      "Airport": "GERALD R FORD INTERNATIONAL",
      "City": "GRAND RAPIDS",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 52,
      "LatitudeSeconds": 51,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 85,
      "LongitudeMinutes": 31,
      "LongitudeSeconds": 22,
      "LongitudeDirection": "W",
      "Altitude": 242,
      "LatitudeDecimal": 42.881,
      "LongitudeDecimal": -85.523
    },
    {
      "ICAO": "KGSB",
      "IATA": "GSB",
      "Airport": "SEYMOUR JOHNSON AFB",
      "City": "GOLDSBORO",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 20,
      "LatitudeSeconds": 21,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 77,
      "LongitudeMinutes": 57,
      "LongitudeSeconds": 38,
      "LongitudeDirection": "W",
      "Altitude": 34,
      "LatitudeDecimal": 35.339,
      "LongitudeDecimal": -77.961
    },
    {
      "ICAO": "KGTB",
      "IATA": "GTB",
      "Airport": "WHEELER SACK AAF",
      "City": "FORT DRUM",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 3,
      "LatitudeSeconds": 20,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 43,
      "LongitudeSeconds": 10,
      "LongitudeDirection": "W",
      "Altitude": 211,
      "LatitudeDecimal": 44.056,
      "LongitudeDecimal": -75.719
    },
    {
      "ICAO": "KGTF",
      "IATA": "GTF",
      "Airport": "GREAT FALLS INTERNATIONAL",
      "City": "GREAT FALLS",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 28,
      "LatitudeSeconds": 55,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 111,
      "LongitudeMinutes": 22,
      "LongitudeSeconds": 14,
      "LongitudeDirection": "W",
      "Altitude": 1121,
      "LatitudeDecimal": 47.482,
      "LongitudeDecimal": -111.371
    },
    {
      "ICAO": "KGUS",
      "IATA": "GUS",
      "Airport": "GRISSOM ARB",
      "City": "PERU",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 53,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 86,
      "LongitudeMinutes": 9,
      "LongitudeSeconds": 7,
      "LongitudeDirection": "W",
      "Altitude": 248,
      "LatitudeDecimal": 40.648,
      "LongitudeDecimal": -86.152
    },
    {
      "ICAO": "KGVT",
      "IATA": "GVT",
      "Airport": "MAJORS",
      "City": "GREENVILE",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 4,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 96,
      "LongitudeMinutes": 3,
      "LongitudeSeconds": 55,
      "LongitudeDirection": "W",
      "Altitude": 164,
      "LatitudeDecimal": 33.068,
      "LongitudeDecimal": -96.065
    },
    {
      "ICAO": "KGVW",
      "IATA": "GVW",
      "Airport": "RICHARDS GEBAUR MEMORIAL",
      "City": "GRANDVIEW",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 50,
      "LatitudeSeconds": 36,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 33,
      "LongitudeSeconds": 38,
      "LongitudeDirection": "W",
      "Altitude": 333,
      "LatitudeDecimal": 38.843,
      "LongitudeDecimal": -94.561
    },
    {
      "ICAO": "KGWO",
      "IATA": "GWO",
      "Airport": "GREENWOOD LEFLORE",
      "City": "GREENWOOD",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 29,
      "LatitudeSeconds": 39,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 90,
      "LongitudeMinutes": 5,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 50,
      "LatitudeDecimal": 33.494,
      "LongitudeDecimal": -90.084
    },
    {
      "ICAO": "KHAR",
      "IATA": "HAR",
      "Airport": "N/A",
      "City": "HARRISBURG",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KHBR",
      "IATA": "HBR",
      "Airport": "HOBART MUNI",
      "City": "HOBART",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 59,
      "LatitudeSeconds": 28,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 99,
      "LongitudeMinutes": 3,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 477,
      "LatitudeDecimal": 34.991,
      "LongitudeDecimal": -99.051
    },
    {
      "ICAO": "KHFD",
      "IATA": "HFD",
      "Airport": "HARTFORD BRAINARD",
      "City": "HARTFORD",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 44,
      "LatitudeSeconds": 10,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 72,
      "LongitudeMinutes": 39,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "W",
      "Altitude": 6,
      "LatitudeDecimal": 41.736,
      "LongitudeDecimal": -72.65
    },
    {
      "ICAO": "KHHR",
      "IATA": "HHR",
      "Airport": "JACK NORTHROP FLD HAWTHORNE MUNI",
      "City": "HAWTHORNE",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 55,
      "LatitudeSeconds": 22,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 118,
      "LongitudeMinutes": 20,
      "LongitudeSeconds": 6,
      "LongitudeDirection": "W",
      "Altitude": 21,
      "LatitudeDecimal": 33.923,
      "LongitudeDecimal": -118.335
    },
    {
      "ICAO": "KHIB",
      "IATA": "HIB",
      "Airport": "CHISHOLM HIBBING",
      "City": "HIBBING",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 23,
      "LatitudeSeconds": 11,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 50,
      "LongitudeSeconds": 20,
      "LongitudeDirection": "W",
      "Altitude": 413,
      "LatitudeDecimal": 47.386,
      "LongitudeDecimal": -92.839
    },
    {
      "ICAO": "KHIF",
      "IATA": "HIF",
      "Airport": "HILL AFB",
      "City": "OGDEN",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 26,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 111,
      "LongitudeMinutes": 58,
      "LongitudeSeconds": 22,
      "LongitudeDirection": "W",
      "Altitude": 1460,
      "LatitudeDecimal": 41.124,
      "LongitudeDecimal": -111.973
    },
    {
      "ICAO": "KHKY",
      "IATA": "HKY",
      "Airport": "HICKORY RGNL",
      "City": "HICKORY",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 44,
      "LatitudeSeconds": 28,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 23,
      "LongitudeSeconds": 22,
      "LongitudeDirection": "W",
      "Altitude": 363,
      "LatitudeDecimal": 35.741,
      "LongitudeDecimal": -81.389
    },
    {
      "ICAO": "KHLN",
      "IATA": "HLN",
      "Airport": "HELENA RGNL",
      "City": "HELENA",
      "Country": "USA",
      "LatitudeDegrees": 46,
      "LatitudeMinutes": 36,
      "LatitudeSeconds": 24,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 111,
      "LongitudeMinutes": 58,
      "LongitudeSeconds": 57,
      "LongitudeDirection": "W",
      "Altitude": 1182,
      "LatitudeDecimal": 46.607,
      "LongitudeDecimal": -111.983
    },
    {
      "ICAO": "KHLR",
      "IATA": "HLR",
      "Airport": "HOOD AAF",
      "City": "FORT HOOD",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 8,
      "LatitudeSeconds": 19,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 42,
      "LongitudeSeconds": 52,
      "LongitudeDirection": "W",
      "Altitude": 282,
      "LatitudeDecimal": 31.139,
      "LongitudeDecimal": -97.714
    },
    {
      "ICAO": "KHMN",
      "IATA": "HMN",
      "Airport": "HOLLOMAN AFB",
      "City": "ALAMOGORDO",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 51,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 106,
      "LongitudeMinutes": 6,
      "LongitudeSeconds": 23,
      "LongitudeDirection": "W",
      "Altitude": 1248,
      "LatitudeDecimal": 32.852,
      "LongitudeDecimal": -106.106
    },
    {
      "ICAO": "KHOB",
      "IATA": "HOB",
      "Airport": "LEA CO RGNL",
      "City": "HOBBS",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 41,
      "LatitudeSeconds": 15,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 103,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 1,
      "LongitudeDirection": "W",
      "Altitude": 1116,
      "LatitudeDecimal": 32.688,
      "LongitudeDecimal": -103.217
    },
    {
      "ICAO": "KHON",
      "IATA": "HON",
      "Airport": "HURON RGNL",
      "City": "HURON",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 23,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 98,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 42,
      "LongitudeDirection": "W",
      "Altitude": 393,
      "LatitudeDecimal": 44.385,
      "LongitudeDecimal": -98.228
    },
    {
      "ICAO": "KHOP",
      "IATA": "HOP",
      "Airport": "CAMPBELL AAF",
      "City": "HOPKINSVILLE",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 29,
      "LongitudeSeconds": 46,
      "LongitudeDirection": "W",
      "Altitude": 175,
      "LatitudeDecimal": 36.668,
      "LongitudeDecimal": -87.496
    },
    {
      "ICAO": "KHOU",
      "IATA": "HOU",
      "Airport": "WILLIAM P HOBBY",
      "City": "HOUSTON",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 43,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 16,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 15,
      "LatitudeDecimal": 29.645,
      "LongitudeDecimal": -95.279
    },
    {
      "ICAO": "KHPN",
      "IATA": "HPN",
      "Airport": "WESTCHESTER CO",
      "City": "WHITE PLAINS",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 1,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 73,
      "LongitudeMinutes": 42,
      "LongitudeSeconds": 27,
      "LongitudeDirection": "W",
      "Altitude": 134,
      "LatitudeDecimal": 41.067,
      "LongitudeDecimal": -73.707
    },
    {
      "ICAO": "KHRL",
      "IATA": "HRL",
      "Airport": "VALLEY INTERNATIONAL",
      "City": "HARLINGEN",
      "Country": "USA",
      "LatitudeDegrees": 26,
      "LatitudeMinutes": 13,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 39,
      "LongitudeSeconds": 15,
      "LongitudeDirection": "W",
      "Altitude": 11,
      "LatitudeDecimal": 26.228,
      "LongitudeDecimal": -97.654
    },
    {
      "ICAO": "KHRO",
      "IATA": "HRO",
      "Airport": "BOONE CO",
      "City": "HARRISON",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 15,
      "LatitudeSeconds": 41,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 9,
      "LongitudeSeconds": 17,
      "LongitudeDirection": "W",
      "Altitude": 417,
      "LatitudeDecimal": 36.261,
      "LongitudeDecimal": -93.155
    },
    {
      "ICAO": "KHRT",
      "IATA": "HRT",
      "Airport": "HURLBURT FLD",
      "City": "MARY ESTHER",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 25,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 86,
      "LongitudeMinutes": 41,
      "LongitudeSeconds": 21,
      "LongitudeDirection": "W",
      "Altitude": 12,
      "LatitudeDecimal": 30.428,
      "LongitudeDecimal": -86.689
    },
    {
      "ICAO": "KHST",
      "IATA": "HST",
      "Airport": "HOMESTEAD ARB",
      "City": "HOMESTEAD",
      "Country": "USA",
      "LatitudeDegrees": 25,
      "LatitudeMinutes": 29,
      "LatitudeSeconds": 18,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 23,
      "LongitudeSeconds": 1,
      "LongitudeDirection": "W",
      "Altitude": 3,
      "LatitudeDecimal": 25.488,
      "LongitudeDecimal": -80.384
    },
    {
      "ICAO": "KHTB",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "CHARLESTON",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KHTL",
      "IATA": "HTL",
      "Airport": "ROSCOMMON CO",
      "City": "HOUGHTON LAKE",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 35,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 84,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 16,
      "LongitudeDirection": "W",
      "Altitude": 351,
      "LatitudeDecimal": 44.36,
      "LongitudeDecimal": -84.671
    },
    {
      "ICAO": "KHUA",
      "IATA": "HUA",
      "Airport": "REDSTONE AAF",
      "City": "REDSTONE",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 43,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 86,
      "LongitudeMinutes": 41,
      "LongitudeSeconds": 5,
      "LongitudeDirection": "W",
      "Altitude": 209,
      "LatitudeDecimal": 34.679,
      "LongitudeDecimal": -86.685
    },
    {
      "ICAO": "KHUF",
      "IATA": "HUF",
      "Airport": "TERRE HAUTE INTERNATIONAL HULMAN FLD",
      "City": "TERRE HAUTE",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 27,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 18,
      "LongitudeSeconds": 27,
      "LongitudeDirection": "W",
      "Altitude": 180,
      "LatitudeDecimal": 39.451,
      "LongitudeDecimal": -87.307
    },
    {
      "ICAO": "KHUL",
      "IATA": "HUL",
      "Airport": "HOULTON INTERNATIONAL",
      "City": "HOULTON",
      "Country": "USA",
      "LatitudeDegrees": 46,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 23,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 67,
      "LongitudeMinutes": 47,
      "LongitudeSeconds": 31,
      "LongitudeDirection": "W",
      "Altitude": 150,
      "LatitudeDecimal": 46.123,
      "LongitudeDecimal": -67.792
    },
    {
      "ICAO": "KHVR",
      "IATA": "HVR",
      "Airport": "HAVRE CITY CO",
      "City": "HAVRE",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 32,
      "LatitudeSeconds": 34,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 109,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 790,
      "LatitudeDecimal": 48.543,
      "LongitudeDecimal": -109.762
    },
    {
      "ICAO": "KHWO",
      "IATA": "HWO",
      "Airport": "NORTH PERRY",
      "City": "HOLLYWOOD",
      "Country": "USA",
      "LatitudeDegrees": 26,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 14,
      "LongitudeSeconds": 25,
      "LongitudeDirection": "W",
      "Altitude": 3,
      "LatitudeDecimal": 26.001,
      "LongitudeDecimal": -80.24
    },
    {
      "ICAO": "KIAB",
      "IATA": "IAB",
      "Airport": "MC CONNELL AFB",
      "City": "WICHITA",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 37,
      "LatitudeSeconds": 22,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 16,
      "LongitudeSeconds": 2,
      "LongitudeDirection": "W",
      "Altitude": 418,
      "LatitudeDecimal": 37.623,
      "LongitudeDecimal": -97.267
    },
    {
      "ICAO": "KIAD",
      "IATA": "IAD",
      "Airport": "WASHINGTON DULLES INTERNATIONAL",
      "City": "WASHINGTON",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 77,
      "LongitudeMinutes": 27,
      "LongitudeSeconds": 20,
      "LongitudeDirection": "W",
      "Altitude": 96,
      "LatitudeDecimal": 38.944,
      "LongitudeDecimal": -77.456
    },
    {
      "ICAO": "KIAG",
      "IATA": "IAG",
      "Airport": "NIAGARA FALLS INTERNATIONAL",
      "City": "NIAGARA FALLS",
      "Country": "USA",
      "LatitudeDegrees": 43,
      "LatitudeMinutes": 6,
      "LatitudeSeconds": 26,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 78,
      "LongitudeMinutes": 56,
      "LongitudeSeconds": 46,
      "LongitudeDirection": "W",
      "Altitude": 180,
      "LatitudeDecimal": 43.107,
      "LongitudeDecimal": -78.946
    },
    {
      "ICAO": "KIAH",
      "IATA": "IAH",
      "Airport": "GEORGE BUSH INTCNTL HOUSTON",
      "City": "HOUSTON",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 58,
      "LatitudeSeconds": 49,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 20,
      "LongitudeSeconds": 23,
      "LongitudeDirection": "W",
      "Altitude": 30,
      "LatitudeDecimal": 29.98,
      "LongitudeDecimal": -95.34
    },
    {
      "ICAO": "KICT",
      "IATA": "ICT",
      "Airport": "WICHITA MID CONTINENT",
      "City": "WICHITA",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 59,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 25,
      "LongitudeSeconds": 59,
      "LongitudeDirection": "W",
      "Altitude": 407,
      "LatitudeDecimal": 37.65,
      "LongitudeDecimal": -97.433
    },
    {
      "ICAO": "KIKK",
      "IATA": "IKK",
      "Airport": "GREATER KANKAKEE",
      "City": "KANKAKEE",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 17,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 50,
      "LongitudeSeconds": 46,
      "LongitudeDirection": "W",
      "Altitude": 193,
      "LatitudeDecimal": 41.071,
      "LongitudeDecimal": -87.846
    },
    {
      "ICAO": "KIKR",
      "IATA": "ABQ",
      "Airport": "ALBUQUERQUE INTERNATIONAL SUNPORT",
      "City": "KIRTLAND A.F.B.",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 2,
      "LatitudeSeconds": 24,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 106,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 33,
      "LongitudeDirection": "W",
      "Altitude": 1633,
      "LatitudeDecimal": 35.04,
      "LongitudeDecimal": -106.609
    },
    {
      "ICAO": "KILG",
      "IATA": "ILG",
      "Airport": "NEW CASTLE CO",
      "City": "WILMINGTON",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 43,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 23,
      "LongitudeDirection": "W",
      "Altitude": 25,
      "LatitudeDecimal": 39.679,
      "LongitudeDecimal": -75.606
    },
    {
      "ICAO": "KILM",
      "IATA": "ILM",
      "Airport": "WILMINGTON INTERNATIONAL",
      "City": "WILMINGTON",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 16,
      "LatitudeSeconds": 14,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 77,
      "LongitudeMinutes": 54,
      "LongitudeSeconds": 9,
      "LongitudeDirection": "W",
      "Altitude": 10,
      "LatitudeDecimal": 34.271,
      "LongitudeDecimal": -77.903
    },
    {
      "ICAO": "KIND",
      "IATA": "IND",
      "Airport": "INDIANAPOLIS INTERNATIONAL",
      "City": "INDIANAPOLIS",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 2,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 86,
      "LongitudeMinutes": 17,
      "LongitudeSeconds": 39,
      "LongitudeDirection": "W",
      "Altitude": 243,
      "LatitudeDecimal": 39.717,
      "LongitudeDecimal": -86.294
    },
    {
      "ICAO": "KINJ",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SANTA ANA",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KINK",
      "IATA": "INK",
      "Airport": "WINKLER CO",
      "City": "WINK",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 46,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 103,
      "LongitudeMinutes": 12,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 860,
      "LatitudeDecimal": 31.779,
      "LongitudeDecimal": -103.201
    },
    {
      "ICAO": "KINL",
      "IATA": "INL",
      "Airport": "FALLS INTERNATIONAL",
      "City": "INTERNATIONAL FALLS",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 33,
      "LatitudeSeconds": 58,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 11,
      "LongitudeDirection": "W",
      "Altitude": 362,
      "LatitudeDecimal": 48.566,
      "LongitudeDecimal": -93.403
    },
    {
      "ICAO": "KINS",
      "IATA": "INS",
      "Airport": "INDIAN SPRINGS AF AUX",
      "City": "INDIAN SPRINGS",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 35,
      "LatitudeSeconds": 13,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 115,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 24,
      "LongitudeDirection": "W",
      "Altitude": 955,
      "LatitudeDecimal": 36.587,
      "LongitudeDecimal": -115.673
    },
    {
      "ICAO": "KINT",
      "IATA": "INT",
      "Airport": "SMITH REYNOLDS",
      "City": "WINSTON-SALEM",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 8,
      "LatitudeSeconds": 1,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 19,
      "LongitudeDirection": "W",
      "Altitude": 296,
      "LatitudeDecimal": 36.134,
      "LongitudeDecimal": -80.222
    },
    {
      "ICAO": "KIPL",
      "IATA": "IPL",
      "Airport": "IMPERIAL CO",
      "City": "IMPERIAL",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 50,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 115,
      "LongitudeMinutes": 34,
      "LongitudeSeconds": 43,
      "LongitudeDirection": "W",
      "Altitude": -17,
      "LatitudeDecimal": 32.834,
      "LongitudeDecimal": -115.579
    },
    {
      "ICAO": "KIPT",
      "IATA": "IPT",
      "Airport": "WILLIAMSPORT RGNL",
      "City": "WILLIAMSPORT",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 14,
      "LatitudeSeconds": 31,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 55,
      "LongitudeSeconds": 16,
      "LongitudeDirection": "W",
      "Altitude": 162,
      "LatitudeDecimal": 41.242,
      "LongitudeDecimal": -76.921
    },
    {
      "ICAO": "KISN",
      "IATA": "ISN",
      "Airport": "SLOULIN FLD INTERNATIONAL",
      "City": "WILLISTON",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 10,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 103,
      "LongitudeMinutes": 38,
      "LongitudeSeconds": 32,
      "LongitudeDirection": "W",
      "Altitude": 605,
      "LatitudeDecimal": 48.178,
      "LongitudeDecimal": -103.642
    },
    {
      "ICAO": "KISP",
      "IATA": "ISP",
      "Airport": "LONG ISLAND MAC ARTHUR",
      "City": "ISLIP",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 47,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 73,
      "LongitudeMinutes": 6,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "W",
      "Altitude": 31,
      "LatitudeDecimal": 40.795,
      "LongitudeDecimal": -73.1
    },
    {
      "ICAO": "KJAN",
      "IATA": "JAN",
      "Airport": "JACKSON INTERNATIONAL",
      "City": "JACKSON",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 18,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 90,
      "LongitudeMinutes": 4,
      "LongitudeSeconds": 33,
      "LongitudeDirection": "W",
      "Altitude": 106,
      "LatitudeDecimal": 32.311,
      "LongitudeDecimal": -90.076
    },
    {
      "ICAO": "KJAX",
      "IATA": "JAX",
      "Airport": "JACKSONVILLE INTERNATIONAL",
      "City": "JACKSONVILLE",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 29,
      "LatitudeSeconds": 38,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 41,
      "LongitudeSeconds": 16,
      "LongitudeDirection": "W",
      "Altitude": 10,
      "LatitudeDecimal": 30.494,
      "LongitudeDecimal": -81.688
    },
    {
      "ICAO": "KJBR",
      "IATA": "JBR",
      "Airport": "JONESBORO MUNI",
      "City": "JONESBORO",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 49,
      "LatitudeSeconds": 54,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 90,
      "LongitudeMinutes": 38,
      "LongitudeSeconds": 46,
      "LongitudeDirection": "W",
      "Altitude": 80,
      "LatitudeDecimal": 35.832,
      "LongitudeDecimal": -90.646
    },
    {
      "ICAO": "KJFK",
      "IATA": "JFK",
      "Airport": "JOHN F KENNEDY INTERNATIONAL",
      "City": "NEW YORK",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 23,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 73,
      "LongitudeMinutes": 46,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 40.64,
      "LongitudeDecimal": -73.779
    },
    {
      "ICAO": "KJNT",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "HEMPSTED",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KJYD",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "MERIDIAN",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KLAN",
      "IATA": "LAN",
      "Airport": "CAPITAL CITY",
      "City": "LANSING",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 46,
      "LatitudeSeconds": 43,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 84,
      "LongitudeMinutes": 35,
      "LongitudeSeconds": 14,
      "LongitudeDirection": "W",
      "Altitude": 263,
      "LatitudeDecimal": 42.779,
      "LongitudeDecimal": -84.587
    },
    {
      "ICAO": "KLAS",
      "IATA": "LAS",
      "Airport": "MC CARRAN INTERNATIONAL",
      "City": "LAS VEGAS",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 49,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 115,
      "LongitudeMinutes": 9,
      "LongitudeSeconds": 8,
      "LongitudeDirection": "W",
      "Altitude": 665,
      "LatitudeDecimal": 36.08,
      "LongitudeDecimal": -115.152
    },
    {
      "ICAO": "KLAX",
      "IATA": "LAX",
      "Airport": "LOS ANGELES INTERNATIONAL",
      "City": "LOS ANGELES",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 33,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 118,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 29,
      "LongitudeDirection": "W",
      "Altitude": 39,
      "LatitudeDecimal": 33.942,
      "LongitudeDecimal": -118.408
    },
    {
      "ICAO": "KLBB",
      "IATA": "LBB",
      "Airport": "LUBBOCK INTERNATIONAL",
      "City": "LUBBOCK",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 39,
      "LatitudeSeconds": 49,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 101,
      "LongitudeMinutes": 49,
      "LongitudeSeconds": 22,
      "LongitudeDirection": "W",
      "Altitude": 1001,
      "LatitudeDecimal": 33.664,
      "LongitudeDecimal": -101.823
    },
    {
      "ICAO": "KLCH",
      "IATA": "LCH",
      "Airport": "LAKE CHARLES RGNL",
      "City": "LAKE CHARLES",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 34,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 24,
      "LongitudeDirection": "W",
      "Altitude": 5,
      "LatitudeDecimal": 30.126,
      "LongitudeDecimal": -93.223
    },
    {
      "ICAO": "KLCK",
      "IATA": "LCK",
      "Airport": "RICKENBACKER INTERNATIONAL",
      "City": "COLUMBUS",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 48,
      "LatitudeSeconds": 49,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 55,
      "LongitudeSeconds": 40,
      "LongitudeDirection": "W",
      "Altitude": 227,
      "LatitudeDecimal": 39.814,
      "LongitudeDecimal": -82.928
    },
    {
      "ICAO": "KLFI",
      "IATA": "LFI",
      "Airport": "LANGLEY AFB",
      "City": "HAMPTON",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 58,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 37.083,
      "LongitudeDecimal": -76.36
    },
    {
      "ICAO": "KLFK",
      "IATA": "LFK",
      "Airport": "ANGELINA CO",
      "City": "LUFKIN",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 14,
      "LatitudeSeconds": 2,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "W",
      "Altitude": 91,
      "LatitudeDecimal": 31.234,
      "LongitudeDecimal": -94.75
    },
    {
      "ICAO": "KLFT",
      "IATA": "LFT",
      "Airport": "LAFAYETTE RGNL",
      "City": "LAFAYETTE",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 12,
      "LatitudeSeconds": 19,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 91,
      "LongitudeMinutes": 59,
      "LongitudeSeconds": 15,
      "LongitudeDirection": "W",
      "Altitude": 13,
      "LatitudeDecimal": 30.205,
      "LongitudeDecimal": -91.987
    },
    {
      "ICAO": "KLGA",
      "IATA": "LGA",
      "Airport": "LA GUARDIA",
      "City": "NEW YORK",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 46,
      "LatitudeSeconds": 38,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 73,
      "LongitudeMinutes": 52,
      "LongitudeSeconds": 21,
      "LongitudeDirection": "W",
      "Altitude": 7,
      "LatitudeDecimal": 40.777,
      "LongitudeDecimal": -73.872
    },
    {
      "ICAO": "KLGB",
      "IATA": "LGB",
      "Airport": "LONG BEACH",
      "City": "LONG BEACH",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 49,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 118,
      "LongitudeMinutes": 9,
      "LongitudeSeconds": 5,
      "LongitudeDirection": "W",
      "Altitude": 19,
      "LatitudeDecimal": 33.818,
      "LongitudeDecimal": -118.151
    },
    {
      "ICAO": "KLHW",
      "IATA": "LHW",
      "Airport": "WRIGHT AAF",
      "City": "WRIGHT",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 53,
      "LatitudeSeconds": 20,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 33,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 15,
      "LatitudeDecimal": 31.889,
      "LongitudeDecimal": -81.562
    },
    {
      "ICAO": "KLIT",
      "IATA": "LIT",
      "Airport": "ADAMS FLD",
      "City": "LITTLE ROCK",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 27,
      "LongitudeDirection": "W",
      "Altitude": 80,
      "LatitudeDecimal": 34.729,
      "LongitudeDecimal": -92.224
    },
    {
      "ICAO": "KLIZ",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "LIMESTONE",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KLNA",
      "IATA": "LNA",
      "Airport": "PALM BEACH CO PARK",
      "City": "WEST PALM BEACH",
      "Country": "USA",
      "LatitudeDegrees": 26,
      "LatitudeMinutes": 35,
      "LatitudeSeconds": 34,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 5,
      "LongitudeSeconds": 6,
      "LongitudeDirection": "W",
      "Altitude": 5,
      "LatitudeDecimal": 26.593,
      "LongitudeDecimal": -80.085
    },
    {
      "ICAO": "KLNK",
      "IATA": "LNK",
      "Airport": "LINCOLN MUNI",
      "City": "LINCOLN",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 51,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 96,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 33,
      "LongitudeDirection": "W",
      "Altitude": 372,
      "LatitudeDecimal": 40.851,
      "LongitudeDecimal": -96.759
    },
    {
      "ICAO": "KLOU",
      "IATA": "LOU",
      "Airport": "BOWMAN FLD",
      "City": "LOUISVILLE",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 13,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 85,
      "LongitudeMinutes": 39,
      "LongitudeSeconds": 49,
      "LongitudeDirection": "W",
      "Altitude": 167,
      "LatitudeDecimal": 38.228,
      "LongitudeDecimal": -85.664
    },
    {
      "ICAO": "KLRD",
      "IATA": "LRD",
      "Airport": "LAREDO INTERNATIONAL",
      "City": "LAREDO",
      "Country": "USA",
      "LatitudeDegrees": 27,
      "LatitudeMinutes": 32,
      "LatitudeSeconds": 37,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 99,
      "LongitudeMinutes": 27,
      "LongitudeSeconds": 41,
      "LongitudeDirection": "W",
      "Altitude": 155,
      "LatitudeDecimal": 27.544,
      "LongitudeDecimal": -99.461
    },
    {
      "ICAO": "KLRF",
      "IATA": "LRF",
      "Airport": "LITTLE ROCK AFB",
      "City": "JACKSONVILLE",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 55,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 8,
      "LongitudeSeconds": 47,
      "LongitudeDirection": "W",
      "Altitude": 95,
      "LatitudeDecimal": 34.918,
      "LongitudeDecimal": -92.146
    },
    {
      "ICAO": "KLSF",
      "IATA": "LSF",
      "Airport": "LAWSON AAF",
      "City": "FORT BENNING",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 20,
      "LatitudeSeconds": 14,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 84,
      "LongitudeMinutes": 59,
      "LongitudeSeconds": 28,
      "LongitudeDirection": "W",
      "Altitude": 71,
      "LatitudeDecimal": 32.337,
      "LongitudeDecimal": -84.991
    },
    {
      "ICAO": "KLSV",
      "IATA": "LSV",
      "Airport": "NELLIS AFB",
      "City": "LAS VEGAS",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 14,
      "LatitudeSeconds": 10,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 115,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 3,
      "LongitudeDirection": "W",
      "Altitude": 570,
      "LatitudeDecimal": 36.236,
      "LongitudeDecimal": -115.034
    },
    {
      "ICAO": "KLTS",
      "IATA": "LTS",
      "Airport": "ALTUS AFB",
      "City": "ALTUS",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 1,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 99,
      "LongitudeMinutes": 16,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "W",
      "Altitude": 422,
      "LatitudeDecimal": 34.667,
      "LongitudeDecimal": -99.267
    },
    {
      "ICAO": "KLUF",
      "IATA": "LUF",
      "Airport": "LUKE AFB",
      "City": "PHOENIX",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 32,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 112,
      "LongitudeMinutes": 22,
      "LongitudeSeconds": 59,
      "LongitudeDirection": "W",
      "Altitude": 331,
      "LatitudeDecimal": 33.535,
      "LongitudeDecimal": -112.383
    },
    {
      "ICAO": "KLUK",
      "IATA": "LUK",
      "Airport": "CINCINNATI MUNI LUNKEN FLD",
      "City": "CINCINNATI",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 6,
      "LatitudeSeconds": 12,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 84,
      "LongitudeMinutes": 25,
      "LongitudeSeconds": 7,
      "LongitudeDirection": "W",
      "Altitude": 148,
      "LatitudeDecimal": 39.103,
      "LongitudeDecimal": -84.419
    },
    {
      "ICAO": "KMAF",
      "IATA": "MAF",
      "Airport": "MIDLAND INTERNATIONAL",
      "City": "MIDLAND",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 33,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 102,
      "LongitudeMinutes": 12,
      "LongitudeSeconds": 6,
      "LongitudeDirection": "W",
      "Altitude": 876,
      "LatitudeDecimal": 31.942,
      "LongitudeDecimal": -102.202
    },
    {
      "ICAO": "KMCC",
      "IATA": "MCC",
      "Airport": "MC CLELLAN AFLD",
      "City": "SACRAMENTO",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 121,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 2,
      "LongitudeDirection": "W",
      "Altitude": 23,
      "LatitudeDecimal": 38.667,
      "LongitudeDecimal": -121.401
    },
    {
      "ICAO": "KMCF",
      "IATA": "MCF",
      "Airport": "MACDILL AFB",
      "City": "TAMPA",
      "Country": "USA",
      "LatitudeDegrees": 27,
      "LatitudeMinutes": 50,
      "LatitudeSeconds": 57,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 31,
      "LongitudeSeconds": 16,
      "LongitudeDirection": "W",
      "Altitude": 5,
      "LatitudeDecimal": 27.849,
      "LongitudeDecimal": -82.521
    },
    {
      "ICAO": "KMCI",
      "IATA": "MCI",
      "Airport": "KANSAS CITY INTERNATIONAL",
      "City": "KANSAS CITY",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 17,
      "LatitudeSeconds": 51,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 94,
      "LongitudeMinutes": 42,
      "LongitudeSeconds": 50,
      "LongitudeDirection": "W",
      "Altitude": 313,
      "LatitudeDecimal": 39.297,
      "LongitudeDecimal": -94.714
    },
    {
      "ICAO": "KMCN",
      "IATA": "MCN",
      "Airport": "MIDDLE GEORGIA RGNL",
      "City": "MACON",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 41,
      "LatitudeSeconds": 34,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 83,
      "LongitudeMinutes": 38,
      "LongitudeSeconds": 57,
      "LongitudeDirection": "W",
      "Altitude": 108,
      "LatitudeDecimal": 32.693,
      "LongitudeDecimal": -83.649
    },
    {
      "ICAO": "KMCO",
      "IATA": "MCO",
      "Airport": "ORLANDO INTERNATIONAL",
      "City": "ORLANDO",
      "Country": "USA",
      "LatitudeDegrees": 28,
      "LatitudeMinutes": 25,
      "LatitudeSeconds": 44,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 18,
      "LongitudeSeconds": 57,
      "LongitudeDirection": "W",
      "Altitude": 30,
      "LatitudeDecimal": 28.429,
      "LongitudeDecimal": -81.316
    },
    {
      "ICAO": "KMDT",
      "IATA": "MDT",
      "Airport": "HARRISBURG INTERNATIONAL",
      "City": "HARRISBURG",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 11,
      "LatitudeSeconds": 36,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 48,
      "LongitudeDirection": "W",
      "Altitude": 95,
      "LatitudeDecimal": 40.193,
      "LongitudeDecimal": -76.763
    },
    {
      "ICAO": "KMDW",
      "IATA": "MDW",
      "Airport": "CHICAGO MIDWAY INTERNATIONAL",
      "City": "CHICAGO",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 47,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 8,
      "LongitudeDirection": "W",
      "Altitude": 189,
      "LatitudeDecimal": 41.786,
      "LongitudeDecimal": -87.752
    },
    {
      "ICAO": "KMEM",
      "IATA": "MEM",
      "Airport": "MEMPHIS INTERNATIONAL",
      "City": "MEMPHIS",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 2,
      "LatitudeSeconds": 32,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 89,
      "LongitudeMinutes": 58,
      "LongitudeSeconds": 36,
      "LongitudeDirection": "W",
      "Altitude": 104,
      "LatitudeDecimal": 35.042,
      "LongitudeDecimal": -89.977
    },
    {
      "ICAO": "KMER",
      "IATA": "MER",
      "Airport": "CASTLE",
      "City": "MERCED",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 22,
      "LatitudeSeconds": 49,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 120,
      "LongitudeMinutes": 34,
      "LongitudeSeconds": 5,
      "LongitudeDirection": "W",
      "Altitude": 58,
      "LatitudeDecimal": 37.38,
      "LongitudeDecimal": -120.568
    },
    {
      "ICAO": "KMFE",
      "IATA": "MFE",
      "Airport": "MC ALLEN MILLER INTERNATIONAL",
      "City": "MCALLEN",
      "Country": "USA",
      "LatitudeDegrees": 26,
      "LatitudeMinutes": 10,
      "LatitudeSeconds": 33,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 98,
      "LongitudeMinutes": 14,
      "LongitudeSeconds": 19,
      "LongitudeDirection": "W",
      "Altitude": 33,
      "LatitudeDecimal": 26.176,
      "LongitudeDecimal": -98.239
    },
    {
      "ICAO": "KMGE",
      "IATA": "MGE",
      "Airport": "DOBBINS ARB",
      "City": "MARIETTA",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 55,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 84,
      "LongitudeMinutes": 30,
      "LongitudeSeconds": 58,
      "LongitudeDirection": "W",
      "Altitude": 326,
      "LatitudeDecimal": 33.915,
      "LongitudeDecimal": -84.516
    },
    {
      "ICAO": "KMHR",
      "IATA": "MHR",
      "Airport": "SACRAMENTO MATHER",
      "City": "SACRAMENTO",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 33,
      "LatitudeSeconds": 14,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 121,
      "LongitudeMinutes": 17,
      "LongitudeSeconds": 51,
      "LongitudeDirection": "W",
      "Altitude": 30,
      "LatitudeDecimal": 38.554,
      "LongitudeDecimal": -121.297
    },
    {
      "ICAO": "KMIA",
      "IATA": "MIA",
      "Airport": "MIAMI INTERNATIONAL",
      "City": "MIAMI",
      "Country": "USA",
      "LatitudeDegrees": 25,
      "LatitudeMinutes": 47,
      "LatitudeSeconds": 35,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 17,
      "LongitudeSeconds": 26,
      "LongitudeDirection": "W",
      "Altitude": 3,
      "LatitudeDecimal": 25.793,
      "LongitudeDecimal": -80.291
    },
    {
      "ICAO": "KMIB",
      "IATA": "MIB",
      "Airport": "MINOT AFB",
      "City": "MINOT",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 24,
      "LatitudeSeconds": 56,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 101,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 27,
      "LongitudeDirection": "W",
      "Altitude": 509,
      "LatitudeDecimal": 48.416,
      "LongitudeDecimal": -101.357
    },
    {
      "ICAO": "KMIV",
      "IATA": "MIV",
      "Airport": "MILLVILLE MUNI",
      "City": "MILLVILLE",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 22,
      "LatitudeSeconds": 4,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 4,
      "LongitudeSeconds": 20,
      "LongitudeDirection": "W",
      "Altitude": 26,
      "LatitudeDecimal": 39.368,
      "LongitudeDecimal": -75.072
    },
    {
      "ICAO": "KMKE",
      "IATA": "MKE",
      "Airport": "GENERAL MITCHELL INTERNATIONAL",
      "City": "MILWAUKEE",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 50,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 53,
      "LongitudeSeconds": 47,
      "LongitudeDirection": "W",
      "Altitude": 221,
      "LatitudeDecimal": 42.947,
      "LongitudeDecimal": -87.896
    },
    {
      "ICAO": "KMKL",
      "IATA": "MKL",
      "Airport": "MC KELLAR SIPES RGNL",
      "City": "JACKSON",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 35,
      "LatitudeSeconds": 59,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 88,
      "LongitudeMinutes": 54,
      "LongitudeSeconds": 56,
      "LongitudeDirection": "W",
      "Altitude": 133,
      "LatitudeDecimal": 35.6,
      "LongitudeDecimal": -88.916
    },
    {
      "ICAO": "KMKO",
      "IATA": "MKO",
      "Airport": "DAVIS FLD",
      "City": "MUSKOGEE",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 39,
      "LatitudeSeconds": 27,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 41,
      "LongitudeDirection": "W",
      "Altitude": 186,
      "LatitudeDecimal": 35.657,
      "LongitudeDecimal": -95.361
    },
    {
      "ICAO": "KMLB",
      "IATA": "MLB",
      "Airport": "MELBOURNE INTERNATIONAL",
      "City": "MELBOURNE",
      "Country": "USA",
      "LatitudeDegrees": 28,
      "LatitudeMinutes": 6,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 38,
      "LongitudeSeconds": 42,
      "LongitudeDirection": "W",
      "Altitude": 11,
      "LatitudeDecimal": 28.103,
      "LongitudeDecimal": -80.645
    },
    {
      "ICAO": "KMLC",
      "IATA": "MLC",
      "Airport": "MC ALESTER RGNL",
      "City": "MCALESTER",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 52,
      "LatitudeSeconds": 56,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 47,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "W",
      "Altitude": 235,
      "LatitudeDecimal": 34.882,
      "LongitudeDecimal": -95.783
    },
    {
      "ICAO": "KMLT",
      "IATA": "MLT",
      "Airport": "MILLINOCKET MUNI",
      "City": "MILLINOCKET",
      "Country": "USA",
      "LatitudeDegrees": 45,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 52,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 68,
      "LongitudeMinutes": 41,
      "LongitudeSeconds": 8,
      "LongitudeDirection": "W",
      "Altitude": 125,
      "LatitudeDecimal": 45.648,
      "LongitudeDecimal": -68.686
    },
    {
      "ICAO": "KMLU",
      "IATA": "MLU",
      "Airport": "MONROE RGNL",
      "City": "MONROE",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 30,
      "LatitudeSeconds": 39,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 15,
      "LongitudeDirection": "W",
      "Altitude": 25,
      "LatitudeDecimal": 32.511,
      "LongitudeDecimal": -92.037
    },
    {
      "ICAO": "KMMV",
      "IATA": "MMV",
      "Airport": "MC MINNVILLE MUNI",
      "City": "MACKMINNVILLE",
      "Country": "USA",
      "LatitudeDegrees": 45,
      "LatitudeMinutes": 11,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 123,
      "LongitudeMinutes": 8,
      "LongitudeSeconds": 9,
      "LongitudeDirection": "W",
      "Altitude": 50,
      "LatitudeDecimal": 45.194,
      "LongitudeDecimal": -123.136
    },
    {
      "ICAO": "KMNM",
      "IATA": "MNM",
      "Airport": "MENOMINEE MARINETTE TWIN CO",
      "City": "MACON",
      "Country": "USA",
      "LatitudeDegrees": 45,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 35,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 38,
      "LongitudeSeconds": 18,
      "LongitudeDirection": "W",
      "Altitude": 191,
      "LatitudeDecimal": 45.126,
      "LongitudeDecimal": -87.638
    },
    {
      "ICAO": "KMOB",
      "IATA": "MOB",
      "Airport": "MOBILE RGNL",
      "City": "MOBILE",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 41,
      "LatitudeSeconds": 29,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 88,
      "LongitudeMinutes": 14,
      "LongitudeSeconds": 34,
      "LongitudeDirection": "W",
      "Altitude": 67,
      "LatitudeDecimal": 30.691,
      "LongitudeDecimal": -88.243
    },
    {
      "ICAO": "KMOD",
      "IATA": "MOD",
      "Airport": "MODESTO CITY CO HARRY SHAM",
      "City": "MODESTO",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 37,
      "LatitudeSeconds": 32,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 120,
      "LongitudeMinutes": 57,
      "LongitudeSeconds": 15,
      "LongitudeDirection": "W",
      "Altitude": 30,
      "LatitudeDecimal": 37.626,
      "LongitudeDecimal": -120.954
    },
    {
      "ICAO": "KMOT",
      "IATA": "MOT",
      "Airport": "MINOT INTERNATIONAL",
      "City": "MINOT",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 15,
      "LatitudeSeconds": 33,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 101,
      "LongitudeMinutes": 16,
      "LongitudeSeconds": 49,
      "LongitudeDirection": "W",
      "Altitude": 519,
      "LatitudeDecimal": 48.259,
      "LongitudeDecimal": -101.28
    },
    {
      "ICAO": "KMPV",
      "IATA": "MPV",
      "Airport": "EDWARD F KNAPP STATE",
      "City": "MONTPELIER",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 12,
      "LatitudeSeconds": 12,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 72,
      "LongitudeMinutes": 33,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 356,
      "LatitudeDecimal": 44.203,
      "LongitudeDecimal": -72.562
    },
    {
      "ICAO": "KMQT",
      "IATA": "MQT",
      "Airport": "SAWYER INTERNATIONAL",
      "City": "MARQUETTE",
      "Country": "USA",
      "LatitudeDegrees": 46,
      "LatitudeMinutes": 32,
      "LatitudeSeconds": 2,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 33,
      "LongitudeSeconds": 42,
      "LongitudeDirection": "W",
      "Altitude": 433,
      "LatitudeDecimal": 46.534,
      "LongitudeDecimal": -87.562
    },
    {
      "ICAO": "KMSN",
      "IATA": "MSN",
      "Airport": "DANE CO RGNL TRUAX FLD",
      "City": "MADISON",
      "Country": "USA",
      "LatitudeDegrees": 43,
      "LatitudeMinutes": 8,
      "LatitudeSeconds": 23,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 89,
      "LongitudeMinutes": 20,
      "LongitudeSeconds": 15,
      "LongitudeDirection": "W",
      "Altitude": 271,
      "LatitudeDecimal": 43.14,
      "LongitudeDecimal": -89.337
    },
    {
      "ICAO": "KMSP",
      "IATA": "MSP",
      "Airport": "MINNEAPOLIS ST PAUL INTERNATIONAL",
      "City": "MINNEAPOLIS",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 52,
      "LatitudeSeconds": 49,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "W",
      "Altitude": 257,
      "LatitudeDecimal": 44.88,
      "LongitudeDecimal": -93.217
    },
    {
      "ICAO": "KMSS",
      "IATA": "MSS",
      "Airport": "MASSENA INTERNATIONAL RICHARDS FLD",
      "City": "MASSENA",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 74,
      "LongitudeMinutes": 50,
      "LongitudeSeconds": 43,
      "LongitudeDirection": "W",
      "Altitude": 66,
      "LatitudeDecimal": 44.936,
      "LongitudeDecimal": -74.845
    },
    {
      "ICAO": "KMSY",
      "IATA": "MSY",
      "Airport": "LOUIS ARMSTRONG NEW ORLEANS INTERNATIONAL",
      "City": "NEW ORLEANS",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 59,
      "LatitudeSeconds": 36,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 90,
      "LongitudeMinutes": 15,
      "LongitudeSeconds": 28,
      "LongitudeDirection": "W",
      "Altitude": 2,
      "LatitudeDecimal": 29.993,
      "LongitudeDecimal": -90.258
    },
    {
      "ICAO": "KMTC",
      "IATA": "MTC",
      "Airport": "SELFRIDGE ANGB",
      "City": "MOUNT CLEMENS",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 36,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 49,
      "LongitudeSeconds": 54,
      "LongitudeDirection": "W",
      "Altitude": 177,
      "LatitudeDecimal": 42.613,
      "LongitudeDecimal": -82.832
    },
    {
      "ICAO": "KMUI",
      "IATA": "MUI",
      "Airport": "MUIR AAF",
      "City": "MUIR",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 26,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 34,
      "LongitudeSeconds": 9,
      "LongitudeDirection": "W",
      "Altitude": 150,
      "LatitudeDecimal": 40.435,
      "LongitudeDecimal": -76.569
    },
    {
      "ICAO": "KMUO",
      "IATA": "MUO",
      "Airport": "MOUNTAIN HOME AFB",
      "City": "MOUNTAIN HOME",
      "Country": "USA",
      "LatitudeDegrees": 43,
      "LatitudeMinutes": 2,
      "LatitudeSeconds": 36,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 115,
      "LongitudeMinutes": 52,
      "LongitudeSeconds": 20,
      "LongitudeDirection": "W",
      "Altitude": 914,
      "LatitudeDecimal": 43.043,
      "LongitudeDecimal": -115.872
    },
    {
      "ICAO": "KMWH",
      "IATA": "MWH",
      "Airport": "GRANT CO INTERNATIONAL",
      "City": "GRANT COUNTY AIRPORT",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 12,
      "LatitudeSeconds": 27,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 119,
      "LongitudeMinutes": 19,
      "LongitudeSeconds": 12,
      "LongitudeDirection": "W",
      "Altitude": 362,
      "LatitudeDecimal": 47.208,
      "LongitudeDecimal": -119.32
    },
    {
      "ICAO": "KMWL",
      "IATA": "MWL",
      "Airport": "MINERAL WELLS",
      "City": "MINERAL WELLS",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 46,
      "LatitudeSeconds": 53,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 98,
      "LongitudeMinutes": 3,
      "LongitudeSeconds": 36,
      "LongitudeDirection": "W",
      "Altitude": 297,
      "LatitudeDecimal": 32.781,
      "LongitudeDecimal": -98.06
    },
    {
      "ICAO": "KMXF",
      "IATA": "MXF",
      "Airport": "MAXWELL AFB",
      "City": "MONTGOMERY",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 22,
      "LatitudeSeconds": 45,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 86,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 45,
      "LongitudeDirection": "W",
      "Altitude": 53,
      "LatitudeDecimal": 32.379,
      "LongitudeDecimal": -86.362
    },
    {
      "ICAO": "KMYR",
      "IATA": "MYR",
      "Airport": "MYRTLE BEACH INTERNATIONAL",
      "City": "MYRTLE BEACH",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 47,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 78,
      "LongitudeMinutes": 55,
      "LongitudeSeconds": 42,
      "LongitudeDirection": "W",
      "Altitude": 8,
      "LatitudeDecimal": 33.68,
      "LongitudeDecimal": -78.928
    },
    {
      "ICAO": "KNAB",
      "IATA": "ABY",
      "Airport": "N/A",
      "City": "ALBANY",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNBC",
      "IATA": "NBC",
      "Airport": "BEAUFORT MCAS",
      "City": "BEAUFORT",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 28,
      "LatitudeSeconds": 38,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 43,
      "LongitudeSeconds": 23,
      "LongitudeDirection": "W",
      "Altitude": 11,
      "LatitudeDecimal": 32.477,
      "LongitudeDecimal": -80.723
    },
    {
      "ICAO": "KNBG",
      "IATA": "NBG",
      "Airport": "NEW ORLEANS NAS JRB",
      "City": "NEW ORLEANS",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 49,
      "LatitudeSeconds": 31,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 90,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 6,
      "LongitudeDirection": "W",
      "Altitude": 1,
      "LatitudeDecimal": 29.825,
      "LongitudeDecimal": -90.035
    },
    {
      "ICAO": "KNBM",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "MAYPORT",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNBU",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "GLENVIEW",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNCA",
      "IATA": "NCA",
      "Airport": "NEW RIVER MCAS",
      "City": "JACKSONVILLE",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 42,
      "LatitudeSeconds": 30,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 77,
      "LongitudeMinutes": 26,
      "LongitudeSeconds": 22,
      "LongitudeDirection": "W",
      "Altitude": 8,
      "LatitudeDecimal": 34.708,
      "LongitudeDecimal": -77.439
    },
    {
      "ICAO": "KNCO",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "QUONSET POINT",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNEA",
      "IATA": "BQK",
      "Airport": "N/A",
      "City": "BRUNSWICK",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNEL",
      "IATA": "NEL",
      "Airport": "LAKEHURST NAES",
      "City": "LAKEHURST",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 2,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 74,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 12,
      "LongitudeDirection": "W",
      "Altitude": 32,
      "LatitudeDecimal": 40.033,
      "LongitudeDecimal": -74.353
    },
    {
      "ICAO": "KNFF",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "JACKSONVILLE",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNFL",
      "IATA": "NFL",
      "Airport": "FALLON NAS",
      "City": "FALLON",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 24,
      "LatitudeSeconds": 59,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 118,
      "LongitudeMinutes": 42,
      "LongitudeSeconds": 3,
      "LongitudeDirection": "W",
      "Altitude": 1200,
      "LatitudeDecimal": 39.416,
      "LongitudeDecimal": -118.701
    },
    {
      "ICAO": "KNGU",
      "IATA": "NGU",
      "Airport": "NORFOLK NS",
      "City": "NORFOLK",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 15,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 17,
      "LongitudeSeconds": 21,
      "LongitudeDirection": "W",
      "Altitude": 5,
      "LatitudeDecimal": 36.938,
      "LongitudeDecimal": -76.289
    },
    {
      "ICAO": "KNGZ",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "ALAMEDA",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNHK",
      "IATA": "NHK",
      "Airport": "PATUXENT RIVER NAS",
      "City": "PATUXENT RIVER",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 17,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 42,
      "LongitudeDirection": "W",
      "Altitude": 12,
      "LatitudeDecimal": 38.286,
      "LongitudeDecimal": -76.412
    },
    {
      "ICAO": "KNID",
      "IATA": "NID",
      "Airport": "CHINA LAKE NAWS",
      "City": "CHINA",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 41,
      "LatitudeSeconds": 16,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 41,
      "LongitudeSeconds": 26,
      "LongitudeDirection": "W",
      "Altitude": 696,
      "LatitudeDecimal": 35.688,
      "LongitudeDecimal": -117.691
    },
    {
      "ICAO": "KNIP",
      "IATA": "NIP",
      "Airport": "JACKSONVILLE NAS",
      "City": "JACKSONVILLE",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 14,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 50,
      "LongitudeDirection": "W",
      "Altitude": 7,
      "LatitudeDecimal": 30.236,
      "LongitudeDecimal": -81.681
    },
    {
      "ICAO": "KNIR",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "BEEVILLE",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNJK",
      "IATA": "NJK",
      "Airport": "EL CENTRO NAF",
      "City": "EL CENTRO",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 49,
      "LatitudeSeconds": 45,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 115,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 18,
      "LongitudeDirection": "W",
      "Altitude": -13,
      "LatitudeDecimal": 32.829,
      "LongitudeDecimal": -115.672
    },
    {
      "ICAO": "KNKT",
      "IATA": "NKT",
      "Airport": "CHERRY POINT MCAS",
      "City": "CHERRY POINT",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 52,
      "LongitudeSeconds": 51,
      "LongitudeDirection": "W",
      "Altitude": 9,
      "LatitudeDecimal": 34.902,
      "LongitudeDecimal": -76.881
    },
    {
      "ICAO": "KNKX",
      "IATA": "NKX",
      "Airport": "MIRAMAR MCAS",
      "City": "MIRAMAR",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 52,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 8,
      "LongitudeSeconds": 33,
      "LongitudeDirection": "W",
      "Altitude": 146,
      "LatitudeDecimal": 32.868,
      "LongitudeDecimal": -117.143
    },
    {
      "ICAO": "KNLC",
      "IATA": "NLC",
      "Airport": "LEMOORE NAS",
      "City": "LEMOORE",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 19,
      "LatitudeSeconds": 58,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 119,
      "LongitudeMinutes": 57,
      "LongitudeSeconds": 7,
      "LongitudeDirection": "W",
      "Altitude": 72,
      "LatitudeDecimal": 36.333,
      "LongitudeDecimal": -119.952
    },
    {
      "ICAO": "KNMH",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "WASHINGTON",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNMM",
      "IATA": "NMM",
      "Airport": "MERIDIAN NAS",
      "City": "MERIDIAN",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 33,
      "LatitudeSeconds": 7,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 88,
      "LongitudeMinutes": 33,
      "LongitudeSeconds": 20,
      "LongitudeDirection": "W",
      "Altitude": 97,
      "LatitudeDecimal": 32.552,
      "LongitudeDecimal": -88.556
    },
    {
      "ICAO": "KNOW",
      "IATA": "NOW",
      "Airport": "PORT ANGELES CGAS",
      "City": "PORT ANGELES",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 8,
      "LatitudeSeconds": 29,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 123,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 50,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 48.141,
      "LongitudeDecimal": -123.414
    },
    {
      "ICAO": "KNPA",
      "IATA": "NPA",
      "Airport": "PENSACOLA NAS",
      "City": "PENSACOLA",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 19,
      "LongitudeSeconds": 7,
      "LongitudeDirection": "W",
      "Altitude": 9,
      "LatitudeDecimal": 30.353,
      "LongitudeDecimal": -87.319
    },
    {
      "ICAO": "KNQA",
      "IATA": "NQA",
      "Airport": "MILLINGTON MUNI",
      "City": "MILLINGTON",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 24,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 89,
      "LongitudeMinutes": 52,
      "LongitudeSeconds": 13,
      "LongitudeDirection": "W",
      "Altitude": 98,
      "LatitudeDecimal": 35.357,
      "LongitudeDecimal": -89.87
    },
    {
      "ICAO": "KNQI",
      "IATA": "NQI",
      "Airport": "KINGSVILLE NAS",
      "City": "KINGSVILLE",
      "Country": "USA",
      "LatitudeDegrees": 27,
      "LatitudeMinutes": 30,
      "LatitudeSeconds": 26,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 48,
      "LongitudeSeconds": 35,
      "LongitudeDirection": "W",
      "Altitude": 16,
      "LatitudeDecimal": 27.507,
      "LongitudeDecimal": -97.81
    },
    {
      "ICAO": "KNQX",
      "IATA": "NQX",
      "Airport": "KEY WEST NAS",
      "City": "KEY WEST",
      "Country": "USA",
      "LatitudeDegrees": 24,
      "LatitudeMinutes": 34,
      "LatitudeSeconds": 33,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 41,
      "LongitudeSeconds": 20,
      "LongitudeDirection": "W",
      "Altitude": 2,
      "LatitudeDecimal": 24.576,
      "LongitudeDecimal": -81.689
    },
    {
      "ICAO": "KNRJ",
      "IATA": "SFB",
      "Airport": "N/A",
      "City": "SANFORD",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNSE",
      "IATA": "NSE",
      "Airport": "WHITING FLD NAS NORTH",
      "City": "MILTON",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 27,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 1,
      "LongitudeSeconds": 19,
      "LongitudeDirection": "W",
      "Altitude": 61,
      "LatitudeDecimal": 30.724,
      "LongitudeDecimal": -87.022
    },
    {
      "ICAO": "KNSI",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SAN NICOLAS ISLAND",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNTD",
      "IATA": "NTD",
      "Airport": "POINT MUGU NAS",
      "City": "POINT MUGU",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 13,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 119,
      "LongitudeMinutes": 7,
      "LongitudeSeconds": 15,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 34.12,
      "LongitudeDecimal": -119.121
    },
    {
      "ICAO": "KNTK",
      "IATA": "NTK",
      "Airport": "TUSTIN MCAF",
      "City": "TUSTIN",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 42,
      "LatitudeSeconds": 22,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 49,
      "LongitudeSeconds": 38,
      "LongitudeDirection": "W",
      "Altitude": 17,
      "LatitudeDecimal": 33.706,
      "LongitudeDecimal": -117.827
    },
    {
      "ICAO": "KNTU",
      "IATA": "NTU",
      "Airport": "OCEANA NAS",
      "City": "OCEANA",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 49,
      "LatitudeSeconds": 14,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "W",
      "Altitude": 7,
      "LatitudeDecimal": 36.821,
      "LongitudeDecimal": -76.033
    },
    {
      "ICAO": "KNUQ",
      "IATA": "NUQ",
      "Airport": "MOFFETT FEDERAL AFLD",
      "City": "MOUNTAIN VIEW",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 24,
      "LatitudeSeconds": 54,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 53,
      "LongitudeDirection": "W",
      "Altitude": 10,
      "LatitudeDecimal": 37.415,
      "LongitudeDecimal": -122.048
    },
    {
      "ICAO": "KNUW",
      "IATA": "NUW",
      "Airport": "WHIDBEY ISLAND NAS",
      "City": "WHIDBEY ISLAND",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 39,
      "LongitudeSeconds": 21,
      "LongitudeDirection": "W",
      "Altitude": 15,
      "LatitudeDecimal": 48.352,
      "LongitudeDecimal": -122.656
    },
    {
      "ICAO": "KNVF",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "VIRGINIA BEACH",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNXP",
      "IATA": "NXP",
      "Airport": "TWENTYNINE PALMS EAF",
      "City": "TWENTY NINE PALMS",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 17,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 116,
      "LongitudeMinutes": 9,
      "LongitudeSeconds": 43,
      "LongitudeDirection": "W",
      "Altitude": 623,
      "LatitudeDecimal": 34.296,
      "LongitudeDecimal": -116.162
    },
    {
      "ICAO": "KNXX",
      "IATA": "NXX",
      "Airport": "WILLOW GROVE NAS JRB",
      "City": "WILLOW GROVE",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 11,
      "LatitudeSeconds": 59,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 8,
      "LongitudeSeconds": 53,
      "LongitudeDirection": "W",
      "Altitude": 111,
      "LatitudeDecimal": 40.2,
      "LongitudeDecimal": -75.148
    },
    {
      "ICAO": "KNYG",
      "IATA": "NYG",
      "Airport": "QUANTICO MCAF",
      "City": "QUANTICO",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 30,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 77,
      "LongitudeMinutes": 18,
      "LongitudeSeconds": 19,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 38.502,
      "LongitudeDecimal": -77.305
    },
    {
      "ICAO": "KNZC",
      "IATA": "NZC",
      "Airport": "CECIL FLD",
      "City": "JACKSONVILLE",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 13,
      "LatitudeSeconds": 7,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 52,
      "LongitudeSeconds": 35,
      "LongitudeDirection": "W",
      "Altitude": 25,
      "LatitudeDecimal": 30.219,
      "LongitudeDecimal": -81.876
    },
    {
      "ICAO": "KNZW",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SOUTH WEYMOUTH",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KNZY",
      "IATA": "NZY",
      "Airport": "NORTH ISLAND NAS",
      "City": "SAN DIEGO",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 41,
      "LatitudeSeconds": 57,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 12,
      "LongitudeSeconds": 55,
      "LongitudeDirection": "W",
      "Altitude": 8,
      "LatitudeDecimal": 32.699,
      "LongitudeDecimal": -117.215
    },
    {
      "ICAO": "KOAK",
      "IATA": "OAK",
      "Airport": "METROPOLITAN OAKLAND INTERNATIONAL",
      "City": "OAKLAND",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 16,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 14,
      "LongitudeDirection": "W",
      "Altitude": 2,
      "LatitudeDecimal": 37.721,
      "LongitudeDecimal": -122.221
    },
    {
      "ICAO": "KOAR",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "FORT ORD",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KOFF",
      "IATA": "OFF",
      "Airport": "OFFUTT AFB",
      "City": "OMAHA",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 54,
      "LongitudeSeconds": 45,
      "LongitudeDirection": "W",
      "Altitude": 321,
      "LatitudeDecimal": 41.118,
      "LongitudeDecimal": -95.913
    },
    {
      "ICAO": "KOGS",
      "IATA": "OGS",
      "Airport": "OGDENSBURG INTERNATIONAL",
      "City": "OGDENSBURG",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 55,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 27,
      "LongitudeSeconds": 56,
      "LongitudeDirection": "W",
      "Altitude": 91,
      "LatitudeDecimal": 44.682,
      "LongitudeDecimal": -75.466
    },
    {
      "ICAO": "KOKC",
      "IATA": "OKC",
      "Airport": "WILL ROGERS WORLD",
      "City": "OKLAHOMA CITY",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 23,
      "LatitudeSeconds": 35,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 2,
      "LongitudeDirection": "W",
      "Altitude": 395,
      "LatitudeDecimal": 35.393,
      "LongitudeDecimal": -97.601
    },
    {
      "ICAO": "KOLS",
      "IATA": "OLS",
      "Airport": "NOGALES INTERNATIONAL",
      "City": "NOGALES",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 25,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 110,
      "LongitudeMinutes": 50,
      "LongitudeSeconds": 52,
      "LongitudeDirection": "W",
      "Altitude": 1206,
      "LatitudeDecimal": 31.418,
      "LongitudeDecimal": -110.848
    },
    {
      "ICAO": "KOMA",
      "IATA": "OMA",
      "Airport": "EPPLEY AFLD",
      "City": "OMAHA",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 18,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 53,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 300,
      "LatitudeDecimal": 41.302,
      "LongitudeDecimal": -95.894
    },
    {
      "ICAO": "KONT",
      "IATA": "ONT",
      "Airport": "ONTARIO INTERNATIONAL",
      "City": "ONTARIO",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 3,
      "LatitudeSeconds": 21,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 288,
      "LatitudeDecimal": 34.056,
      "LongitudeDecimal": -117.601
    },
    {
      "ICAO": "KOPF",
      "IATA": "OPF",
      "Airport": "OPA LOCKA",
      "City": "MIAMI",
      "Country": "USA",
      "LatitudeDegrees": 25,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 25,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 16,
      "LongitudeSeconds": 42,
      "LongitudeDirection": "W",
      "Altitude": 3,
      "LatitudeDecimal": 25.907,
      "LongitudeDecimal": -80.278
    },
    {
      "ICAO": "KORD",
      "IATA": "ORD",
      "Airport": "CHICAGO OHARE INTERNATIONAL",
      "City": "CHICAGO",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 58,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 54,
      "LongitudeSeconds": 16,
      "LongitudeDirection": "W",
      "Altitude": 204,
      "LatitudeDecimal": 41.979,
      "LongitudeDecimal": -87.904
    },
    {
      "ICAO": "KORF",
      "IATA": "ORF",
      "Airport": "NORFOLK INTERNATIONAL",
      "City": "NORFOLK",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 53,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 12,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 8,
      "LatitudeDecimal": 36.894,
      "LongitudeDecimal": -76.201
    },
    {
      "ICAO": "KORL",
      "IATA": "ORL",
      "Airport": "EXECUTIVE",
      "City": "ORLANDO",
      "Country": "USA",
      "LatitudeDegrees": 28,
      "LatitudeMinutes": 32,
      "LatitudeSeconds": 43,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 19,
      "LongitudeSeconds": 58,
      "LongitudeDirection": "W",
      "Altitude": 35,
      "LatitudeDecimal": 28.545,
      "LongitudeDecimal": -81.333
    },
    {
      "ICAO": "KOSC",
      "IATA": "N/A",
      "Airport": "OSCODA WURTSMITH",
      "City": "OSCODA",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 27,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 83,
      "LongitudeMinutes": 23,
      "LongitudeSeconds": 38,
      "LongitudeDirection": "W",
      "Altitude": 194,
      "LatitudeDecimal": 44.451,
      "LongitudeDecimal": -83.394
    },
    {
      "ICAO": "KPAE",
      "IATA": "PAE",
      "Airport": "SNOHOMISH CO",
      "City": "EVERETT",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 22,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 16,
      "LongitudeSeconds": 53,
      "LongitudeDirection": "W",
      "Altitude": 185,
      "LatitudeDecimal": 47.906,
      "LongitudeDecimal": -122.281
    },
    {
      "ICAO": "KPAM",
      "IATA": "PAM",
      "Airport": "TYNDALL AFB",
      "City": "PANAMA CITY",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 11,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 85,
      "LongitudeMinutes": 34,
      "LongitudeSeconds": 35,
      "LongitudeDirection": "W",
      "Altitude": 6,
      "LatitudeDecimal": 30.07,
      "LongitudeDecimal": -85.576
    },
    {
      "ICAO": "KPBF",
      "IATA": "PBF",
      "Airport": "GRIDER FLD",
      "City": "PINE BLUFF",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 10,
      "LatitudeSeconds": 29,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 91,
      "LongitudeMinutes": 56,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 63,
      "LatitudeDecimal": 34.175,
      "LongitudeDecimal": -91.934
    },
    {
      "ICAO": "KPBG",
      "IATA": "PBG",
      "Airport": "PLATTSBURGH INTERNATIONAL",
      "City": "PLATTSBURGH",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 39,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 73,
      "LongitudeMinutes": 28,
      "LongitudeSeconds": 5,
      "LongitudeDirection": "W",
      "Altitude": 72,
      "LatitudeDecimal": 44.651,
      "LongitudeDecimal": -73.468
    },
    {
      "ICAO": "KPBI",
      "IATA": "PBI",
      "Airport": "PALM BEACH INTERNATIONAL",
      "City": "WEST PALM BEACH",
      "Country": "USA",
      "LatitudeDegrees": 26,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 59,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 5,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 6,
      "LatitudeDecimal": 26.683,
      "LongitudeDecimal": -80.096
    },
    {
      "ICAO": "KPDX",
      "IATA": "PDX",
      "Airport": "PORTLAND INTERNATIONAL",
      "City": "PORTLAND",
      "Country": "USA",
      "LatitudeDegrees": 45,
      "LatitudeMinutes": 35,
      "LatitudeSeconds": 19,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 35,
      "LongitudeSeconds": 51,
      "LongitudeDirection": "W",
      "Altitude": 10,
      "LatitudeDecimal": 45.589,
      "LongitudeDecimal": -122.597
    },
    {
      "ICAO": "KPHF",
      "IATA": "PHF",
      "Airport": "NEWPORT NEWS WILLIAMSBURG INTERNATIONAL",
      "City": "NEWPORT NEWS",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 54,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 29,
      "LongitudeSeconds": 34,
      "LongitudeDirection": "W",
      "Altitude": 14,
      "LatitudeDecimal": 37.132,
      "LongitudeDecimal": -76.493
    },
    {
      "ICAO": "KPHL",
      "IATA": "PHL",
      "Airport": "PHILADELPHIA INTERNATIONAL",
      "City": "PHILADELPHIA",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 52,
      "LatitudeSeconds": 19,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 14,
      "LongitudeSeconds": 28,
      "LongitudeDirection": "W",
      "Altitude": 12,
      "LatitudeDecimal": 39.872,
      "LongitudeDecimal": -75.241
    },
    {
      "ICAO": "KPHN",
      "IATA": "PHN",
      "Airport": "ST CLAIR CO INTERNATIONAL",
      "City": "PORT HURON",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 39,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 31,
      "LongitudeSeconds": 43,
      "LongitudeDirection": "W",
      "Altitude": 199,
      "LatitudeDecimal": 42.911,
      "LongitudeDecimal": -82.529
    },
    {
      "ICAO": "KPHX",
      "IATA": "PHX",
      "Airport": "PHOENIX SKY HARBOR INTERNATIONAL",
      "City": "PHOENIX",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 26,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 112,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 29,
      "LongitudeDirection": "W",
      "Altitude": 346,
      "LatitudeDecimal": 33.434,
      "LongitudeDecimal": -112.008
    },
    {
      "ICAO": "KPIE",
      "IATA": "PIE",
      "Airport": "ST PETERSBURG CLEARWATER INTERNATIONAL",
      "City": "ST. PETERSBURG",
      "Country": "USA",
      "LatitudeDegrees": 27,
      "LatitudeMinutes": 54,
      "LatitudeSeconds": 38,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 41,
      "LongitudeSeconds": 14,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 27.911,
      "LongitudeDecimal": -82.687
    },
    {
      "ICAO": "KPIT",
      "IATA": "PIT",
      "Airport": "PITTSBURGH INTERNATIONAL",
      "City": "PITTSBURGH (PENNSYLVA)",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 29,
      "LatitudeSeconds": 29,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 13,
      "LongitudeSeconds": 58,
      "LongitudeDirection": "W",
      "Altitude": 367,
      "LatitudeDecimal": 40.491,
      "LongitudeDecimal": -80.233
    },
    {
      "ICAO": "KPMB",
      "IATA": "N/A",
      "Airport": "PEMBINA MUNI",
      "City": "PEMBINA",
      "Country": "USA",
      "LatitudeDegrees": 48,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 33,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 14,
      "LongitudeSeconds": 27,
      "LongitudeDirection": "W",
      "Altitude": 243,
      "LatitudeDecimal": 48.942,
      "LongitudeDecimal": -97.241
    },
    {
      "ICAO": "KPMD",
      "IATA": "PMD",
      "Airport": "PALMDALE PROD FLT TEST INSTL AF PLT 42",
      "City": "PALMDALE",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 37,
      "LatitudeSeconds": 45,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 118,
      "LongitudeMinutes": 5,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 776,
      "LatitudeDecimal": 34.629,
      "LongitudeDecimal": -118.084
    },
    {
      "ICAO": "KPNC",
      "IATA": "PNC",
      "Airport": "PONCA CITY MUNI",
      "City": "PONCA CITY",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 50,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 5,
      "LongitudeSeconds": 59,
      "LongitudeDirection": "W",
      "Altitude": 307,
      "LatitudeDecimal": 36.731,
      "LongitudeDecimal": -97.1
    },
    {
      "ICAO": "KPNE",
      "IATA": "PNE",
      "Airport": "NORTHEAST PHILADELPHIA",
      "City": "PHILADELPHIA",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 4,
      "LatitudeSeconds": 55,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 38,
      "LongitudeDirection": "W",
      "Altitude": 37,
      "LatitudeDecimal": 40.082,
      "LongitudeDecimal": -75.011
    },
    {
      "ICAO": "KPNM",
      "IATA": "PNM",
      "Airport": "PRINCETON MUNI",
      "City": "PRINCETON",
      "Country": "USA",
      "LatitudeDegrees": 45,
      "LatitudeMinutes": 33,
      "LatitudeSeconds": 35,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 29,
      "LongitudeDirection": "W",
      "Altitude": 299,
      "LatitudeDecimal": 45.56,
      "LongitudeDecimal": -93.608
    },
    {
      "ICAO": "KPNS",
      "IATA": "PNS",
      "Airport": "PENSACOLA RGNL",
      "City": "PENSACOLA",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 28,
      "LatitudeSeconds": 23,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 11,
      "LongitudeSeconds": 14,
      "LongitudeDirection": "W",
      "Altitude": 37,
      "LatitudeDecimal": 30.473,
      "LongitudeDecimal": -87.187
    },
    {
      "ICAO": "KPOB",
      "IATA": "POB",
      "Airport": "POPE AFB",
      "City": "FAYETTEVILLE",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 10,
      "LatitudeSeconds": 15,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 79,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 52,
      "LongitudeDirection": "W",
      "Altitude": 67,
      "LatitudeDecimal": 35.171,
      "LongitudeDecimal": -79.014
    },
    {
      "ICAO": "KPOE",
      "IATA": "POE",
      "Airport": "POLK AAF",
      "City": "FORT POLK",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 2,
      "LatitudeSeconds": 41,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 11,
      "LongitudeSeconds": 29,
      "LongitudeDirection": "W",
      "Altitude": 101,
      "LatitudeDecimal": 31.045,
      "LongitudeDecimal": -93.191
    },
    {
      "ICAO": "KPQI",
      "IATA": "PQI",
      "Airport": "NORTHERN MAINE RGNL AT PRESQUE ISLE",
      "City": "PRESQUE ISLE",
      "Country": "USA",
      "LatitudeDegrees": 46,
      "LatitudeMinutes": 41,
      "LatitudeSeconds": 20,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 68,
      "LongitudeMinutes": 2,
      "LongitudeSeconds": 41,
      "LongitudeDirection": "W",
      "Altitude": 163,
      "LatitudeDecimal": 46.689,
      "LongitudeDecimal": -68.045
    },
    {
      "ICAO": "KPRC",
      "IATA": "PRC",
      "Airport": "ERNEST A LOVE FLD",
      "City": "PRESCOTT",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 39,
      "LatitudeSeconds": 16,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 112,
      "LongitudeMinutes": 25,
      "LongitudeSeconds": 10,
      "LongitudeDirection": "W",
      "Altitude": 1538,
      "LatitudeDecimal": 34.654,
      "LongitudeDecimal": -112.419
    },
    {
      "ICAO": "KPSP",
      "IATA": "PSP",
      "Airport": "PALM SPRINGS INTERNATIONAL",
      "City": "PALM SPRINGS",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 49,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 116,
      "LongitudeMinutes": 30,
      "LongitudeSeconds": 24,
      "LongitudeDirection": "W",
      "Altitude": 145,
      "LatitudeDecimal": 33.829,
      "LongitudeDecimal": -116.507
    },
    {
      "ICAO": "KPSX",
      "IATA": "PSX",
      "Airport": "PALACIOS MUNI",
      "City": "PALACIOS",
      "Country": "USA",
      "LatitudeDegrees": 28,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 39,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 96,
      "LongitudeMinutes": 15,
      "LongitudeSeconds": 3,
      "LongitudeDirection": "W",
      "Altitude": 5,
      "LatitudeDecimal": 28.727,
      "LongitudeDecimal": -96.251
    },
    {
      "ICAO": "KPUB",
      "IATA": "PUB",
      "Airport": "PUEBLO MEM",
      "City": "PUEBLO MEMORIAL",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 17,
      "LatitudeSeconds": 20,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 104,
      "LongitudeMinutes": 29,
      "LongitudeSeconds": 47,
      "LongitudeDirection": "W",
      "Altitude": 1441,
      "LatitudeDecimal": 38.289,
      "LongitudeDecimal": -104.496
    },
    {
      "ICAO": "KPVD",
      "IATA": "PVD",
      "Airport": "THEODORE FRANCIS GREEN STATE",
      "City": "PROVIDENCE",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 26,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 71,
      "LongitudeMinutes": 25,
      "LongitudeSeconds": 41,
      "LongitudeDirection": "W",
      "Altitude": 17,
      "LatitudeDecimal": 41.724,
      "LongitudeDecimal": -71.428
    },
    {
      "ICAO": "KPWM",
      "IATA": "PWM",
      "Airport": "PORTLAND INTERNATIONAL JETPORT",
      "City": "PORTLAND",
      "Country": "USA",
      "LatitudeDegrees": 43,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 70,
      "LongitudeMinutes": 18,
      "LongitudeSeconds": 31,
      "LongitudeDirection": "W",
      "Altitude": 23,
      "LatitudeDecimal": 43.646,
      "LongitudeDecimal": -70.309
    },
    {
      "ICAO": "KRAL",
      "IATA": "RAL",
      "Airport": "RIVERSIDE MUNI",
      "City": "RIVERSIDE",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 57,
      "LatitudeSeconds": 6,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 26,
      "LongitudeSeconds": 42,
      "LongitudeDirection": "W",
      "Altitude": 250,
      "LatitudeDecimal": 33.952,
      "LongitudeDecimal": -117.445
    },
    {
      "ICAO": "KRBM",
      "IATA": "RBM",
      "Airport": "ROBINSON AAF",
      "City": "ROBINSON",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 51,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 18,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "W",
      "Altitude": 179,
      "LatitudeDecimal": 34.85,
      "LongitudeDecimal": -92.3
    },
    {
      "ICAO": "KRCA",
      "IATA": "RCA",
      "Airport": "ELLSWORTH AFB",
      "City": "RAPID CITY",
      "Country": "USA",
      "LatitudeDegrees": 44,
      "LatitudeMinutes": 8,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 103,
      "LongitudeMinutes": 6,
      "LongitudeSeconds": 12,
      "LongitudeDirection": "W",
      "Altitude": 1000,
      "LatitudeDecimal": 44.145,
      "LongitudeDecimal": -103.103
    },
    {
      "ICAO": "KRDR",
      "IATA": "RDR",
      "Airport": "GRAND FORKS AFB",
      "City": "RED RIVER",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 57,
      "LatitudeSeconds": 39,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 278,
      "LatitudeDecimal": 47.961,
      "LongitudeDecimal": -97.401
    },
    {
      "ICAO": "KRDU",
      "IATA": "RDU",
      "Airport": "RALEIGH DURHAM INTERNATIONAL",
      "City": "RALEIGH-DURHAM",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 52,
      "LatitudeSeconds": 39,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 78,
      "LongitudeMinutes": 47,
      "LongitudeSeconds": 14,
      "LongitudeDirection": "W",
      "Altitude": 133,
      "LatitudeDecimal": 35.877,
      "LongitudeDecimal": -78.787
    },
    {
      "ICAO": "KREE",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "LUBBOCK",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KRIC",
      "IATA": "RIC",
      "Airport": "RICHMOND INTERNATIONAL",
      "City": "RICHMOND",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 30,
      "LatitudeSeconds": 18,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 77,
      "LongitudeMinutes": 19,
      "LongitudeSeconds": 10,
      "LongitudeDirection": "W",
      "Altitude": 51,
      "LatitudeDecimal": 37.505,
      "LongitudeDecimal": -77.319
    },
    {
      "ICAO": "KRIU",
      "IATA": "RIU",
      "Airport": "RANCHO MURIETA",
      "City": "RANCHO MURIETA",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 29,
      "LatitudeSeconds": 19,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 121,
      "LongitudeMinutes": 6,
      "LongitudeSeconds": 8,
      "LongitudeDirection": "W",
      "Altitude": 43,
      "LatitudeDecimal": 38.489,
      "LongitudeDecimal": -121.102
    },
    {
      "ICAO": "KRIV",
      "IATA": "RIV",
      "Airport": "MARCH ARB",
      "City": "RIVERSIDE",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 52,
      "LatitudeSeconds": 50,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 15,
      "LongitudeSeconds": 34,
      "LongitudeDirection": "W",
      "Altitude": 468,
      "LatitudeDecimal": 33.881,
      "LongitudeDecimal": -117.259
    },
    {
      "ICAO": "KRME",
      "IATA": "RME",
      "Airport": "GRIFFISS AIRPARK",
      "City": "ROME",
      "Country": "USA",
      "LatitudeDegrees": 43,
      "LatitudeMinutes": 14,
      "LatitudeSeconds": 1,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 25,
      "LongitudeDirection": "W",
      "Altitude": 154,
      "LatitudeDecimal": 43.234,
      "LongitudeDecimal": -75.407
    },
    {
      "ICAO": "KRND",
      "IATA": "RND",
      "Airport": "RANDOLPH AFB",
      "City": "SAN ANTONIO",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 31,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 98,
      "LongitudeMinutes": 16,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 233,
      "LatitudeDecimal": 29.529,
      "LongitudeDecimal": -98.279
    },
    {
      "ICAO": "KRNO",
      "IATA": "RNO",
      "Airport": "RENO TAHOE INTERNATIONAL",
      "City": "RENO",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 29,
      "LatitudeSeconds": 54,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 119,
      "LongitudeMinutes": 46,
      "LongitudeSeconds": 5,
      "LongitudeDirection": "W",
      "Altitude": 1345,
      "LatitudeDecimal": 39.498,
      "LongitudeDecimal": -119.768
    },
    {
      "ICAO": "KROC",
      "IATA": "ROC",
      "Airport": "GREATER ROCHESTER INTERNATIONAL",
      "City": "ROCHESTER",
      "Country": "USA",
      "LatitudeDegrees": 43,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 7,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 77,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 20,
      "LongitudeDirection": "W",
      "Altitude": 171,
      "LatitudeDecimal": 43.119,
      "LongitudeDecimal": -77.672
    },
    {
      "ICAO": "KROW",
      "IATA": "ROW",
      "Airport": "ROSWELL INDUSTRIAL AIR CENTER",
      "City": "ROSWELL",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 18,
      "LatitudeSeconds": 5,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 104,
      "LongitudeMinutes": 31,
      "LongitudeSeconds": 50,
      "LongitudeDirection": "W",
      "Altitude": 1119,
      "LatitudeDecimal": 33.301,
      "LongitudeDecimal": -104.531
    },
    {
      "ICAO": "KRSW",
      "IATA": "RSW",
      "Airport": "SOUTHWEST FLORIDA INTERNATIONAL",
      "City": "FORT MYERS",
      "Country": "USA",
      "LatitudeDegrees": 26,
      "LatitudeMinutes": 32,
      "LatitudeSeconds": 10,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 45,
      "LongitudeSeconds": 18,
      "LongitudeDirection": "W",
      "Altitude": 10,
      "LatitudeDecimal": 26.536,
      "LongitudeDecimal": -81.755
    },
    {
      "ICAO": "KSAC",
      "IATA": "SAC",
      "Airport": "SACRAMENTO EXECUTIVE",
      "City": "SACRAMENTO",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 30,
      "LatitudeSeconds": 45,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 121,
      "LongitudeMinutes": 29,
      "LongitudeSeconds": 36,
      "LongitudeDirection": "W",
      "Altitude": 8,
      "LatitudeDecimal": 38.513,
      "LongitudeDecimal": -121.493
    },
    {
      "ICAO": "KSAF",
      "IATA": "SAF",
      "Airport": "SANTA FE MUNI",
      "City": "SANTA FE",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 37,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 106,
      "LongitudeMinutes": 5,
      "LongitudeSeconds": 17,
      "LongitudeDirection": "W",
      "Altitude": 1935,
      "LatitudeDecimal": 35.617,
      "LongitudeDecimal": -106.088
    },
    {
      "ICAO": "KSAN",
      "IATA": "SAN",
      "Airport": "SAN DIEGO INTERNATIONAL LINDBERGH FLD",
      "City": "SAN DIEGO",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 44,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 11,
      "LongitudeSeconds": 22,
      "LongitudeDirection": "W",
      "Altitude": 5,
      "LatitudeDecimal": 32.733,
      "LongitudeDecimal": -117.189
    },
    {
      "ICAO": "KSAT",
      "IATA": "SAT",
      "Airport": "SAN ANTONIO INTERNATIONAL",
      "City": "SAN ANTONIO",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 32,
      "LatitudeSeconds": 1,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 98,
      "LongitudeMinutes": 28,
      "LongitudeSeconds": 11,
      "LongitudeDirection": "W",
      "Altitude": 247,
      "LatitudeDecimal": 29.534,
      "LongitudeDecimal": -98.47
    },
    {
      "ICAO": "KSAV",
      "IATA": "SAV",
      "Airport": "SAVANNAH HILTON HEAD INTERNATIONAL",
      "City": "SAVANNAH",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 7,
      "LatitudeSeconds": 39,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 12,
      "LongitudeSeconds": 7,
      "LongitudeDirection": "W",
      "Altitude": 16,
      "LatitudeDecimal": 32.127,
      "LongitudeDecimal": -81.202
    },
    {
      "ICAO": "KSAW",
      "IATA": "N/A",
      "Airport": "SAWYER INTERNATIONAL",
      "City": "GWINN",
      "Country": "USA",
      "LatitudeDegrees": 46,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 13,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 23,
      "LongitudeSeconds": 45,
      "LongitudeDirection": "W",
      "Altitude": 373,
      "LatitudeDecimal": 46.354,
      "LongitudeDecimal": -87.396
    },
    {
      "ICAO": "KSBO",
      "IATA": "SBO",
      "Airport": "EMANUEL CO",
      "City": "SANTA BARBARA",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 36,
      "LatitudeSeconds": 30,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 22,
      "LongitudeSeconds": 7,
      "LongitudeDirection": "W",
      "Altitude": 100,
      "LatitudeDecimal": 32.608,
      "LongitudeDecimal": -82.369
    },
    {
      "ICAO": "KSBY",
      "IATA": "SBY",
      "Airport": "SALISBURY OCEAN CITY WICOMICO RGNL",
      "City": "SALISBURY",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 20,
      "LatitudeSeconds": 25,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 30,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 16,
      "LatitudeDecimal": 38.34,
      "LongitudeDecimal": -75.51
    },
    {
      "ICAO": "KSCI",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SAN CLEMENTE",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KSCK",
      "IATA": "SCK",
      "Airport": "STOCKTON METROPOLITAN",
      "City": "STOCKTON",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 53,
      "LatitudeSeconds": 39,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 121,
      "LongitudeMinutes": 14,
      "LongitudeSeconds": 19,
      "LongitudeDirection": "W",
      "Altitude": 10,
      "LatitudeDecimal": 37.894,
      "LongitudeDecimal": -121.239
    },
    {
      "ICAO": "KSEA",
      "IATA": "SEA",
      "Airport": "SEATTLE TACOMA INTERNATIONAL",
      "City": "SEATTLE",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 26,
      "LatitudeSeconds": 56,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 18,
      "LongitudeSeconds": 33,
      "LongitudeDirection": "W",
      "Altitude": 131,
      "LatitudeDecimal": 47.449,
      "LongitudeDecimal": -122.309
    },
    {
      "ICAO": "KSEM",
      "IATA": "SEM",
      "Airport": "CRAIG FLD",
      "City": "SELMA",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 20,
      "LatitudeSeconds": 38,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 86,
      "LongitudeMinutes": 59,
      "LongitudeSeconds": 16,
      "LongitudeDirection": "W",
      "Altitude": 51,
      "LatitudeDecimal": 32.344,
      "LongitudeDecimal": -86.988
    },
    {
      "ICAO": "KSES",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SELMA",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KSFF",
      "IATA": "SFF",
      "Airport": "FELTS FLD",
      "City": "SPOKANE",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 58,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 19,
      "LongitudeSeconds": 21,
      "LongitudeDirection": "W",
      "Altitude": 596,
      "LatitudeDecimal": 47.683,
      "LongitudeDecimal": -117.322
    },
    {
      "ICAO": "KSFO",
      "IATA": "SFO",
      "Airport": "SAN FRANCISCO INTERNATIONAL",
      "City": "SAN FRANCISCO",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 37,
      "LatitudeSeconds": 8,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 22,
      "LongitudeSeconds": 29,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 37.619,
      "LongitudeDecimal": -122.375
    },
    {
      "ICAO": "KSFS",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SAN FRANCISCO",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KSFZ",
      "IATA": "SFZ",
      "Airport": "NORTH CENTRAL STATE",
      "City": "SMITHFIELD",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 55,
      "LatitudeSeconds": 14,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 71,
      "LongitudeMinutes": 29,
      "LongitudeSeconds": 28,
      "LongitudeDirection": "W",
      "Altitude": 135,
      "LatitudeDecimal": 41.921,
      "LongitudeDecimal": -71.491
    },
    {
      "ICAO": "KSHV",
      "IATA": "SHV",
      "Airport": "SHREVEPORT RGNL",
      "City": "SHREVEPORT",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 26,
      "LatitudeSeconds": 47,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 49,
      "LongitudeSeconds": 32,
      "LongitudeDirection": "W",
      "Altitude": 79,
      "LatitudeDecimal": 32.446,
      "LongitudeDecimal": -93.826
    },
    {
      "ICAO": "KSJC",
      "IATA": "SJC",
      "Airport": "NORMAN Y MINETA SAN JOSE INTERNATIONAL",
      "City": "SAN JOSE",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 121,
      "LongitudeMinutes": 55,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 18,
      "LatitudeDecimal": 37.362,
      "LongitudeDecimal": -121.929
    },
    {
      "ICAO": "KSJT",
      "IATA": "SJT",
      "Airport": "SAN ANGELO RGNL MATHIS FLD",
      "City": "SAN ANGELO",
      "Country": "USA",
      "LatitudeDegrees": 31,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 27,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 100,
      "LongitudeMinutes": 29,
      "LongitudeSeconds": 46,
      "LongitudeDirection": "W",
      "Altitude": 585,
      "LatitudeDecimal": 31.358,
      "LongitudeDecimal": -100.496
    },
    {
      "ICAO": "KSKA",
      "IATA": "SKA",
      "Airport": "FAIRCHILD AFB",
      "City": "SPOKANE",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 36,
      "LatitudeSeconds": 54,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 39,
      "LongitudeSeconds": 20,
      "LongitudeDirection": "W",
      "Altitude": 751,
      "LatitudeDecimal": 47.615,
      "LongitudeDecimal": -117.656
    },
    {
      "ICAO": "KSKF",
      "IATA": "SKF",
      "Airport": "LACKLAND AFB KELLY FLD ANNEX",
      "City": "SAN ANTONIO",
      "Country": "USA",
      "LatitudeDegrees": 29,
      "LatitudeMinutes": 23,
      "LatitudeSeconds": 3,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 98,
      "LongitudeMinutes": 34,
      "LongitudeSeconds": 51,
      "LongitudeDirection": "W",
      "Altitude": 211,
      "LatitudeDecimal": 29.384,
      "LongitudeDecimal": -98.581
    },
    {
      "ICAO": "KSKY",
      "IATA": "SKY",
      "Airport": "GRIFFING SANDUSKY",
      "City": "SANDUSKY",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 26,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 39,
      "LongitudeSeconds": 8,
      "LongitudeDirection": "W",
      "Altitude": 177,
      "LatitudeDecimal": 41.433,
      "LongitudeDecimal": -82.652
    },
    {
      "ICAO": "KSLC",
      "IATA": "SLC",
      "Airport": "SALT LAKE CITY INTERNATIONAL",
      "City": "SALT LAKE CITY",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 47,
      "LatitudeSeconds": 18,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 111,
      "LongitudeMinutes": 58,
      "LongitudeSeconds": 39,
      "LongitudeDirection": "W",
      "Altitude": 1289,
      "LatitudeDecimal": 40.788,
      "LongitudeDecimal": -111.978
    },
    {
      "ICAO": "KSMF",
      "IATA": "SMF",
      "Airport": "SACRAMENTO INTERNATIONAL",
      "City": "SACRAMENTO",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 41,
      "LatitudeSeconds": 43,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 121,
      "LongitudeMinutes": 35,
      "LongitudeSeconds": 26,
      "LongitudeDirection": "W",
      "Altitude": 9,
      "LatitudeDecimal": 38.695,
      "LongitudeDecimal": -121.591
    },
    {
      "ICAO": "KSNA",
      "IATA": "SNA",
      "Airport": "JOHN WAYNE ARPT ORANGE CO",
      "City": "SANTA ANA",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 40,
      "LatitudeSeconds": 32,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 52,
      "LongitudeSeconds": 5,
      "LongitudeDirection": "W",
      "Altitude": 18,
      "LatitudeDecimal": 33.676,
      "LongitudeDecimal": -117.868
    },
    {
      "ICAO": "KSPB",
      "IATA": "SPB",
      "Airport": "SCAPPOOSE INDUSTRIAL AIRPARK",
      "City": "SAN LUIS",
      "Country": "USA",
      "LatitudeDegrees": 45,
      "LatitudeMinutes": 46,
      "LatitudeSeconds": 21,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 51,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 18,
      "LatitudeDecimal": 45.773,
      "LongitudeDecimal": -122.862
    },
    {
      "ICAO": "KSPG",
      "IATA": "SPG",
      "Airport": "ALBERT WHITTED",
      "City": "ST. PETERSBURG",
      "Country": "USA",
      "LatitudeDegrees": 27,
      "LatitudeMinutes": 45,
      "LatitudeSeconds": 54,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 37,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 3,
      "LatitudeDecimal": 27.765,
      "LongitudeDecimal": -82.627
    },
    {
      "ICAO": "KSPS",
      "IATA": "SPS",
      "Airport": "SHEPPARD AFB WICHITA FALLS MUNI",
      "City": "WICHITA FALLS",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 59,
      "LatitudeSeconds": 19,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 98,
      "LongitudeMinutes": 29,
      "LongitudeSeconds": 30,
      "LongitudeDirection": "W",
      "Altitude": 311,
      "LatitudeDecimal": 33.989,
      "LongitudeDecimal": -98.492
    },
    {
      "ICAO": "KSSC",
      "IATA": "SSC",
      "Airport": "SHAW AFB",
      "City": "SUMTER",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 58,
      "LatitudeSeconds": 22,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 28,
      "LongitudeSeconds": 22,
      "LongitudeDirection": "W",
      "Altitude": 74,
      "LatitudeDecimal": 33.973,
      "LongitudeDecimal": -80.473
    },
    {
      "ICAO": "KSSM",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SAULT SAINTE MARIE",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KSTL",
      "IATA": "STL",
      "Airport": "LAMBERT ST LOUIS INTERNATIONAL",
      "City": "ST. LOUIS",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 44,
      "LatitudeSeconds": 51,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 90,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 35,
      "LongitudeDirection": "W",
      "Altitude": 185,
      "LatitudeDecimal": 38.748,
      "LongitudeDecimal": -90.36
    },
    {
      "ICAO": "KSUU",
      "IATA": "SUU",
      "Airport": "TRAVIS AFB",
      "City": "FAIRFIELD",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 15,
      "LatitudeSeconds": 45,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 121,
      "LongitudeMinutes": 55,
      "LongitudeSeconds": 38,
      "LongitudeDirection": "W",
      "Altitude": 19,
      "LatitudeDecimal": 38.263,
      "LongitudeDecimal": -121.927
    },
    {
      "ICAO": "KSUX",
      "IATA": "SUX",
      "Airport": "SIOUX GATEWAY COL BUD DAY FLD",
      "City": "SIOUX CITY",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 24,
      "LatitudeSeconds": 9,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 96,
      "LongitudeMinutes": 23,
      "LongitudeSeconds": 3,
      "LongitudeDirection": "W",
      "Altitude": 335,
      "LatitudeDecimal": 42.402,
      "LongitudeDecimal": -96.384
    },
    {
      "ICAO": "KSVN",
      "IATA": "SVN",
      "Airport": "HUNTER AAF",
      "City": "HUNTER AAF",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 36,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 81,
      "LongitudeMinutes": 8,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 13,
      "LatitudeDecimal": 32.01,
      "LongitudeDecimal": -81.146
    },
    {
      "ICAO": "KSWA",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SWAN ISLAND",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KSWF",
      "IATA": "SWF",
      "Airport": "STEWART INTERNATIONAL",
      "City": "NEWBURGH",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 30,
      "LatitudeSeconds": 14,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 74,
      "LongitudeMinutes": 6,
      "LongitudeSeconds": 17,
      "LongitudeDirection": "W",
      "Altitude": 150,
      "LatitudeDecimal": 41.504,
      "LongitudeDecimal": -74.105
    },
    {
      "ICAO": "KSYM",
      "IATA": "N/A",
      "Airport": "N/A",
      "City": "SMYRNA",
      "Country": "USA",
      "LatitudeDegrees": 0,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "U",
      "LongitudeDegrees": 0,
      "LongitudeMinutes": 0,
      "LongitudeSeconds": 0,
      "LongitudeDirection": "U",
      "Altitude": 0,
      "LatitudeDecimal": 0,
      "LongitudeDecimal": 0
    },
    {
      "ICAO": "KSYR",
      "IATA": "SYR",
      "Airport": "SYRACUSE HANCOCK INTERNATIONAL",
      "City": "SYRACUSE",
      "Country": "USA",
      "LatitudeDegrees": 43,
      "LatitudeMinutes": 6,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 76,
      "LongitudeMinutes": 6,
      "LongitudeSeconds": 22,
      "LongitudeDirection": "W",
      "Altitude": 129,
      "LatitudeDecimal": 43.111,
      "LongitudeDecimal": -76.106
    },
    {
      "ICAO": "KSZL",
      "IATA": "SZL",
      "Airport": "WHITEMAN AFB",
      "City": "KNOBNOSTER",
      "Country": "USA",
      "LatitudeDegrees": 38,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 49,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 32,
      "LongitudeSeconds": 52,
      "LongitudeDirection": "W",
      "Altitude": 266,
      "LatitudeDecimal": 38.73,
      "LongitudeDecimal": -93.548
    },
    {
      "ICAO": "KTBN",
      "IATA": "TBN",
      "Airport": "WAYNESVILLE RGNL ARPT AT FORNEY FLD",
      "City": "FORT LEONARDWOOD",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 44,
      "LatitudeSeconds": 29,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 92,
      "LongitudeMinutes": 8,
      "LongitudeSeconds": 26,
      "LongitudeDirection": "W",
      "Altitude": 354,
      "LatitudeDecimal": 37.741,
      "LongitudeDecimal": -92.141
    },
    {
      "ICAO": "KTCC",
      "IATA": "TCC",
      "Airport": "TUCUMCARI MUNI",
      "City": "TUCUMCARI",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 10,
      "LatitudeSeconds": 58,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 103,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 11,
      "LongitudeDirection": "W",
      "Altitude": 1240,
      "LatitudeDecimal": 35.183,
      "LongitudeDecimal": -103.603
    },
    {
      "ICAO": "KTCM",
      "IATA": "TCM",
      "Airport": "MC CHORD AFB",
      "City": "TACOMA",
      "Country": "USA",
      "LatitudeDegrees": 47,
      "LatitudeMinutes": 8,
      "LatitudeSeconds": 15,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 122,
      "LongitudeMinutes": 28,
      "LongitudeSeconds": 35,
      "LongitudeDirection": "W",
      "Altitude": 99,
      "LatitudeDecimal": 47.138,
      "LongitudeDecimal": -122.476
    },
    {
      "ICAO": "KTCS",
      "IATA": "TCS",
      "Airport": "TRUTH OR CONSEQUENCES MUNI",
      "City": "TRUTH OR CONSEQUENCES",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 14,
      "LatitudeSeconds": 13,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 107,
      "LongitudeMinutes": 16,
      "LongitudeSeconds": 18,
      "LongitudeDirection": "W",
      "Altitude": 1480,
      "LatitudeDecimal": 33.237,
      "LongitudeDecimal": -107.272
    },
    {
      "ICAO": "KTEB",
      "IATA": "TEB",
      "Airport": "TETERBORO",
      "City": "TETERBORO",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 50,
      "LatitudeSeconds": 59,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 74,
      "LongitudeMinutes": 3,
      "LongitudeSeconds": 39,
      "LongitudeDirection": "W",
      "Altitude": 3,
      "LatitudeDecimal": 40.85,
      "LongitudeDecimal": -74.061
    },
    {
      "ICAO": "KTIK",
      "IATA": "TIK",
      "Airport": "TINKER AFB",
      "City": "OKLAHOMA CITY",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 24,
      "LatitudeSeconds": 53,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 97,
      "LongitudeMinutes": 23,
      "LongitudeSeconds": 11,
      "LongitudeDirection": "W",
      "Altitude": 394,
      "LatitudeDecimal": 35.415,
      "LongitudeDecimal": -97.386
    },
    {
      "ICAO": "KTLH",
      "IATA": "TLH",
      "Airport": "TALLAHASSEE RGNL",
      "City": "TALLAHASSEE",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 23,
      "LatitudeSeconds": 47,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 84,
      "LongitudeMinutes": 21,
      "LongitudeSeconds": 1,
      "LongitudeDirection": "W",
      "Altitude": 25,
      "LatitudeDecimal": 30.396,
      "LongitudeDecimal": -84.35
    },
    {
      "ICAO": "KTMB",
      "IATA": "TMB",
      "Airport": "KENDALL TAMIAMI EXECUTIVE",
      "City": "KENDALL-TAMIAMI",
      "Country": "USA",
      "LatitudeDegrees": 25,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 52,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 25,
      "LongitudeSeconds": 58,
      "LongitudeDirection": "W",
      "Altitude": 3,
      "LatitudeDecimal": 25.648,
      "LongitudeDecimal": -80.433
    },
    {
      "ICAO": "KTNT",
      "IATA": "TNT",
      "Airport": "DADE COLLIER TRAINING AND TRANSITION",
      "City": "MIAMI",
      "Country": "USA",
      "LatitudeDegrees": 25,
      "LatitudeMinutes": 51,
      "LatitudeSeconds": 42,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 53,
      "LongitudeSeconds": 49,
      "LongitudeDirection": "W",
      "Altitude": 4,
      "LatitudeDecimal": 25.862,
      "LongitudeDecimal": -80.897
    },
    {
      "ICAO": "KTNX",
      "IATA": "N/A",
      "Airport": "TONOPAH TEST RANGE",
      "City": "TONOPAH",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 47,
      "LatitudeSeconds": 40,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 116,
      "LongitudeMinutes": 46,
      "LongitudeSeconds": 43,
      "LongitudeDirection": "W",
      "Altitude": 1692,
      "LatitudeDecimal": 37.794,
      "LongitudeDecimal": -116.779
    },
    {
      "ICAO": "KTPA",
      "IATA": "TPA",
      "Airport": "TAMPA INTERNATIONAL",
      "City": "TAMPA",
      "Country": "USA",
      "LatitudeDegrees": 27,
      "LatitudeMinutes": 58,
      "LatitudeSeconds": 31,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 82,
      "LongitudeMinutes": 31,
      "LongitudeSeconds": 59,
      "LongitudeDirection": "W",
      "Altitude": 8,
      "LatitudeDecimal": 27.975,
      "LongitudeDecimal": -82.533
    },
    {
      "ICAO": "KTTN",
      "IATA": "TTN",
      "Airport": "TRENTON MERCER",
      "City": "TRENTON",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 16,
      "LatitudeSeconds": 36,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 74,
      "LongitudeMinutes": 48,
      "LongitudeSeconds": 48,
      "LongitudeDirection": "W",
      "Altitude": 65,
      "LatitudeDecimal": 40.277,
      "LongitudeDecimal": -74.813
    },
    {
      "ICAO": "KTUL",
      "IATA": "TUL",
      "Airport": "TULSA INTERNATIONAL",
      "City": "TULSA",
      "Country": "USA",
      "LatitudeDegrees": 36,
      "LatitudeMinutes": 11,
      "LatitudeSeconds": 54,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 53,
      "LongitudeSeconds": 17,
      "LongitudeDirection": "W",
      "Altitude": 207,
      "LatitudeDecimal": 36.198,
      "LongitudeDecimal": -95.888
    },
    {
      "ICAO": "KTUS",
      "IATA": "TUS",
      "Airport": "TUCSON INTERNATIONAL",
      "City": "TUCSON",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 6,
      "LatitudeSeconds": 58,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 110,
      "LongitudeMinutes": 56,
      "LongitudeSeconds": 29,
      "LongitudeDirection": "W",
      "Altitude": 806,
      "LatitudeDecimal": 32.116,
      "LongitudeDecimal": -110.941
    },
    {
      "ICAO": "KTXK",
      "IATA": "TXK",
      "Airport": "TEXARKANA RGNL WEBB FLD",
      "City": "TEXARKANA",
      "Country": "USA",
      "LatitudeDegrees": 33,
      "LatitudeMinutes": 27,
      "LatitudeSeconds": 13,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 93,
      "LongitudeMinutes": 59,
      "LongitudeSeconds": 27,
      "LongitudeDirection": "W",
      "Altitude": 119,
      "LatitudeDecimal": 33.454,
      "LongitudeDecimal": -93.991
    },
    {
      "ICAO": "KTYR",
      "IATA": "TYR",
      "Airport": "TYLER POUNDS RGNL",
      "City": "TYLER",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 21,
      "LatitudeSeconds": 14,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 95,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 8,
      "LongitudeDirection": "W",
      "Altitude": 166,
      "LatitudeDecimal": 32.354,
      "LongitudeDecimal": -95.402
    },
    {
      "ICAO": "KTYS",
      "IATA": "TYS",
      "Airport": "MC GHEE TYSON",
      "City": "KNOXVILLE",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 48,
      "LatitudeSeconds": 44,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 83,
      "LongitudeMinutes": 59,
      "LongitudeSeconds": 34,
      "LongitudeDirection": "W",
      "Altitude": 300,
      "LatitudeDecimal": 35.812,
      "LongitudeDecimal": -83.993
    },
    {
      "ICAO": "KUGN",
      "IATA": "UGN",
      "Airport": "WAUKEGAN RGNL",
      "City": "CHICAGO",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 25,
      "LatitudeSeconds": 19,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 87,
      "LongitudeMinutes": 52,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 222,
      "LatitudeDecimal": 42.422,
      "LongitudeDecimal": -87.868
    },
    {
      "ICAO": "KVAD",
      "IATA": "VAD",
      "Airport": "MOODY AFB",
      "City": "VALDOSTA",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 58,
      "LatitudeSeconds": 4,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 83,
      "LongitudeMinutes": 11,
      "LongitudeSeconds": 34,
      "LongitudeDirection": "W",
      "Altitude": 72,
      "LatitudeDecimal": 30.968,
      "LongitudeDecimal": -83.193
    },
    {
      "ICAO": "KVBG",
      "IATA": "VBG",
      "Airport": "VANDENBERG AFB",
      "City": "LOMPOC",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 43,
      "LatitudeSeconds": 46,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 120,
      "LongitudeMinutes": 34,
      "LongitudeSeconds": 36,
      "LongitudeDirection": "W",
      "Altitude": 112,
      "LatitudeDecimal": 34.729,
      "LongitudeDecimal": -120.577
    },
    {
      "ICAO": "KVCV",
      "IATA": "VCV",
      "Airport": "SOUTHERN CALIFORNIA LOGISTICS",
      "City": "VICTORVILLE",
      "Country": "USA",
      "LatitudeDegrees": 34,
      "LatitudeMinutes": 35,
      "LatitudeSeconds": 35,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 117,
      "LongitudeMinutes": 22,
      "LongitudeSeconds": 46,
      "LongitudeDirection": "W",
      "Altitude": 880,
      "LatitudeDecimal": 34.593,
      "LongitudeDecimal": -117.379
    },
    {
      "ICAO": "KVPS",
      "IATA": "VPS",
      "Airport": "EGLIN AFB",
      "City": "VALPARAISO",
      "Country": "USA",
      "LatitudeDegrees": 30,
      "LatitudeMinutes": 28,
      "LatitudeSeconds": 59,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 86,
      "LongitudeMinutes": 31,
      "LongitudeSeconds": 31,
      "LongitudeDirection": "W",
      "Altitude": 27,
      "LatitudeDecimal": 30.483,
      "LongitudeDecimal": -86.525
    },
    {
      "ICAO": "KVRB",
      "IATA": "VRB",
      "Airport": "VERO BEACH MUNI",
      "City": "VERO BEACH",
      "Country": "USA",
      "LatitudeDegrees": 27,
      "LatitudeMinutes": 39,
      "LatitudeSeconds": 20,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 25,
      "LongitudeSeconds": 4,
      "LongitudeDirection": "W",
      "Altitude": 8,
      "LatitudeDecimal": 27.656,
      "LongitudeDecimal": -80.418
    },
    {
      "ICAO": "KWAL",
      "IATA": "WAL",
      "Airport": "WALLOPS FLIGHT FACILITY",
      "City": "WALLOPS ISLAND",
      "Country": "USA",
      "LatitudeDegrees": 37,
      "LatitudeMinutes": 56,
      "LatitudeSeconds": 24,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 75,
      "LongitudeMinutes": 27,
      "LongitudeSeconds": 59,
      "LongitudeDirection": "W",
      "Altitude": 13,
      "LatitudeDecimal": 37.94,
      "LongitudeDecimal": -75.466
    },
    {
      "ICAO": "KWRB",
      "IATA": "WRB",
      "Airport": "ROBINS AFB",
      "City": "MACON",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 38,
      "LatitudeSeconds": 24,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 83,
      "LongitudeMinutes": 35,
      "LongitudeSeconds": 30,
      "LongitudeDirection": "W",
      "Altitude": 90,
      "LatitudeDecimal": 32.64,
      "LongitudeDecimal": -83.592
    },
    {
      "ICAO": "KWRI",
      "IATA": "WRI",
      "Airport": "MC GUIRE AFB",
      "City": "WRIGHTSTOWN",
      "Country": "USA",
      "LatitudeDegrees": 40,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 56,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 74,
      "LongitudeMinutes": 35,
      "LongitudeSeconds": 37,
      "LongitudeDirection": "W",
      "Altitude": 41,
      "LatitudeDecimal": 40.016,
      "LongitudeDecimal": -74.594
    },
    {
      "ICAO": "KWSD",
      "IATA": "WSD",
      "Airport": "CONDRON AAF",
      "City": "WHITE SANDS",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 20,
      "LatitudeSeconds": 29,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 106,
      "LongitudeMinutes": 24,
      "LongitudeSeconds": 9,
      "LongitudeDirection": "W",
      "Altitude": 1200,
      "LatitudeDecimal": 32.341,
      "LongitudeDecimal": -106.403
    },
    {
      "ICAO": "KWWD",
      "IATA": "WWD",
      "Airport": "CAPE MAY CO",
      "City": "WILDWOOD",
      "Country": "USA",
      "LatitudeDegrees": 39,
      "LatitudeMinutes": 0,
      "LatitudeSeconds": 30,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 74,
      "LongitudeMinutes": 54,
      "LongitudeSeconds": 29,
      "LongitudeDirection": "W",
      "Altitude": 8,
      "LatitudeDecimal": 39.008,
      "LongitudeDecimal": -74.908
    },
    {
      "ICAO": "KYIP",
      "IATA": "YIP",
      "Airport": "WILLOW RUN",
      "City": "DETROIT",
      "Country": "USA",
      "LatitudeDegrees": 42,
      "LatitudeMinutes": 14,
      "LatitudeSeconds": 16,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 83,
      "LongitudeMinutes": 31,
      "LongitudeSeconds": 49,
      "LongitudeDirection": "W",
      "Altitude": 219,
      "LatitudeDecimal": 42.238,
      "LongitudeDecimal": -83.53
    },
    {
      "ICAO": "KYNG",
      "IATA": "YNG",
      "Airport": "YOUNGSTOWN WARREN RGNL",
      "City": "YOUNGSTOWN",
      "Country": "USA",
      "LatitudeDegrees": 41,
      "LatitudeMinutes": 15,
      "LatitudeSeconds": 38,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 80,
      "LongitudeMinutes": 40,
      "LongitudeSeconds": 44,
      "LongitudeDirection": "W",
      "Altitude": 365,
      "LatitudeDecimal": 41.261,
      "LongitudeDecimal": -80.679
    },
    {
      "ICAO": "KYUM",
      "IATA": "YUM",
      "Airport": "YUMA MCAS YUMA INTERNATIONAL",
      "City": "YUMA",
      "Country": "USA",
      "LatitudeDegrees": 32,
      "LatitudeMinutes": 39,
      "LatitudeSeconds": 23,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 114,
      "LongitudeMinutes": 36,
      "LongitudeSeconds": 21,
      "LongitudeDirection": "W",
      "Altitude": 65,
      "LatitudeDecimal": 32.656,
      "LongitudeDecimal": -114.606
    },
    {
      "ICAO": "KZUN",
      "IATA": "ZUN",
      "Airport": "BLACK ROCK",
      "City": "ZUNI PUEBLO",
      "Country": "USA",
      "LatitudeDegrees": 35,
      "LatitudeMinutes": 5,
      "LatitudeSeconds": 0,
      "LatitudeDirection": "N",
      "LongitudeDegrees": 108,
      "LongitudeMinutes": 47,
      "LongitudeSeconds": 30,
      "LongitudeDirection": "W",
      "Altitude": 1968,
      "LatitudeDecimal": 35.083,
      "LongitudeDecimal": -108.792
    },  ]
  export { airports }