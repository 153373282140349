/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class WhyUs extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <section className="why-us-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <div className="section-title">
                <h2 className="c-white">Who We Serve</h2>
                <p>
                  We connect you to the best FBOs and fuel providers in the
                  industry with our large network of connections
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-why-us-box">
                <div className="icon">
                  <i className="flaticon-transport-1" />
                </div>
                <div className="content">
                  <h4 className="c-white">Corporate Fleets</h4>
                  <p>
                    We have been serving the needs of corporate fleets for
                    decades. Let us leverage that expertise for you. Call us
                    today.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-why-us-box">
                <div className="icon">
                  <i className="flaticon-transport" />
                </div>
                <div className="content">
                  <h4 className="c-white">FBOs</h4>
                  <p>
                    The FBO operators at each ICAO trust our team of experts.
                    That means you get on-time fuel pricing with expert tender
                    management.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-why-us-box">
                <div className="icon">
                  <i className="flaticon-helicopter" />
                </div>
                <div className="content">
                  <h4 className="c-white">Charter Companies</h4>
                  <p>
                    We also know your unique requirements and the customers you
                    serve. By working together we can provide the most efficient
                    solution.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-why-us-box">
                <div className="icon">
                  <i className="flaticon-skydiving" />
                </div>
                <div className="content">
                  <h4 className="c-white">Stakeholders</h4>
                  <p>
                    You are the passenger, so we listen. Whatever your
                    requirements we know that your needs come first... so talk
                    to us today.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default WhyUs;
