/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class FunFacts extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <section className="some-fun-fact-area fun-fact-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <div className="section-title">
                <h2 className="c-white">Some Fun Facts</h2>
                <p>
                  Our extensive portfolio of into-wing suppliers provides a
                  variety of choices with the assurance that your fuel is of the
                  highest quality and will be there on time every time.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 col-sm-6">
              <div className="single-fun-item">
                <div className="icon">
                  <i className="far fa-paper-plane" />
                </div>
                <div className="content">
                  <span className="count-number">12,090</span>
                  <h6 className="title">Project Done</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6">
              <div className="single-fun-item">
                <div className="icon">
                  <i className="far fa-lemon" />
                </div>
                <div className="content">
                  <span className="count-number">10,070</span>
                  <h6 className="title">Cup Of Coffee</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6">
              <div className="single-fun-item">
                <div className="icon">
                  <i className="far fa-smile" />
                </div>
                <div className="content">
                  <span className="count-number">98,090</span>
                  <h6 className="title">Happy Clients</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6">
              <div className="single-fun-item">
                <div className="icon">
                  <i className="far fa-gem" />
                </div>
                <div className="content">
                  <span className="count-number">2,000</span>
                  <h6 className="title">Winning Awards</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default FunFacts;
