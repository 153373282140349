/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class Footer extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="copyright-area">
                © 2019 JetFlyt Inc, All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
