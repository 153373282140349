/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class ServiceArea extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <section className="service-area">
        <div className="service-area-top">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 text-center">
                <div className="section-title">
                  <h2 className="c-white">Our Services</h2>
                  <p>
                    Our extensive portfolio of into-wing suppliers provides a
                    variety of choices with the assurance that your fuel is of
                    the highest quality and will be there on time every time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service-area-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 remove-col-padding">
                <div className="service-bottom-inner">
                  <div className="row">
                    <div className="col-md-6 col-lg-4">
                      <div className="single-service-box">
                        <div className="icon">
                          <i className="flaticon-ticket" />
                        </div>
                        <div className="content">
                          <h4>Flight Ticket</h4>
                          <p>
                            {" "}
                            Our extensive portfolio of into wing suppliers
                            provides a variety of choices with the assurance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="single-service-box">
                        <div className="icon">
                          <i className="flaticon-skydiving-1" />
                        </div>
                        <div className="content">
                          <h4>Sky Diving</h4>
                          <p>
                            {" "}
                            Our extensive portfolio of into wing suppliers
                            provides a variety of choices with the assurance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="single-service-box">
                        <div className="icon">
                          <i className="flaticon-helicopter" />
                        </div>
                        <div className="content">
                          <h4>Helicopter Service</h4>
                          <p>
                            {" "}
                            Our extensive portfolio of into wing suppliers
                            provides a variety of choices with the assurance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="single-service-box">
                        <div className="icon">
                          <i className="flaticon-transport" />
                        </div>
                        <div className="content">
                          <h4>JET Service</h4>
                          <p>
                            {" "}
                            Our extensive portfolio of into wing suppliers
                            provides a variety of choices with the assurance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="single-service-box">
                        <div className="icon">
                          <i className="flaticon-transport-1" />
                        </div>
                        <div className="content">
                          <h4>Turbo Aeroplane</h4>
                          <p>
                            {" "}
                            Our extensive portfolio of into wing suppliers
                            provides a variety of choices with the assurance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="single-service-box">
                        <div className="icon">
                          <i className="flaticon-airplane-1" />
                        </div>
                        <div className="content">
                          <h4>Boeing Services</h4>
                          <p>
                            {" "}
                            Our extensive portfolio of into wing suppliers
                            provides a variety of choices with the assurance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ServiceArea;
