/* eslint-disable */
import React from "react";
import classnames from 'classnames';
import {
	Row,
	Col,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Table,
	Input
} from 'reactstrap';

class FuelTrade extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			activeTab: '1'
		};
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}
	render() {
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-2 [Cryptocurrency]                                           */
			/*--------------------------------------------------------------------------------*/
			<Card>
				<CardBody>
					<div className="d-md-flex align-items-center">
						<div>
							<CardTitle>Purchase History</CardTitle>
							<CardSubtitle>Listing of all transactions</CardSubtitle>
						</div>
						<div className="ml-auto d-flex align-items-center">
							<div className="dl">
								<Input type="select" className="custom-select">
			            <option value="0">March</option>
									<option value="1">April</option>
									<option value="2">May</option>
			          </Input>
							</div>
						</div>
					</div>
					<Nav pills className="custom-pills mt-4">
						<NavItem>
							<NavLink
								className={classnames({ active: this.state.activeTab === '1' })}
								onClick={() => { this.toggle('1'); }}
							>Current</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({ active: this.state.activeTab === '2' })}
								onClick={() => { this.toggle('2'); }}
							>Past History</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={this.state.activeTab} className="mt-3">
						{/*--------------------------------------------------------------------------------*/}
						{/* Tab-1                                                                          */}
						{/*--------------------------------------------------------------------------------*/}
						<TabPane tabId="1">
							<Row>
								<Col sm="12">
									<div>
										<Table className="v-middle" responsive>
											<thead>
												<tr>
													<th className="border-top-0">Price</th>
													<th className="border-top-0">Amount</th>
													<th className="border-top-0">Date</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td><span className="text-success">$12948.89</span> USD</td>
													<td>G <span className="font-medium">512</span></td>
													<td>12/14/2018</td>
												</tr>
												<tr>
													<td><span className="text-danger">$23135.78</span> USD</td>
													<td>G <span className="font-medium">432</span></td>
													<td>12/24/2018</td>
												</tr>
												<tr>
													<td><span className="text-success">$43567.98</span> USD</td>
													<td>G <span className="font-medium">142</span></td>
													<td>01/10/2019</td>
												</tr>
												<tr>
													<td><span className="text-success">$12948.89</span> GBP</td>
													<td>L <span className="font-medium">231</span></td>
													<td>01/17/2019</td>
												</tr>
												<tr>
													<td><span className="text-danger">$23135.78</span> USD</td>
													<td>G <span className="font-medium">321</span></td>
													<td>01/18/2019</td>
												</tr>
												<tr>
													<td><span className="text-success">$43567.98</span> EUR</td>
													<td>L <span className="font-medium">233</span></td>
													<td>02/01/2019</td>
												</tr>
											</tbody>
										</Table>
									</div>
								</Col>
							</Row>
						</TabPane>
						{/*--------------------------------------------------------------------------------*/}
						{/* Tab-2                                                                          */}
						{/*--------------------------------------------------------------------------------*/}
						<TabPane tabId="2">
							<Row>
								<Col sm="12">
									<Table className="v-middle" responsive>
										<thead>
											<tr>
												<th className="border-top-0">Price</th>
												<th className="border-top-0">Amount</th>
												<th className="border-top-0">Date</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><span className="text-success">$43567.98</span> EUR</td>
												<td>L <span className="font-medium">345</span></td>
												<td>12/10/2018</td>
											</tr>
											<tr>
												<td><span className="text-success">$12948.89</span> USD</td>
												<td>G <span className="font-medium">265</span></td>
												<td>08/10/2018</td>
											</tr>
											<tr>
												<td><span className="text-success">$12948.89</span> EUR</td>
												<td>L <span className="font-medium">563</span></td>
												<td>06/01/2018</td>
											</tr>
											<tr>
												<td><span className="text-danger">$23135.78</span> GBP</td>
												<td>L <span className="font-medium">125</span></td>
												<td>04/10/2018</td>
											</tr>
											<tr>
												<td><span className="text-danger">$23135.78</span> USD</td>
												<td>G <span className="font-medium">432</span></td>
												<td>03/10/2018</td>
											</tr>
											<tr>
												<td><span className="text-success">$43567.98</span> USD</td>
												<td>G <span className="font-medium">223</span></td>
												<td>02/14/2018</td>
											</tr>
										</tbody>
									</Table>
								</Col>
							</Row>
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
		);
	}
}

export default FuelTrade;
