import React, { Component } from 'react';
//import moment from 'moment'
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, FormText} from 'reactstrap';
import TextBox from '../../../components/form-components/TextBox';
import { db } from '../../../firebase';

const INITIAL_STATE = {
    key: null,
    id: '',
    icao: '',
    title: '',
    description: '',
    type: 'FBO',
//---------------------
    modal: false,
    error: null,
    mode: 'add',
};

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
})

class FBO extends Component {
    constructor(props) {
        super(props);
        if(this.props.item) {
            this.state = { 
                ...this.props.item,
                mode: this.props.mode,
            };
            
        } else {
            this.state = { 
                ...INITIAL_STATE,
                mode: this.props.mode,
            };
        }        
    }

    toggle = (e) => {
        this.setState({
            ...this.props.item,
            modal: !this.state.modal,
            mode: this.props.mode,
        });
        this.props.handleRefresh();
    }

    handleEditButton = (e) => {
        e.preventDefault();
        this.setState({
            mode: "edit",
        });
    }

    handleCancelButton = (e) => {
        e.preventDefault();
        this.setState({
            ...INITIAL_STATE,
            mode: "view",
        })
        this.toggle()
    }

    handleDeleteButton = (e) => {
        e.preventDefault();
        console.log(this.props.baseObject, this.state.key)
        db.remove(
            this.props.baseObject,
            this.state.key
        )
        .then(this.setState({mode: "view"}))
        .then(this.props.handleRefresh())
        .then(this.toggle())
        .catch(error => {alert(error);});
    }

    handleInputChange = (e) => {
        this.setState(byPropKey(e.target.name, e.target.value));
    }

    handleSaveChanges = (e) => {
        e.preventDefault();
        const item = {
            key: this.state.key,
            id: this.state.id || '',
            icao: this.state.icao || '',
            title: this.state.title || '', 
            description: this.state.description || '',
            type: this.state.type || '',
        };
        db.save(
            this.props.baseObject,
            item,
        )
        .then(this.setState({
            ...INITIAL_STATE,
            mode: "view",
        }))
        .then(this.props.handleRefresh())
        .then(this.toggle())
        .catch(error => {alert(error);});
    }

    render() {

        const {
//            key,
            id, 
            icao,
            title,
            description,
            type,
//-----------------
            error,
        } = this.state;

        const isInvalid = (
            icao === '' ||
            title === '' ||
            type === '' 
        );
        const closeButton = <button className="close" onClick={this.toggle}>&times;</button>;

        return (
            <div>
                <Button onClick={this.toggle} size="sm" className="btn-outline-primary">{this.props.buttonText}</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} close={closeButton} className={this.props.className} backdrop="static" size="lg">
                    <Form onSubmit={this.onSubmit}>
                        <ModalHeader toggle={this.toggle} className="modal-custom text-right">
                            <div className="float-left">{this.props.baseObject}: </div>
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col sm="6">
                                    <TextBox fieldName="id" fieldValue={id} mode={this.state.mode} handleInputChange={this.handleInputChange}/>
                                    <TextBox fieldName="icao" fieldValue={icao} mode={this.state.mode} handleInputChange={this.handleInputChange}/>
                                    <TextBox fieldName="type" fieldValue={type} mode={this.state.mode} handleInputChange={this.handleInputChange}/>
                                </Col>
                                <Col sm="6">
                                    <TextBox fieldName="title" fieldValue={title} mode={this.state.mode} handleInputChange={this.handleInputChange}/>
                                    <TextBox fieldName="description" fieldValue={description} type='textarea' mode={this.state.mode} handleInputChange={this.handleInputChange}/>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <FormGroup>
                                <FormText>
                                    { error && <p>{error.message}</p>}
                                </FormText>
                            </FormGroup>
                            { (this.state.mode === 'edit'  || this.state.mode === 'add') && <Button disabled={isInvalid} type="submit" color="primary" onClick={this.handleSaveChanges}>Save Changes</Button>} {' '}
                            { (this.state.mode !== 'edit' && this.state.mode !== 'add') && <Button type="submit" color="secondary" onClick={this.handleEditButton}>Edit</Button>} {' '}
                            { (this.state.mode === 'edit' || this.state.mode === 'add') && <Button color="secondary" onClick={this.handleCancelButton}>Cancel</Button> }
                            { (this.state.mode !== 'edit' && this.state.mode !== 'add') && <Button type="submit" color="secondary" onClick={this.handleDeleteButton}>Delete</Button>} {' '}
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default FBO;
