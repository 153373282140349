/* eslint-disable */
import React from "react";
import logo from "../../assets/theme/jetflyt/img/logo.png";
import { Link } from "react-router-dom";
class SupportBar extends React.Component {
  state = { isOpen: false };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <header className="header-area ">
        <div id="header-slider" className="header-slider">
          <div className="single-header-item slider-bg-1">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 text-center">
                  <span className="subtitle">JetFlyt Aviation</span>
                  <h1>
                    Tender
                    <br /> Management
                  </h1>
                  <div className="btn-wrapper">
                    <Link
                      className="boxed-btn btn-rounded blank"
                      to="/customer"
                    >
                      Submit a Tender
                    </Link>
                    <a href="#" className="boxed-btn btn-rounded blank">
                      Contact Sales
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default SupportBar;
