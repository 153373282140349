/* eslint-disable */
import React from "react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
// import ThemeRoutes from "../routes/routing.jsx";
import SocialBar from "./JetFlyt/SocialBar";
import SupportBar from "./JetFlyt/SupportBar";
import NavBar from "./JetFlyt/NavBar";
import HeaderArea from "./JetFlyt/HeaderArea";
import About from "./JetFlyt/About";
import WhyUs from "./JetFlyt/WhyUs";
import Portfolio from "./JetFlyt/Portfolio";
import ServiceArea from "./JetFlyt/ServiceArea";
import PricingPlan from "./JetFlyt/PricingPlan";
import MeetTheTeam from "./JetFlyt/MeetTheTeam";
import Testimonials from "./JetFlyt/Testimonials";
import FunFacts from "./JetFlyt/FunFacts";
import NewsFeed from "./JetFlyt/NewsFeed";
import BrandLogo from "./JetFlyt/BrandLogo";
import Footer from "./JetFlyt/Footer";
import BackToTop from "./JetFlyt/BackToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/theme/jetflyt/css/style.css";
import "../assets/theme/jetflyt/css/responsive.css";

class HomeLayout extends React.Component {
  render() {
    return (
      <div id="main-wrapper">
        <SocialBar />
        <SupportBar />
        <NavBar />
        <HeaderArea />
        <About />
        <WhyUs />
        <Footer />
        <BackToTop />
      </div>
    );
  }
}
export default HomeLayout;
